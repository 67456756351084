import EventEmitter from 'framework/core/eventEmitter';
import Ease from 'framework/utils/easeCss';
import Timer from 'framework/tools/timer';
import Utils from 'framework/utils';

class Animator extends EventEmitter {

	constructor( options ) {

		super( options );

		this.timelineTime = 0;

	}

	// set styles to element without transitions

	from( els, props ) {

		let i = els.length;

		while ( i-- ) {

			els[ i ].style.transition = 'all 0ms';

			this.set( els[ i ], props );

		}

		return this;

	}

	to( els, params, position = 0, delay = 0 ) {

		let _this = this;

		// Catch anim options

		params = this.extractOptions( params );

		let ease = Ease[ params.options.ease ] ? Ease[ params.options.ease ]() : Ease.expoOut();
		let duration = params.options.duration || 1000;
		let clean = params.options.clean !== undefined ? params.options.clean : true;

		let timelineDelay = this.timelineTime;

		let i = els.length;

		this.timelineTime += duration + ( i * delay ) + position;

		let complete = false;
		let nb = 0;

		while ( i-- ) {

			let el = els[ i ];

			let indexedDelay = ( timelineDelay + ( delay * ( i + 1 ) ) );

			el.style.transition = 'all ' + duration + 'ms ' + ease + ' ' + indexedDelay + 'ms';

			let onComplete = new Timer();

			let k = i;

			onComplete
				.wait( indexedDelay + duration + position )
				.set( function() {

					nb++;

					// onComplete.destroy();

					if ( ( indexedDelay + duration + position ) === _this.timelineTime ) {
						complete = true;
					}
					if( nb === els.length && complete ){
						_this.trigger( 'finish' );
					}

				} )
				.wait( Math.abs( position / 2 ) )
				.set( function() {

					if ( clean ) {
						el.setAttribute( 'style', '' );
					}

				} );

			Utils.waitRedraw( function() {
				_this.disablePointer( el );
				_this.addWillChange( el, params.props );
				_this.set( el, params.props );
			} );

			// Utils.waitRedraw( function() {
			// 	_this.disablePointer( el );

			// 	Utils.waitRedraw( function() {
			// 		_this.addWillChange( el, params.props );

			// 		Utils.waitRedraw( function() {
			// 			_this.set( el, params.props );
			// 		} );
			// 	} );
			// } );

		}


		return this;

	}

	disablePointer( el ) {

		el.style.pointerEvents = 'none';

	}

	// add will-change property to element

	addWillChange( el, params ) {

		let val = '';

		// loop props

		for ( let prop in params ) {

			// convert readable prop to usable css prop

			let css = this.readableToUsable( prop, params[ prop ] );

			// add val if not still exists

			if ( val.indexOf( css.prop ) === -1 ) {
				val += css.prop + ', ';
			}

		}

		// if will change something

		if ( val ) {

			// remove space and coma from last val

			val = val.slice( 0, -2 );

			// add property and val to element

			el.style.willChange = val;

		}

	}

	set( el, props ) {

		let properties = {};

		// combine same props

		for ( let prop in props ) {

			let val = props[ prop ];

			let css = this.readableToUsable( prop, val );

			if ( properties[ css.prop ] ) {

				properties[ css.prop ] += ' ' + css.val;

			} else {

				properties[ css.prop ] = css.val;

			}

		}

		this.setCss( el, properties );

	}

	setCss( el, props ) {

		// set props

		for ( let prop in props ) {

			let val = props[ prop ];
			el.style[ prop ] = val;

		}

	}

	extractOptions( params ) {

		let extracted = {
			props: {},
			options: {}
		};

		for ( let prop in params ) {

			let val = params[ prop ];

			if ( prop === 'ease' || prop === 'duration' || prop === 'clean' ) {

				extracted.options[ prop ] = val;

			} else {

				extracted.props[ prop ] = val;

			}

		}

		return extracted;

	}

	readableToUsable( prop, val ) {

		let css = {};

		val = val.toString();

		switch ( prop ) {

			case 'opacity':
				css.prop = 'opacity';
				css.val = val;
				break;

			case 'origin':
				css.prop = 'transformOrigin';
				css.val = val;
				break;

			case 'x':
				if ( val.indexOf( '%' ) === -1 ) {
					val += 'px';
				}
				css.prop = 'transform';
				css.val = 'translateX(' + val + ')';
				break;

			case 'y':
				if ( val.indexOf( '%' ) === -1 ) {
					val += 'px';
				}
				css.prop = 'transform';
				css.val = 'translateY(' + val + ')';
				break;

			case 'z':
				if ( val.indexOf( '%' ) === -1 ) {
					val += 'px';
				}
				css.prop = 'transform';
				css.val = 'translateZ(' + val + ')';
				break;

			case 'scale':
				css.prop = 'transform';
				css.val = 'scale(' + val + ')';
				break;

			case 'rotate':
				css.prop = 'transform';
				css.val = 'rotate(' + val + 'deg)';
				break;

			case 'rotateX':
				css.prop = 'transform';
				css.val = 'rotateX(' + val + 'deg)';
				break;

			case 'rotateY':
				css.prop = 'transform';
				css.val = 'rotateY(' + val + 'deg)';
				break;

			case 'rotateZ':
				css.prop = 'transform';
				css.val = 'rotateZ(' + val + 'deg)';
				break;

			case 'scaleX':
				css.prop = 'transform';
				css.val = 'scaleX(' + val + ')';
				break;

			case 'scaleY':
				css.prop = 'transform';
				css.val = 'scaleY(' + val + ')';
				break;

			case 'scaleZ':
				css.prop = 'transform';
				css.val = 'scaleZ(' + val + ')';
				break;

			case 'skew':
				css.prop = 'transform';
				css.val = 'skew(' + val + ')';
				break;

			case 'skewX':
				css.prop = 'transform';
				css.val = 'skewX(' + val + ')';
				break;

			case 'skewY':
				css.prop = 'transform';
				css.val = 'skewY(' + val + ')';
				break;

			default:
				css.prop = prop;
				css.val = val;

		}

		// matrix(1.0, 2.0, 3.0, 4.0, 5.0, 6.0);
		// translate(12px, 50%);
		// scale(2, 0.5);
		// matrix3d(1.0, 2.0, 3.0, 4.0, 5.0, 6.0, 7.0, 8.0, 9.0, 10.0, 11.0, 12.0, 13.0, 14.0, 15.0, 16.0);
		// translate3d(12px, 50%, 3em);
		// scale3d(2.5, 1.2, 0.3);
		// rotate3d(1, 2.0, 3.0, 10deg);
		// perspective(17px);


		return css;

	}

}

export default Animator;
