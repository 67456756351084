var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<span class=\"close close-burger\"><i class=\"ico-close light\"></i></span>\n\n<header>\n\n\t<ul class=\"top-nav\">\n\t\t<li class=\"txt-small-1\"><a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"topbar", env.opts.autoescape)),"press", env.opts.autoescape)),"link", env.opts.autoescape), env.opts.autoescape);
output += "\" target=\"_blank\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"topbar", env.opts.autoescape)),"press", env.opts.autoescape)),"label", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</a></li>\n\t\t<li class=\"txt-small-1\"><a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"topbar", env.opts.autoescape)),"pro", env.opts.autoescape)),"link", env.opts.autoescape), env.opts.autoescape);
output += "\" target=\"_blank\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"topbar", env.opts.autoescape)),"pro", env.opts.autoescape)),"label", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</a></li>\n\t</ul>\n\n\t<a class=\"logo\" href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "\">\n\t\t<svg class=\"svg\">\n\t\t\t<use xlink:href=\"#ico-dppi-logo\">\n\t\t</svg>\n\t</a>\n\n\t<ul class=\"main-nav\">\n\t\t<li class=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "page") == "project"?"is-active":runtime.contextOrFrameLookup(context, frame, "null")), env.opts.autoescape);
output += "\">\n\t\t\t<i data-overlay=\"projects\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"menu", env.opts.autoescape)),"projects", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</i>\n\t\t</li>\n\t\t<li class=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "page") == "expertise"?"is-active":runtime.contextOrFrameLookup(context, frame, "null")), env.opts.autoescape);
output += "\">\n\t\t\t<i data-overlay=\"expertises\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"menu", env.opts.autoescape)),"expertises", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</i>\n\t\t</li>\n\t\t<li class=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "page") == "heritage"?"is-active":runtime.contextOrFrameLookup(context, frame, "null")), env.opts.autoescape);
output += "\">\n\t\t\t<i data-overlay=\"heritages\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"menu", env.opts.autoescape)),"heritage", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</i>\n\t\t</li>\n\t\t<li class=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "page") == "talent"?"is-active":runtime.contextOrFrameLookup(context, frame, "null")), env.opts.autoescape);
output += "\">\n\t\t\t<i data-overlay=\"talents\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"menu", env.opts.autoescape)),"talents", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</i>\n\t\t</li>\n\t\t<li class=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "page") == "about"?"is-active":runtime.contextOrFrameLookup(context, frame, "null")), env.opts.autoescape);
output += "\">\n\t\t\t<a href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/about/\">\n\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"menu", env.opts.autoescape)),"about", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</li>\n\t\t<li>\n\t\t\t<a class=\"bold\" href=\"http://www.google.fr/\" target=\"_blank\">\n\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"menu", env.opts.autoescape)),"store", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</li>\n\t</ul>\n\n\t<span class=\"burger\">\n\t\t<i></i>\n\t\t<i></i>\n\t\t<i></i>\n\t</span>\n\n</header>\n\n<div class=\"overlay pad-1 bg-dark js-overlay-projects\">\n\t<span class=\"prev prev-overlay\">\n\t\t<i class=\"ico-prev light\">\n\t\t\t<i></i>\n\t\t</i>\n\t</span>\n\t<span class=\"close close-overlay round-hover\" data-roundhover>\n\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t</span>\n\t<ul class=\"nav\">\n\t";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "posts")),"projects", env.opts.autoescape);
if(t_3) {var t_1;
if(runtime.isArray(t_3)) {
var t_2 = t_3.length;
for(t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1][0]
frame.set("cat", t_3[t_1][0]);
var t_5 = t_3[t_1][1]
frame.set("projects", t_3[t_1][1]);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t<span>\n\t\t\t";
frame = frame.push();
var t_8 = t_5;
if(t_8) {var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("project", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += "\n\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index", env.opts.autoescape) == 1) {
output += "\n\t\t\t\t<li class=\"categ\">\n\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t<li>\n\t\t\t\t";
;
}
output += "\n\t\t\t\t\t<b>\n\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index", env.opts.autoescape) == 1) {
output += "\n\t\t\t\t\t\t\t<span class=\"txt-small-1\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_9),"category", env.opts.autoescape)),"name", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t<a href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/project/";
output += runtime.suppressValue(runtime.memberLookup((t_9),"slug", env.opts.autoescape), env.opts.autoescape);
output += "/\" class=\"txt-med-5\">";
output += runtime.suppressValue(runtime.memberLookup((t_9),"client", env.opts.autoescape), env.opts.autoescape);
output += "</a>\n\t\t\t\t\t</b>\n\t\t\t\t\t<div class=\"img\">\n\t\t\t\t\t\t<img\n\t\t\t\t\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t\tdata-lazy=\"true\"\n\t\t\t\t\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((t_9),"image", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t>\n\t\t\t\t\t</div>\n\t\t\t\t</li>\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t</span>\n\t";
;
}
} else {
t_1 = -1;
var t_2 = runtime.keys(t_3).length;
for(var t_10 in t_3) {
t_1++;
var t_11 = t_3[t_10];
frame.set("cat", t_10);
frame.set("projects", t_11);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t<span>\n\t\t\t";
frame = frame.push();
var t_14 = t_11;
if(t_14) {var t_13 = t_14.length;
for(var t_12=0; t_12 < t_14.length; t_12++) {
var t_15 = t_14[t_12];
frame.set("project", t_15);
frame.set("loop.index", t_12 + 1);
frame.set("loop.index0", t_12);
frame.set("loop.revindex", t_13 - t_12);
frame.set("loop.revindex0", t_13 - t_12 - 1);
frame.set("loop.first", t_12 === 0);
frame.set("loop.last", t_12 === t_13 - 1);
frame.set("loop.length", t_13);
output += "\n\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index", env.opts.autoescape) == 1) {
output += "\n\t\t\t\t<li class=\"categ\">\n\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t<li>\n\t\t\t\t";
;
}
output += "\n\t\t\t\t\t<b>\n\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index", env.opts.autoescape) == 1) {
output += "\n\t\t\t\t\t\t\t<span class=\"txt-small-1\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_15),"category", env.opts.autoescape)),"name", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t<a href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/project/";
output += runtime.suppressValue(runtime.memberLookup((t_15),"slug", env.opts.autoescape), env.opts.autoescape);
output += "/\" class=\"txt-med-5\">";
output += runtime.suppressValue(runtime.memberLookup((t_15),"client", env.opts.autoescape), env.opts.autoescape);
output += "</a>\n\t\t\t\t\t</b>\n\t\t\t\t\t<div class=\"img\">\n\t\t\t\t\t\t<img\n\t\t\t\t\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t\tdata-lazy=\"true\"\n\t\t\t\t\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((t_15),"image", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t>\n\t\t\t\t\t</div>\n\t\t\t\t</li>\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t</span>\n\t";
;
}
}
}
frame = frame.pop();
output += "\n\t</ul>\n</div>\n\n<div class=\"overlay pad-1 bg-dark js-overlay-expertises\">\n\t<span class=\"prev prev-overlay\">\n\t\t<i class=\"ico-prev light\">\n\t\t\t<i></i>\n\t\t</i>\n\t</span>\n\t<span class=\"close close-overlay round-hover\" data-roundhover>\n\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t</span>\n\t<ul class=\"nav\">\n\t\t";
frame = frame.push();
var t_18 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "posts")),"expertises", env.opts.autoescape);
if(t_18) {var t_17 = t_18.length;
for(var t_16=0; t_16 < t_18.length; t_16++) {
var t_19 = t_18[t_16];
frame.set("expertise", t_19);
frame.set("loop.index", t_16 + 1);
frame.set("loop.index0", t_16);
frame.set("loop.revindex", t_17 - t_16);
frame.set("loop.revindex0", t_17 - t_16 - 1);
frame.set("loop.first", t_16 === 0);
frame.set("loop.last", t_16 === t_17 - 1);
frame.set("loop.length", t_17);
output += "\n\t\t\t<li>\n\t\t\t\t<a href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/expertise/";
output += runtime.suppressValue(runtime.memberLookup((t_19),"slug", env.opts.autoescape), env.opts.autoescape);
output += "/\" class=\"txt-med-5\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_19),"title", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</a>\n\t\t\t</li>\n\t\t";
;
}
}
frame = frame.pop();
output += "\n\t</ul>\n</div>\n\n<div class=\"overlay pad-1 bg-dark js-overlay-heritages\">\n\t<span class=\"prev prev-overlay\">\n\t\t<i class=\"ico-prev light\">\n\t\t\t<i></i>\n\t\t</i>\n\t</span>\n\t<span class=\"close close-overlay round-hover\" data-roundhover>\n\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t</span>\n\t<ul class=\"nav\">\n\t";
frame = frame.push();
var t_22 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "posts")),"heritages", env.opts.autoescape);
if(t_22) {var t_21 = t_22.length;
for(var t_20=0; t_20 < t_22.length; t_20++) {
var t_23 = t_22[t_20];
frame.set("heritage", t_23);
frame.set("loop.index", t_20 + 1);
frame.set("loop.index0", t_20);
frame.set("loop.revindex", t_21 - t_20);
frame.set("loop.revindex0", t_21 - t_20 - 1);
frame.set("loop.first", t_20 === 0);
frame.set("loop.last", t_20 === t_21 - 1);
frame.set("loop.length", t_21);
output += "\n\t\t<li>\n\t\t\t<a href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/heritage/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_23),"attrs", env.opts.autoescape)),"handle", env.opts.autoescape), env.opts.autoescape);
output += "/\" class=\"txt-med-5\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_23),"attrs", env.opts.autoescape)),"title", env.opts.autoescape), env.opts.autoescape);
output += "</a>\n\t\t</li>\n\t";
;
}
}
frame = frame.pop();
output += "\n\t</ul>\n</div>\n\n<div class=\"overlay pad-1 bg-dark js-overlay-talents\">\n\t<span class=\"prev prev-overlay\">\n\t\t<i class=\"ico-prev light\">\n\t\t\t<i></i>\n\t\t</i>\n\t</span>\n\t<span class=\"close close-overlay round-hover\" data-roundhover>\n\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t</span>\n\t<ul class=\"nav\">\n\t";
frame = frame.push();
var t_26 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "posts")),"talents", env.opts.autoescape);
if(t_26) {var t_25 = t_26.length;
for(var t_24=0; t_24 < t_26.length; t_24++) {
var t_27 = t_26[t_24];
frame.set("talent", t_27);
frame.set("loop.index", t_24 + 1);
frame.set("loop.index0", t_24);
frame.set("loop.revindex", t_25 - t_24);
frame.set("loop.revindex0", t_25 - t_24 - 1);
frame.set("loop.first", t_24 === 0);
frame.set("loop.last", t_24 === t_25 - 1);
frame.set("loop.length", t_25);
output += "\n\t\t<li>\n\t\t\t<a href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/talent/";
output += runtime.suppressValue(runtime.memberLookup((t_27),"slug", env.opts.autoescape), env.opts.autoescape);
output += "/\" class=\"txt-med-5\">";
output += runtime.suppressValue(runtime.memberLookup((t_27),"title", env.opts.autoescape), env.opts.autoescape);
output += "</a>\n\t\t</li>\n\t";
;
}
}
frame = frame.pop();
output += "\n\t\t<li>\n\t\t\t<a href=\"#\" class=\"txt-med-5 talent-disable\">François Abattista</a>\n\t\t</li>\n\t\t<li>\n\t\t\t<a href=\"#\" class=\"txt-med-5 talent-disable\">Thierry Bovy</a>\n\t\t</li>\n\t\t<li>\n\t\t\t<a href=\"#\" class=\"txt-med-5 talent-disable\">Vincent Ceas</a>\n\t\t</li>\n\t\t<li>\n\t\t\t<a href=\"#\" class=\"txt-med-5 talent-disable\">Benjamin Cremel</a>\n\t\t</li>\n\t\t<li>\n\t\t\t<a href=\"#\" class=\"txt-med-5 talent-disable\">Vincent Curutchet</a>\n\t\t</li>\n\t\t<li>\n\t\t\t<a href=\"#\" class=\"txt-med-5 talent-disable\">Antonin Grenier</a>\n\t\t</li>\n\t\t<li>\n\t\t\t<a href=\"#\" class=\"txt-med-5 talent-disable\">Alexandre Guillaumot</a>\n\t\t</li>\n\t\t<li>\n\t\t\t<a href=\"#\" class=\"txt-med-5 talent-disable\">Delphine Le Berre</a>\n\t\t</li>\n\t\t<li>\n\t\t\t<a href=\"#\" class=\"txt-med-5 talent-disable\">Jean Marie Liot</a>\n\t\t</li>\n\t\t<li>\n\t\t\t<a href=\"#\" class=\"txt-med-5 talent-disable\">Philippe Millereau</a>\n\t\t</li>\n\t\t<li>\n\t\t\t<a href=\"#\" class=\"txt-med-5 talent-disable\">Michel Poupineau</a>\n\t\t</li>\n\t\t<li>\n\t\t\t<a href=\"#\" class=\"txt-med-5 talent-disable\">Christophe Trarieux</a>\n\t\t</li>\n\t\t<li>\n\t\t\t<a href=\"#\" class=\"txt-med-5 talent-disable\">Manou Zurini</a>\n\t\t</li>\n\t</ul>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
