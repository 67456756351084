import Utils from 'framework/utils';
import { $ } from 'framework/tools/selector';

class Abstract {

	constructor( options ) {

		this.defaults = {};
		this.options = Utils.mergeDeep( this.defaults, options );

	}

	destroy() {

		Utils.nullObject( this );

	}

}

export default Abstract;
