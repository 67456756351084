import Component from 'framework/core/component';
import Template from 'templates/components/common/cookies.html';
import Cookie from 'framework/utils/cookies';

class Cookies extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.$.cookies = this.$.container.find( '#cookies' );
		this.$.btnClose = this.$.cookies.find( '.close-cookies' );

		if ( Cookie.getCookie( 'cookies_allow' ) !== 'true' ) {
			this.observe();
		} else {
			this.$.cookies.addClass( 'hide' );
		}

	}

	observe() {

		this.clickHandler = this.clickHandler.bind( this );
		this.$.btnClose.on( 'click', this.clickHandler );

	}

	clickHandler() {

		Cookie.setCookie( 'cookies_allow', true, 7 );
		this.$.cookies.addClass( 'hide' );

	}

	destroy() {

		if ( Cookie.getCookie( 'cookies_allow' ) !== 'true' ) {
			this.$.btnClose.off( 'click', this.clickHandler );
		}

	}

}

export default Cookies;
