import EventEmitter from 'framework/core/eventEmitter';
import Visibler from 'framework/tools/visibler';
import {
	$
} from 'framework/tools/selector';
import Utils from 'framework/utils';

class Videos extends EventEmitter {

	constructor( options ) {

		super( options );

		this.$ = {};

		this.$.container = options.container;
		this.$.video = this.$.container.find( '.video' );

		this.isDesktop = Utils.isDesktop();

		this.visiblers = [];

		this.createVisiblers();

	}

	createVisiblers() {

		let _this = this;
		let i = this.$.video.length;

		this.visibleHandler = this.visibleHandler.bind( this );
		this.playHandler = this.playHandler.bind( this );
		this.hiddenHandler = this.hiddenHandler.bind( this );

		while ( i-- ) {

			let el = $( this.$.video[ i ] );

			let player = el.find( 'video' );
			let play = el.find( '.play' );

			let visibler = new Visibler( {
				element: el,
				player: player[ 0 ],
				play: play
			} );

			play.on( 'click', _this.playHandler );
			visibler.on( 'visible', _this.visibleHandler );
			visibler.on( 'hidden', _this.hiddenHandler );

			if ( !Utils.isIOS ) {
				player[ 0 ].load();
			}

			_this.visiblers.push( visibler );

		}

	}

	visibleHandler( infos ) {

		// play video on visible
		if ( !Utils.isIOS() ) {
			infos.player.muted = true;
			infos.player.play();
		}

	}

	playHandler( e ) {

		let btn = $( e.currentTarget );
		let container = btn.parent();
		let widget = container.find( 'iframe' );
		container.addClass( 'play-widget' );

		window.sportagraph.embeds.api( widget[ 0 ], 'play' );


	}

	hiddenHandler( infos ) {

		// stop video on visible

		infos.player.pause();
		infos.player.currentTime = 0;

		let iframe = infos.element.find( 'iframe' );
		window.sportagraph.embeds.api( iframe[ 0 ], 'asleep' );

		infos.element.removeClass( 'play-widget' );

	}

	destroy() {

		let i = this.visiblers.length;

		while ( i-- ) {

			let visibler = this.visiblers[ i ];

			visibler.off( 'visible', this.visibleHandler );
			visibler.off( 'hidden', this.hiddenHandler );

			visibler.destroy();

		}

		super.destroy();

	}

}

export default Videos;
