class EaseCss {

	static format( ax, ay, bx, by ) {
		return 'cubic-bezier(' + ax + ', ' + ay + ', ' + bx + ', ' + by + ')';
	}

	static backInOut() {
		return this.format( 0.680, -0.550, 0.265, 1.550 );
	}

	static backIn() {
		return this.format( 0.600, -0.280, 0.735, 0.045 );
	}

	static backOut() {
		return this.format( 0.175, 0.885, 0.320, 1.275 );
	}

	static circInOut() {
		return this.format( 0.785, 0.135, 0.150, 0.860 );
	}

	static circIn() {
		return this.format( 0.600, 0.040, 0.980, 0.335 );
	}

	static circOut() {
		return this.format( 0.075, 0.820, 0.165, 1.000 );
	}

	static cubicInOut() {
		return this.format( 0.645, 0.045, 0.355, 1.000 );
	}

	static cubicIn() {
		return this.format( 0.550, 0.055, 0.675, 0.190 );
	}

	static cubicOut() {
		return this.format( 0.215, 0.610, 0.355, 1.000 );
	}

	static expoInOut() {
		return this.format( 1.000, 0.000, 0.000, 1.000 );
	}

	static expoIn() {
		return this.format( 0.950, 0.050, 0.795, 0.035 );
	}

	static expoOut() {
		return this.format( 0.190, 1.000, 0.220, 1.000 );
	}

	static linear() {
		return this.format( 0.250, 0.250, 0.750, 0.750 );
	}

	static quadInOut() {
		return this.format( 0.455, 0.030, 0.515, 0.955 );
	}

	static quadIn() {
		return this.format( 0.550, 0.085, 0.680, 0.530 );
	}

	static quadOut() {
		return this.format( 0.250, 0.460, 0.450, 0.940 );
	}

	static quartInOut() {
		return this.format( 0.770, 0.000, 0.175, 1.000 );
	}

	static quartIn() {
		return this.format( 0.895, 0.030, 0.685, 0.220 );
	}

	static quartOut() {
		return this.format( 0.165, 0.840, 0.440, 1.000 );
	}

	static quintInOut() {
		return this.format( 0.860, 0.000, 0.070, 1.000 );
	}

	static quintIn() {
		return this.format( 0.755, 0.050, 0.855, 0.060 );
	}

	static quintOut() {
		return this.format( 0.230, 1.000, 0.320, 1.000 );
	}

	static sineInOut() {
		return this.format( 0.445, 0.050, 0.550, 0.950 );
	}

	static sineIn() {
		return this.format( 0.470, 0.000, 0.745, 0.715 );
	}

	static sineOut() {
		return this.format( 0.390, 0.575, 0.565, 1.000 );
	}

}

export default EaseCss;
