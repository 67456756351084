var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"heritage-intro\">\n\t<div class=\"grid pad-1\">\n\t\t<h2 class=\"txt-fat-2 pad-2-bot\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "currentCollection")),"attrs", env.opts.autoescape)),"title", env.opts.autoescape), env.opts.autoescape);
output += "</h2>\n\t\t<p class=\"txt-small-3\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "heritage")),"intro", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t</div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
