var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"talent-intro white-background\">\n\t<div class=\"grid pad-2\">\n\t\t<div class=\"nav\">\n\t\t\t<div class=\"nav-btns\">\n\t\t\t\t<a href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/talent/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "talent")),"prev", env.opts.autoescape)),"slug", env.opts.autoescape), env.opts.autoescape);
output += "/\" class=\"nav-btn nav-left\">\n\t\t\t\t\t<svg class=\"svg\">\n\t\t\t\t\t\t<use xlink:href=\"#ico-arrow-left\">\n\t\t\t\t\t</svg>\n\t\t\t\t</a>\n\t\t\t\t<a href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/talent/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "talent")),"next", env.opts.autoescape)),"slug", env.opts.autoescape), env.opts.autoescape);
output += "/\" class=\"nav-btn nav-right\">\n\t\t\t\t\t<svg class=\"svg\">\n\t\t\t\t\t\t<use xlink:href=\"#ico-arrow-right\">\n\t\t\t\t\t</svg>\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t\t<div class=\"nav-infos\">\n\t\t\t\t<span><em class=\"pink\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "talent")),"navIndex", env.opts.autoescape), env.opts.autoescape);
output += "</em>/<em>";
output += runtime.suppressValue(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "posts")),"talents", env.opts.autoescape)), env.opts.autoescape);
output += "</em></span>\n\t\t\t</div>\n\t\t</div>\n\t\t<h2 class=\"txt-fat-2\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "talent")),"title", env.opts.autoescape), env.opts.autoescape);
output += "</h2>\n\t</div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
