import EventEmitter from 'framework/core/eventEmitter';
import Utils from 'framework/utils';
//import Router from 'framework/tools/router';
import {
	$
} from 'framework/tools/selector';

class Controller extends EventEmitter {

	constructor( options ) {

		super( options );

		this.name = this.options.name;
		this.previous = this.options.previous;
		this.params = this.options.params;

		this.datas = {};

		this.components = {};

		this.$ = {};
		this.$.container = $( document.body );
		this.$.overview = $( document.createElement( 'section' ) );
		this.$.fixed = $( document.createElement( 'section' ) );

		// render

		this.$.overview.attr( 'id', 'overview' );
		this.$.fixed.attr( 'id', 'fixed' );

		this.$.container.append( this.$.overview );
		this.$.container.append( this.$.fixed );

	}

	parse() {

		// parse router

		window.Router.parse();

		window.setTimeout( function() {
			window.dispatchEvent( new CustomEvent( 'resize' ) );
			window.scrollTo( 0, 1 );
			window.scrollTo( 0, 0 );
		}, 100 );

	}

	hide( callback ) {

		let len = Object.keys( this.components ).length;
		let count = 0;

		for ( let key in this.components ) {

			this.components[ key ].hide( function() {

				count++;

				if ( count === ( len - 1 ) ) {
					callback();
				}

			} );

		}

	}

	destroy() {

		for ( let key in this.components ) {

			if ( this.components[ key ].destroy ) {
				this.components[ key ].destroy();
			}

		}

		this.$.overview.remove();
		this.$.fixed.remove();

		Utils.nullObject( this );

	}

}

export default Controller;
