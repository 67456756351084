var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"expertise-content\">\n\n\t";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "expertise")),"content", env.opts.autoescape);
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("content", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\n\t\t<div class=\"white-background\">\n\t\t\t<div class=\"grid pad-1\">\n\t\t\t\t<p class=\"txt-small-1 pad-4-bot\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "expertise")),"title", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t\t\t<h2 class=\"txt-fat-2\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"title", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</h2>\n\t\t\t</div>\n\t\t\t<div class=\"grid pad-1-bot\">\n\t\t\t\t<div class=\"col-1-3-gut\">\n\t\t\t\t\t<ul class=\"pink-chip\">\n\t\t\t\t\t\t";
frame = frame.push();
var t_7 = runtime.memberLookup((t_4),"skills", env.opts.autoescape);
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("skill", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t\t\t<li>";
output += runtime.suppressValue(runtime.memberLookup((t_8),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</li>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</ul>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"col-2-3\">\n\t\t\t\t\t<span class=\"txt-fat-4\">0";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index", env.opts.autoescape), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t<p class=\"txt-med-1\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"description", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"expertise-carousel carousel full\">\n\t\t\t<div class=\"images\">\n\t\t\t\t<img class=\"ratio-mobile col-5-6\" src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\">\n\t\t\t\t<img class=\"ratio-desktop col-5-6\" src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_160_63", env.opts.autoescape), env.opts.autoescape);
output += "\">\n\t\t\t\t<div class=\"parallax\">\n\t\t\t\t\t<div class=\"viewport col-5-6\">\n\t\t\t\t\t\t<span class=\"statusbar\"></span>\n\t\t\t\t\t\t<ul class=\"overview\">\n\t\t\t\t\t\t\t";
frame = frame.push();
var t_11 = runtime.memberLookup((t_4),"projects", env.opts.autoescape);
if(t_11) {var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("project", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n\t\t\t\t\t\t\t\t<li class=\"col-5-6\">\n\t\t\t\t\t\t\t\t\t<div class=\"mask\">\n\t\t\t\t\t\t\t\t\t\t<div class=\"img\">\n\t\t\t\t\t\t\t\t\t\t\t<img\n\t\t\t\t\t\t\t\t\t\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t\t\t\t\t\t\tdata-lazy-scroll=\"true\"\n\t\t\t\t\t\t\t\t\t\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"image", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t\t\t\t\t\t>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t</ul>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class=\"white-background\">\n\t\t\t\t<div class=\"infos grid pad-4-top\">\n\t\t\t\t\t<ul>\n\t\t\t\t\t\t";
frame = frame.push();
var t_15 = runtime.memberLookup((t_4),"projects", env.opts.autoescape);
if(t_15) {var t_14 = t_15.length;
for(var t_13=0; t_13 < t_15.length; t_13++) {
var t_16 = t_15[t_13];
frame.set("project", t_16);
frame.set("loop.index", t_13 + 1);
frame.set("loop.index0", t_13);
frame.set("loop.revindex", t_14 - t_13);
frame.set("loop.revindex0", t_14 - t_13 - 1);
frame.set("loop.first", t_13 === 0);
frame.set("loop.last", t_13 === t_14 - 1);
frame.set("loop.length", t_14);
output += "\n\t\t\t\t\t\t\t<li class=\"pad-4-top ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index", env.opts.autoescape) == 1?"shown":runtime.contextOrFrameLookup(context, frame, "null")), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t<div class=\"col-2-3\">\n\t\t\t\t\t\t\t\t\t<p class=\"txt-small-1 pad-5-bot\">";
output += runtime.suppressValue(runtime.memberLookup((t_16),"client", env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t\t\t\t\t\t\t\t<h2 class=\"txt-med-2\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_16),"title", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</h2>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class=\"col-1-3\">\n\t\t\t\t\t\t\t\t\t<a class=\"link-underline\" href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/project/";
output += runtime.suppressValue(runtime.memberLookup((t_16),"slug", env.opts.autoescape), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"home", env.opts.autoescape)),"carousel", env.opts.autoescape)),"discover", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</a>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class=\"nav-infos col-1-6\">\n\t\t\t\t\t\t\t\t\t<span><em class=\"pink\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index", env.opts.autoescape), env.opts.autoescape);
output += "</em>/<em>";
output += runtime.suppressValue(env.getFilter("length").call(context, runtime.memberLookup((t_4),"projects", env.opts.autoescape)), env.opts.autoescape);
output += "</em></span>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</ul>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<nav>\n\t\t\t\t<img class=\"col-5-6\" src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_160_63", env.opts.autoescape), env.opts.autoescape);
output += "\">\n\t\t\t\t<div class=\"col-1-6 previous\"><i class=\"ico-prev\"><i></i></i></div>\n\t\t\t\t<div class=\"col-1-6 next\"><i class=\"ico-next\"><i></i></i></div>\n\t\t\t</nav>\n\t\t</div>\n\n\t";
;
}
}
frame = frame.pop();
output += "\n\n\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
