import Component from 'framework/core/component';
import Resizer from 'framework/tools/resizer';
import Template from 'templates/components/common/header.html';
import Utils from 'framework/utils';
import {
	$
} from 'framework/tools/selector';
import Animator from 'framework/tools/animator';
import Sticker from 'framework/tools/sticker';

class Header extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.resizer = new Resizer();

		this.isMobile = Utils.isMobile();
		this.isTablet = Utils.isTablet();
		this.isDesktop = Utils.isDesktop();

		// elements

		this.$.header = this.$.container.find( 'header' );
		this.$.logo = this.$.header.find( '.logo' );
		this.$.nav = this.$.header.find( '.main-nav' );
		this.$.allBtns = this.$.nav.find( 'li' );
		this.$.btns = this.$.container.find( '[data-overlay]' );
		this.$.overlays = this.$.container.find( '.overlay' );
		this.$.overlaysClose = this.$.container.find( '.close-overlay' );
		this.$.overlaysPrev = this.$.container.find( '.prev-overlay' );
		this.$.burger = this.$.container.find( '.burger' );
		this.$.burgerClose = $( document.body ).find( '.close-burger' );

		this.$.activeOverlay = null;

		if ( this.isMobile ) {
			this.overlayPadding = 200;
		} else {
			this.overlayPadding = 120;
		}

		// config animation
		this.configAnimation = {
			overlay: {
				open: {
					btns: {
						duration: 1000,
						shift: 25,
						finalShift: 0,
						fromX: this.isMobile ? 0 : 100,
						fromOpacity: 0,
						toX: 0,
						toOpacity: 1,
						ease: ''
					}
				},
				hide: {
					btns: {
						duration: 600,
						shift: 25,
						finalShift: 0,
						toX: this.isMobile ? 0 : -100,
						toOpacity: 0,
						ease: 'quartInOut'
					},
					container: {
						startShift: 600
					}
				},
				close: {
					btns: {
						duration: 600,
						shift: 25,
						finalShift: 0,
						toX: this.isMobile ? 0 : -100,
						toOpacity: 0,
						ease: 'quartInOut'
					}
				}
			}
		};

		// ---o

		if ( ( !this.isMobile && Utils.isMac() ) || this.isTablet ) {
			this.sticker = new Sticker( {
				element: this.$.header,
				start: 140,
				startSmartShown: window.innerHeight / 2
			} );
		}

		this.launchAnimations();

		if ( !this.isMobile ) {
			this.showMainMenu();
		}

		this.observe();

	}

	observe() {

		this.btnsHandler = this.btnsHandler.bind( this );
		this.$.btns.on( 'click', this.btnsHandler );

		this.overlaysCloseHandler = this.overlaysCloseHandler.bind( this );
		this.$.overlaysClose.on( 'click', this.overlaysCloseHandler );

		this.overlaysPrevHandler = this.overlaysPrevHandler.bind( this );
		this.$.overlaysPrev.on( 'click', this.overlaysPrevHandler );

		this.burgerOpenHandler = this.burgerOpenHandler.bind( this );
		this.$.burger.on( 'click', this.burgerOpenHandler );

		this.burgerCloseHandler = this.burgerCloseHandler.bind( this );
		this.$.burgerClose.on( 'click', this.burgerCloseHandler );

		this.resizeHandler = this.resizeHandler.bind( this );
		this.resizer.on( 'resize', this.resizeHandler );


	}

	prepareMainMenu() {

		this.mainMenuShow = new Animator();
		this.mainMenuShow
			.from( this.$.header, {
				opacity: 0
			} );

	}

	showMainMenu() {

		this.prepareMainMenu();

		let _this = this;
		setTimeout( function() {

			_this.mainMenuShow
				.to( _this.$.header, {
					opacity: 1,
					duration: 800,
					ease: '',
					clean: false
				}, 0 );

		}, 200 );

		this.mainMenuShow.once( 'finish', function() {
			_this.$.header.css( {
				pointerEvents: '',
				transition: ''
			} );
		} );

	}

	launchAnimations() {

		if ( window.isOverlayProject || window.isOverlayNav ) {

			// add Light class to header text
			this.$.container.find( 'header' ).addClass( 'bg-dark' );

		}

		// // let _this = this;
		// // setTimeout( function(){

		// 	// add transition to header
		// 	this.$.container.find( 'header' ).addClass( 'transition' );

		// 	// show header
		// 	this.$.container.find( 'header' ).addClass( 'show' );

		// // }, 5000 );

	}

	resizeHandler() {

		let i = this.$.overlays.length;

		while ( i-- ) {
			let overlay = this.$.overlays.eq( i );
			let nav = overlay.find( '.nav' );
			let nh = nav.height();
			let oh = overlay.height() - this.overlayPadding;

			if ( nh < oh ) {
				nav.css( {
					top: ( ( ( oh - nh ) / 2 ) ) + 'px'
				} );
			}
		}

	}

	btnsHandler( e ) {

		let el = $( e.target );
		let name = el.attr( 'data-overlay' );
		this.$.activeOverlay = this.$.container.find( '.js-overlay-' + name );

		if ( this.isDesktop && !$( document.documentElement ).hasClass( 'ie' ) ) {

			this.overlayShow();

		} else {

			this.$.activeOverlay.addClass( 'shown' );
			this.$.overlaysClose.addClass( 'visible' );
			if ( this.isMobile ) {
				this.$.overlaysPrev.addClass( 'visible' );
			}
			if ( this.isDesktop ) {
				// remove scrollbars
				document.documentElement.classList.add( 'overlay-scrollbar' );
			}

		}

	}

	overlaysCloseHandler() {

		if ( this.isDesktop && !$( document.documentElement ).hasClass( 'ie' ) ) {

			this.overlayHide();

		} else {

			this.$.activeOverlay.removeClass( 'shown' );
			this.$.overlaysClose.removeClass( 'visible' );
			if ( this.isMobile ) {
				this.$.overlaysPrev.removeClass( 'visible' );
			}
			if ( this.isDesktop ) {
				// show scrollbars
				document.documentElement.classList.remove( 'overlay-scrollbar' );
			}

		}

	}

	overlaysPrevHandler() {

		if ( this.isDesktop && !$( document.documentElement ).hasClass( 'ie' ) ) {

			this.overlayHide();

		} else {

			this.$.activeOverlay.removeClass( 'shown' );
			this.$.overlaysClose.removeClass( 'visible' );
			if ( this.isMobile ) {
				this.$.overlaysPrev.removeClass( 'visible' );
			}
			if ( this.isDesktop ) {
				// show scrollbars
				document.documentElement.classList.remove( 'overlay-scrollbar' );
			}

		}

	}

	prepareBtnAnimations( btns ) {

		this.overlayShowIn = new Animator();
		this.overlayShowIn
			.from( btns, {
				opacity: this.configAnimation.overlay.open.btns.fromOpacity,
				x: this.configAnimation.overlay.open.btns.fromX
			} );

	}

	overlayShow() {

		this.overlayOpen = true;
		window.isOverlay = true;

		if ( this.isDesktop ) {
			// remove scrollbars
			document.documentElement.classList.add( 'overlay-scrollbar' );
		}

		let btns = this.$.activeOverlay.find( 'li' );

		// if it is projects overlay :
		if ( this.$.activeOverlay.hasClass( 'js-overlay-projects' ) ) {

			window.isOverlayProject = true;

			// click-event on projects btn to keep active image
			let i = btns.length;
			while ( i-- ) {

				btns.eq( i ).once( 'click', function( el ) {

					// active btn
					let btn = $( el );
					btn.addClass( 'active' );

					// disable click on other btns
					let j = btns.length;
					while ( j-- ) {
						btns.eq( j ).parent().css( {
							pointerEvents: 'none'
						} );
					}

				} );

			}

			// for projects overlay, animation is not on li element but on b element
			btns = this.$.activeOverlay.find( 'li > b' );

		}

		// disable items that can disrupt animation
		this.$.activeOverlay.find( 'ul' ).css( {
			pointerEvents: 'none'
		} );
		this.$.burgerClose.css( {
			pointerEvents: 'none'
		} );
		this.$.overlaysPrev.css( {
			pointerEvents: 'none'
		} );

		// set start style of items
		this.prepareBtnAnimations( btns );

		// animation
		let _this = this;
		setTimeout( function() { // shifted to allow time to prepare function

			// show overlay
			_this.$.activeOverlay.addClass( 'shown' );

			if ( _this.isMobile ) {
				// show prev btn
				_this.$.overlaysPrev.addClass( 'visible' );
			}

			// btns animation
			_this.overlayShowIn
				.to( btns, {
					opacity: _this.configAnimation.overlay.open.btns.toOpacity,
					x: _this.configAnimation.overlay.open.btns.toX,
					duration: _this.configAnimation.overlay.open.btns.duration,
					ease: _this.configAnimation.overlay.open.btns.ease
				}, _this.configAnimation.overlay.open.btns.finalShift, _this.configAnimation.overlay.open.btns.shift );

			// end of animation
			_this.overlayShowIn.once( 'finish', function() {

				// show close btn
				_this.$.overlaysClose.addClass( 'visible' );

				// enable items that could disrupt animation
				_this.$.activeOverlay.find( 'ul' ).css( {
					pointerEvents: 'all'
				} );
				_this.$.burgerClose.css( {
					pointerEvents: 'all'
				} );
				_this.$.overlaysPrev.css( {
					pointerEvents: 'all'
				} );

			} );

		}, 200 );

	}

	overlayHide() {

		// for mobile : check if overlay is open
		if ( this.overlayOpen ) {

			let btns = this.$.activeOverlay.find( 'li' );

			// for projects overlay, animation is not on li element but on b element
			if ( this.$.activeOverlay.hasClass( 'js-overlay-projects' ) ) {
				btns = this.$.activeOverlay.find( 'li > b' );
			}

			// disable elements
			this.$.activeOverlay.find( 'ul' ).css( {
				pointerEvents: 'none'
			} );

			// hide btns
			this.$.overlaysClose.removeClass( 'visible' );
			this.$.overlaysPrev.removeClass( 'visible' );

			// btns animation
			this.overlayShowOut = new Animator();
			this.overlayShowOut
				.to( btns, {
					opacity: this.configAnimation.overlay.hide.btns.toOpacity,
					x: this.configAnimation.overlay.hide.btns.toX,
					duration: this.configAnimation.overlay.hide.btns.duration,
					ease: this.configAnimation.overlay.hide.btns.ease,
					clean: false
				}, this.configAnimation.overlay.hide.btns.finalShift, this.configAnimation.overlay.hide.btns.shift );

			// end of animation
			let _this = this;
			setTimeout( function() {
				// hide overlay
				_this.$.activeOverlay.removeClass( 'shown' );
			}, this.configAnimation.overlay.hide.container.startShift );

			this.overlayShowOut.once( 'finish', function() {

				// enable elements
				_this.$.burgerClose.css( {
					pointerEvents: ''
				} );
				_this.$.overlaysPrev.css( {
					pointerEvents: ''
				} );

				if ( _this.isDesktop ) {

					// show scrollbars
					document.documentElement.classList.remove( 'overlay-scrollbar' );

				}

			} );

			// reset overlay variables
			this.overlayOpen = false;
			window.isOverlay = false;
			window.isOverlayProject = false;

		}

	}

	burgerCloseHandler() {

		$( document.body ).removeClass( 'no-scroll' );

		this.$.burgerClose.removeClass( 'shown' );
		this.$.header.removeClass( 'collapsed' );
		this.$.overlays.removeClass( 'shown' );
		this.overlayHide();
		window.isOverlay = false;
		window.isOverlayProject = false;

	}

	burgerOpenHandler() {

		$( document.body ).addClass( 'no-scroll' );

		this.$.burgerClose.addClass( 'shown' );
		this.$.header.addClass( 'collapsed' );

	}

	hideMainMenu() {

		this.mainMenuHide = new Animator();
		this.mainMenuHide
			.to( this.$.header, {
				opacity: 0,
				duration: 800,
				ease: ''
			}, 0 );

	}

	hide( callback ) {

		if ( !this.isMobile ) {
			this.hideMainMenu();
		}

		if ( window.isOverlay ) {

			let btns = this.$.activeOverlay.find( 'li' );

			// for projects overlay, animation is not on li element but on b element
			if ( this.$.activeOverlay.hasClass( 'js-overlay-projects' ) ) {
				btns = this.$.activeOverlay.find( 'li > b' );
			}

			// hide btns
			this.$.overlaysClose.removeClass( 'visible' );
			this.$.overlaysPrev.removeClass( 'visible' );

			// btns animation
			this.headerShowOut = new Animator();
			this.headerShowOut
				.to( btns, {
					opacity: this.configAnimation.overlay.close.btns.toOpacity,
					x: this.configAnimation.overlay.close.btns.toX,
					duration: this.configAnimation.overlay.close.btns.duration,
					ease: this.configAnimation.overlay.close.btns.ease,
					clean: false
				}, this.configAnimation.overlay.close.btns.finalShift, this.configAnimation.overlay.close.btns.shift );

			let _this = this;
			this.headerShowOut.once( 'finish', function() {

				// end of animation is different if is project
				if ( !_this.$.activeOverlay.hasClass( 'js-overlay-projects' ) ) {

					if ( _this.isDesktop ) {

						// show spinner
						$( document.body ).find( '.spinner' ).addClass( 'visible' );

						// remove black layer for transition if exists
						if ( $( document.body ).find( '.layer-transition-black' ) ) {
							$( document.body ).find( '.layer-transition-black' ).remove();
						}

						// append black layer for transition
						let transitionLayer = $( Utils.createElement( {
							type: 'div',
							class: 'layer-transition-black',
							style: {
								'position': 'fixed',
								'top': 0,
								'left': 0,
								'width': '100%',
								'height': '100%',
								'z-index': '11',
								'background-color': '#000',
								'opacity': 1,
								'pointer-events': 'none'
							}
						} ) );
						$( document.body ).append( transitionLayer );

					}

					// show scrollbars
					document.documentElement.classList.remove( 'overlay-scrollbar' );

					callback();

				} else {

					// show scrollbars
					document.documentElement.classList.remove( 'overlay-scrollbar' );

					callback();

				}

			} );

		} else {

			if ( this.isDesktop ) {
				// show scrollbars
				document.documentElement.classList.remove( 'overlay-scrollbar' );
			}

			callback();

		}

	}

	destroy() {

		this.$.btns.off( 'click', this.btnsHandler );
		this.$.overlaysClose.off( 'click', this.overlaysCloseHandler );
		this.$.overlaysPrev.off( 'click', this.overlaysPrevHandler );
		this.$.burger.off( 'click', this.burgerOpenHandler );
		this.$.burgerClose.off( 'click', this.burgerCloseHandler );

		if ( ( !this.isMobile && Utils.isMac() ) || this.isTablet ) {
			this.sticker.destroy();
		}

	}

}

export default Header;
