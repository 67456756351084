'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var version = '{{versionString}}';

/**
 * @module shopify-buy
 * @submodule version
 */

exports.default = version;