import Component from 'framework/core/component';
import Template from 'templates/components/expertise/talents.html';

import Filters from 'app/components/expertise/talents/filters';

class Talents extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.createFilters();
		this.observe();

	}

	observe() {

		this.filterHandler = this.filterHandler.bind( this );
		this.filters.on( 'filter', this.filterHandler );

	}

	createFilters() {

		this.filters = new Filters( {
			container: this.$.container
		} );

	}

	filterHandler() {

		// trigger resize
		window.dispatchEvent( new CustomEvent( 'resize' ) );

		// trigger scroll
		window.scrollTo( 0, ( window.scrollY || window.pageYOffset ) + 1 );
		window.scrollTo( 0, ( window.scrollY || window.pageYOffset ) - 1 );

	}

	destroy() {

		this.filters.off( 'filter', this.filterHandler );
		this.filters.destroy();

		super.destroy();

	}

}

export default Talents;
