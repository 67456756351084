module.exports = function( self ) {

	self.onmessage = function( message ) {

		// Create Ajax instance

		var instance = new XMLHttpRequest();

		instance.open( message.data.method, message.data.url, true );

		// Open Ajax Instance

		if ( message.data.isArrayBuffer ) {
			instance.responseType = 'arraybuffer';
		}

		// Request Success callback

		instance.onload = function() {

			// If request get success, else..
			if ( instance.status >= 200 && instance.status < 400 ) {

				var response = message.data.isArrayBuffer ? instance.response : instance.responseText;

				self.postMessage( response );

			} else {

				console.log( instance );

				// error

			}

		};

		instance.send( JSON.stringify( message.data.datas ) );

	};

};
