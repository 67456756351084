var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div id=\"cookies\">\n\t<div>\n\t\t<p class=\"txt-small-1 white\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"cookies", env.opts.autoescape)),"description", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t<span class=\"close close-cookies round-hover\" data-roundhover>\n\t\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t\t\t<i class=\"ico-close light round-hover-ico\"></i>\n\t\t</span>\n\t</div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
