import EventEmitter from 'framework/core/eventEmitter';
import RafHandler from 'framework/tools/raf/rafHandler';

class Raf extends EventEmitter {

	constructor( options ) {

		super( options );

		this.fps = this.options.fps || 60;
		this.autorun = this.options.autorun !== undefined ? this.options.autorun : true;
		this.then = Date.now();
		this.interval = 1000 / this.fps;
		this.isActive = false;

		this.bound();

		if ( this.autorun ) {
			this.observe();
		}

	}

	bound() {

		this.tickHandler = this.tickHandler.bind( this );

	}

	observe() {

		this.isActive = true;
		RafHandler.on( 'tick', this.tickHandler );

	}

	unobserve() {

		this.isActive = false;
		RafHandler.off( 'tick', this.tickHandler );

	}

	start() {

		if ( !this.isActive ) {
			this.observe();
		} else {
			console.log( 'Raf is still active' );
		}

	}

	stop() {

		if ( this.isActive ) {
			this.unobserve();
		} else {
			console.log( 'Raf is not active' );
		}

	}

	tickHandler( infos ) {

		var now = Date.now();
		var delta = now - this.then;

		if ( delta > this.interval ) {

			this.trigger( 'tick', infos );
			this.then = now - ( delta % this.interval );

		}

	}

	destroy() {

		this.unobserve();

	}

}

export default Raf;
