import Model from 'framework/core/model';
import Utils from 'framework/utils';
import Datas from 'datas';

// available types : project, expertise, talent

class PostsModel extends Model {

	static getAll( type ) {

		let all = [];
		let datas = JSON.parse( Datas );

		// extract posts

		for ( let key in datas ) {

			let part = datas[ key ];
			if ( part.type === type ) {

				all.push( part );

			}

		}

		return all;

	}

	static getAllSortedByCategorySlug( type ) {

		let all = this.getAll( type );
		let sorted = {};

		for ( let index in all ) {
			index = parseInt( index );
			let post = all[ index ];
			if ( !sorted[ post.order ] ) {
				sorted[ post.order ] = [];
			}
			sorted[ post.order ].push( post );
		}

		for ( let f in sorted ) {

			let items = sorted[ f ];

			Utils.sortByKey( items, 'client' );

		}

		return sorted;


	}

	static getOneBySlug( type, slug, orderSlug ) {

		let all = this.getAll( type );
		if ( orderSlug ) {
			all = Utils.sortByKey( this.getAll( type ), orderSlug );
		}
		let one = {};

		for ( let index in all ) {

			index = parseInt( index );
			let post = all[ index ];

			if ( post.slug === slug ) {

				post.navIndex = 1 + index;

				one = post;

				// get prev post
				one.prev = all[ index - 1 ] || all[ all.length - 1 ];

				// get next post
				one.next = all[ index + 1 ] || all[ 0 ];

			}

		}

		return one;

	}

}

export default PostsModel;
