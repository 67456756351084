import MobileDetect from 'mobile-detect';

class Hardware {

	constructor( options ) {

		this.mobileDetect = new MobileDetect( window.navigator.userAgent );

		this.agent = navigator.userAgent.toLowerCase();
		this.pixelRatio = window.devicePixelRatio;
		this.isHDPI = this.pixelRatio > 1;
		this.isTouch = ( ( 'ontouchstart' in window ) || ( navigator.MaxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 ) );

		this.cores = navigator.hardwareConcurrency || 4;
		this.platform = navigator.platform || 'undefined';
		this.battery = navigator.battery || navigator.webkitBattery || 'undefined';
		this.vibrate = navigator.vibrate || navigator.mozVibrate || 'undefined';

		this.isTouchScreen = ( ( 'ontouchstart' in window ) || ( navigator.MaxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 ) );
		this.isIOS = /iPad|iPhone|iPod/.test( navigator.userAgent ) && !window.MSStream;
		this.isMac = this.platform.toUpperCase().indexOf( 'MAC' ) >= 0;
		this.isTablet = this.mobileDetect.tablet();
		this.isMobile = this.mobileDetect.phone();
		this.isDesktop = !this.isTablet && !this.isMobile;
		this.isIpad = this.isTablet && this.isIOS;
		this.isIphone = this.isMobile && this.isIOS;
		this.isIE = this.detectIE();

		if ( this.isTablet || this.isMobile ) {
			this.isDesktop = false;
		}

		if ( !this.isDesktop ) {
			document.body.classList.add( 'touch' );
		}

	}

	detectIE() {

		var ua = window.navigator.userAgent;

		var msie = ua.indexOf( 'MSIE ' );
		if ( msie > 0 ) {
			// IE 10 or older => return version number
			return parseInt( ua.substring( msie + 5, ua.indexOf( '.', msie ) ), 10 );
		}

		var trident = ua.indexOf( 'Trident/' );
		if ( trident > 0 ) {
			// IE 11 => return version number
			var rv = ua.indexOf( 'rv:' );
			return parseInt( ua.substring( rv + 3, ua.indexOf( '.', rv ) ), 10 );
		}

		var edge = ua.indexOf( 'Edge/' );
		if ( edge > 0 ) {
			// Edge (IE 12+) => return version number
			return parseInt( ua.substring( edge + 5, ua.indexOf( '.', edge ) ), 10 );
		}

		// other browser
		return false;
	}

}

export default new Hardware();
