var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"talent-mosaic pad-1-bot white-background\">\n\t<div class=\"grid\">\n\t\t<ul class=\"col-1-2 col-1-2-gut\">\n\n\t\t\t\t";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "talent")),"gallery", env.opts.autoescape);
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("image", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\n\t\t\t\t\t";
if(!(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0", env.opts.autoescape) % 2)) {
output += "\n\n\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t<div class=\"img\">\n\t\t\t\t\t\t\t\t<img\n\t\t\t\t\t\t\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"placehold", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t\t\t\tdata-lazy-scroll=\"true\"\n\t\t\t\t\t\t\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"url", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t\t\t\tdata-cors=\"true\"\n\t\t\t\t\t\t\t\t>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<h4 class=\"txt-med-1 pad-4-top black\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"title", env.opts.autoescape), env.opts.autoescape);
output += "</h4>\n\t\t\t\t\t\t</li>\n\n\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\t\t</ul>\n\n\t\t<ul class=\"col-1-2 col-1-2-gut\">\n\n\t\t\t\t";
frame = frame.push();
var t_7 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "talent")),"gallery", env.opts.autoescape);
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("image", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\n\t\t\t\t\t";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0", env.opts.autoescape) % 2)) {
output += "\n\n\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t<div class=\"img\">\n\t\t\t\t\t\t\t\t<img\n\t\t\t\t\t\t\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"placehold", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t\t\t\tdata-lazy-scroll=\"true\"\n\t\t\t\t\t\t\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"url", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t\t\t\tdata-cors=\"true\"\n\t\t\t\t\t\t\t\t>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<h4 class=\"txt-med-1 pad-4-top black\">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"title", env.opts.autoescape), env.opts.autoescape);
output += "</h4>\n\t\t\t\t\t\t</li>\n\n\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\t\t</ul>\n\n\t</div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
