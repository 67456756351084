import Component from 'framework/core/component';
import Scroller from 'framework/tools/scroller';
import Easer from 'framework/tools/easer';
import Template from 'templates/components/home/scrolling.html';

class Scrolling extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.speed = 0.5;

		this.$.overview = this.$.container.find( '.home-scrolling ul' );

		this.scroller = new Scroller();
		this.easer = new Easer();

		this.observe();

	}

	observe() {

		this.scrollHandler = this.scrollHandler.bind( this );
		this.scroller.on( 'scroll', this.scrollHandler );

		this.frameHandler = this.frameHandler.bind( this );
		this.easer.on( 'frame', this.frameHandler );

	}

	scrollHandler( infos ) {

		this.easer.set( {
			x: infos.top
		} );

	}

	frameHandler( datas ) {

		this.$.overview.css( {
			transform: 'translate3d(-' + ( datas.pos.x * this.speed ) + 'px, 0, 0)'
		} );

	}

	destroy() {

		this.scroller.off( 'scroll', this.scrollHandler );
		this.easer.off( 'frame', this.frameHandler );

	}

}

export default Scrolling;
