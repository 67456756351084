import Component from 'framework/core/component';
import {
	$
} from 'framework/tools/selector';
import Template from 'templates/components/heritage/products.html';
import Utils from 'framework/utils';

class Products extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.isMobile = Utils.isMobile();

		this.$.wrap = this.$.container.find( '.heritage-products' );
		this.$.products = this.$.wrap.find( '.product' );
		this.$.containerChoices = this.$.wrap.find( 'li' );
		this.$.choices = this.$.wrap.find( 'input' );
		this.$.buys = this.$.wrap.find( '.buy' );

		this.observe();
		this.selectDefault();

	}

	selectDefault() {

		let i = this.$.products.length;

		while ( i-- ) {

			let product = this.$.products.eq( i );
			if ( this.isMobile ) {
				let lis = product.find( 'li' );
				lis.eq( 0 ).trigger( 'click' );
			} else {
				let inputs = product.find( 'input' );
				inputs.eq( 0 ).trigger( 'click' );
			}

		}

	}

	observe() {

		if ( this.isMobile ) {

			this.chooseHandlerBis = this.chooseHandlerBis.bind( this );
			let i = this.$.choices.length;
			while ( i-- ) {
				let el = this.$.choices.eq( i ).parent();
				el.on( 'click', this.chooseHandlerBis );
			}

		} else {

			this.chooseHandler = this.chooseHandler.bind( this );
			this.$.choices.on( 'click', this.chooseHandler );

		}

	}

	chooseHandler( e ) {

		let el = $( e.target );

		this.selectHandler( el );

	}

	chooseHandlerBis( e ) {

		let el = $( e.target );

		this.selectHandler( el.find( 'input' ) );

		let lis = el.parent().find( 'input' );

		let i = lis.length;
		while ( i-- ) {
			lis.eq( i ).removeAttr( 'checked' );
		}

		el.find( 'input' ).attr( 'checked', true );

	}

	selectHandler( el ) {

		let product = el.attr( 'data-product' );
		let url = el.val();

		let i = this.$.buys.length;

		while ( i-- ) {
			let buy = this.$.buys.eq( i );

			if ( buy.attr( 'data-product' ) === product ) {
				buy.attr( 'href', url );
			}

		}

	}

	destroy() {

		if ( this.isMobile ) {

			let i = this.$.choices.length;
			while ( i-- ) {
				// let el = this.$.choices.eq( i ).parent();
				// el.off( 'click', this.chooseHandlerBis );
			}

		} else {

			this.$.choices.off( 'click', this.chooseHandler );

		}

		super.destroy();

	}

}

export default Products;
