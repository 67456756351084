import Model from 'framework/core/model';
import Datas from 'datas';

class DatasModel extends Model {

	static getAll() {

		return JSON.parse( Datas );

	}

}

export default DatasModel;
