import EventEmitter from 'framework/core/eventEmitter';
import Raf from 'framework/tools/raf';
import Utils from 'framework/utils';

class Scheduler extends EventEmitter {

	constructor( options ) {

		super( options );

		this.options = options;
		this.infos = {};

		this.raf = new Raf( {
			autorun: false
		} );

		// this.isProcessing = false;

		this.tasks = {
			queue: [],
			check: []
		};

		this.taskCount = 0;
		this.queueCount = 0;
		this.bound = null;

	}

	/**
	 * add() add a function to the tasks queue
	 *
	 * @param {Function} callback
	 * @return {Integer} delay (time in millisecond)
	 */

	add( callback, delay ) {

		this.tasks.queue.push( {
			callback: callback,
			delay: delay,
			index: this.taskCount++
		} );

		if ( !this.isProcessing ) {
			this.bound = this._tick.bind( this );
			this.raf.start();
			this.raf.on( 'tick', this.bound );
			this.isProcessing = true;
		}

	}


	/**
	 * _tick() is the RAF Handler
	 */

	_tick( currentTime ) {

		// Loop queue

		if ( currentTime.delta ) {
			this._loopQueue( currentTime );
		}

	}


	/**
	 * _loopQueue() loop through queue to check if task
	 * need to be processed
	 *
	 * @return {Integer} currentTime (time in millisecond)
	 */

	_loopQueue( currentTime ) {

		var i = this.tasks.queue.length;

		while ( i-- ) {

			// Get current task

			var task = this.tasks.queue[ i ];

			// Save task start time if not exist

			if ( !task.startTime ) {
				task.startTime = currentTime.now;
			}

			// Predict task end time by delay

			task.endTime = task.startTime + task.delay;

			// get adjusted endTime because of delta difference

			task.adjustedTime = ( task.endTime - ( currentTime.delta / 2 ) );

			// If Delay Time is elapsed

			if ( currentTime.now >= task.adjustedTime ) {

				// Save Timeshift after adjustment

				task.timeShift = currentTime.now - task.endTime;

				this._processTask( task );

			}

		}

	}

	/**
	 * _processTask() process task and remove it to queue
	 *
	 * @return {Object} task
	 */

	_processTask( task ) {

		var response = this._prepareResponse( task );

		// Launch Callback

		task.callback( response );

		if ( this.tasks ) {

			// Add task to checked task

			this.tasks.check.push( task );

			// Remove this processed task from tasks queue

			this.tasks.queue.splice( this.tasks.queue.indexOf( task ), 1 );

			// No more tasks in queue ?

			if ( !this.tasks.queue.length ) {
				this._onComplete();
			}

		}

	}


	/**
	 * _prepareResponse() prepare callback response
	 *
	 * @return {Object} task
	 */

	_prepareResponse( task ) {

		var response = {
			keyframe: {
				forecast: task.delay,
				real: task.delay + task.timeShift,
				shift: task.timeShift
			},
			index: task.index
		};

		return response;

	}

	/**
	 * _onComplete() is called when timeline is finished
	 *
	 */

	_onComplete() {

		if ( this.options.repeat !== this.queueCount ) {

			var i = this.tasks.check.length;

			while ( i-- ) {

				var task = this.tasks.check[ i ];
				task.startTime = null;
				this.tasks.queue.push( task );
			}

			this.queueCount++;

		} else {
			this.destroyTimer();
		}

	}

	destroyTimer() {

		this.options.timer.destroy();

	}

	/**
	 * destroy() remove all scheduled tasks
	 */

	destroy() {

		if ( this.isProcessing ) {

			this.isProcessing = false;

			this.raf.off( 'tick', this.bound );
			this.raf.destroy();

			Utils.nullObject( this );

		}

	}

}

export default Scheduler;
