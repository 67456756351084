import Storer from 'framework/tools/storer';
import Work from 'webworkify';
import Hardware from 'framework/tools/stage/Hardware';

class Ajax {

	constructor() {

		this.cores = Hardware.cores;

		this.blobReady = [ 'jpg', 'png', 'webp', 'gif' ];
		this.threads = [];
		this.thread = Work( require( './ajaxWorker.js' ) );
		this.isLoading = false;
		this.queue = [];
		this.createThreads();

		this.count = 0;

	}

	createThreads() {

		let i = this.cores;

		while ( i-- ) {
			this.threads[ i ] = Work( require( './ajaxWorker.js' ) );
		}

	}

	_get( params, callback ) {

		let task = {
			url: params.url,
			callback: callback,
			method: params.method || 'GET',
			datas: params.datas || {},
			thread: this.threads[ this.count % this.cores ]
		};

		if ( !this.isLoading ) {
			this.process( task );
			return;
		}

		this.queue.push( task );
		this.count++;


	}

	process( task ) {

		if ( !Storer.get( task.url ) ) {

			let url = task.url;
			let method = task.method;
			let datas = task.datas;
			let extension = url.indexOf( '.' ) !== -1 ? url.split( '.' ).pop() : null;
			let message = {};
			let thread = task.thread;

			this.isLoading = true;

			let _this = this;

			thread.onmessage = function( message ) {

				let response = message.data;
				let datas = {};

				if ( _this.blobReady.indexOf( extension ) !== -1 ) {

					let arrayBufferView = new Uint8Array( response );
					let blob = new Blob( [ arrayBufferView ], {
						type: 'image/' + extension
					} );
					var urlCreator = window.URL || window.webkitURL;
					var imageUrl = urlCreator.createObjectURL( blob );

					datas.url = imageUrl;
					datas.file = blob;

				} else {

					datas.url = task.url;
					datas.file = response;

				}

				Storer.set( task.url, datas );

				task.callback( datas );

				_this.isLoading = false;

				_this.treatQueue();

			};

			if ( url.indexOf( 'http' ) === -1 ) {
				url = window.location.protocol + '//' + window.location.host + task.url;
			}

			// set array buffer if it's an image

			if ( this.blobReady.indexOf( extension ) !== -1 ) {
				message = {
					method: method,
					datas: datas,
					url: url,
					isArrayBuffer: true
				};
			} else {
				message = {
					method: method,
					datas: datas,
					url: url,
					isArrayBuffer: false
				};
			}

			thread.postMessage( message );

		} else {

			task.callback( Storer.get( task.url ) );
			this.treatQueue();

		}

	}

	treatQueue() {


		if ( this.queue.length ) {

			var task = this.queue[ 0 ];

			// Shift queue array

			if ( this.queue.length > 1 ) {
				this.queue = this.queue.splice( 1, this.queue.length - 1 );
			} else {
				this.queue = [];
			}

			// load waiting tasks list

			this._get( task, task.callback );

		}

	}

}

export default new Ajax();
