var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"expertise-talents\">\n\t<div class=\"grid pad-1-bot\">\n\t\t<h2 class=\"txt-fat-2\">Talents</h2>\n\t</div>\n\t<div class=\"grid pad-1-bot\">\n\t\t<div class=\"col-1-3\">\n\t\t\t<ul class=\"filters bold\">\n\t\t\t\t<li data-filter=\"*\" class=\"is-active\">Tout</li>\n\t\t\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "talentsCategories");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("cat", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t<li data-filter=\"cat-";
output += runtime.suppressValue(runtime.memberLookup((t_4),"slug", env.opts.autoescape), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"name", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</li>\n\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t</ul>\n\t\t</div>\n\t\t<div class=\"col-2-3\">\n\t\t\t<ul class=\"thumbs\">\n\t\t\t\t";
frame = frame.push();
var t_7 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "posts")),"talents", env.opts.autoescape);
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("talent", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t<li class=\"col-1-3 ";
frame = frame.push();
var t_11 = runtime.memberLookup((t_8),"categories", env.opts.autoescape);
if(t_11) {var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("cat", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "cat-";
output += runtime.suppressValue(runtime.memberLookup((t_12),"slug", env.opts.autoescape), env.opts.autoescape);
output += " ";
;
}
}
frame = frame.pop();
output += "\">\n\t\t\t\t\t\t<a href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/talent/";
output += runtime.suppressValue(runtime.memberLookup((t_8),"slug", env.opts.autoescape), env.opts.autoescape);
output += "/\">\n\t\t\t\t\t\t\t<div class=\"img\">\n\t\t\t\t\t\t\t\t<img\n\t\t\t\t\t\t\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_3_4", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t\t\t\tdata-lazy-scroll=\"true\"\n\t\t\t\t\t\t\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"avatar", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t\t\t>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<span>";
output += runtime.suppressValue(runtime.memberLookup((t_8),"title", env.opts.autoescape), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t</a>\n\t\t\t\t\t</li>\n\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t</ul>\n\t\t</div>\n\t</div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
