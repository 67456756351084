import AbstractController from 'app/helpers/abstractController';
import PostsModel from 'app/models/postsModel';
import Featured from 'app/components/project/featured';
import Intro from 'app/components/project/intro';
import Content from 'app/components/project/content';
import Nav from 'app/components/project/nav';

class ProjectController extends AbstractController {

	constructor( options ) {

		super( options );

	}

	start() {

		// get datas

		this.datas.project = PostsModel.getOneBySlug( 'project', this.params.slug );

		// create components

		this.fallback();
		this.before();
		this.createComponents();
		this.after();

	}

	validate() {

		// project validation

		if ( !Object.keys( PostsModel.getOneBySlug( 'project', this.params.slug ) ).length ) {
			window.Router.createError();
		}

		// locale validation

		super.validate();

	}

	createComponents() {

		//console.log( 'Project controller, slug : ' + this.params.slug );

		// add Light class to header text

		this.$.container.find( 'header' ).addClass( 'bg-dark' );

		// create featured part

		this.components.featured = new Featured( {
			container: this.$.overview,
			datas: this.datas
		} );

		// create intro part

		this.components.intro = new Intro( {
			container: this.$.overview,
			datas: this.datas
		} );

		// create content part

		this.components.content = new Content( {
			container: this.$.overview,
			datas: this.datas
		} );

		// create nav part

		this.components.nav = new Nav( {
			container: this.$.overview,
			datas: this.datas
		} );

	}

	destroy() {

		super.destroy();

	}

}

export default ProjectController;
