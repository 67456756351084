var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"heritage-products\">\n\t<ul>\n\n\t";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "currentHeritage")),"products", env.opts.autoescape);
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("product", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\n\t\t";
if(runtime.memberLookup((runtime.memberLookup((t_4),"attrs", env.opts.autoescape)),"available", env.opts.autoescape)) {
output += "\n\n\t\t\t<li class=\"product\">\n\t\t\t\t<div class=\"img\">\n\t\t\t\t\t<div class=\"grid img\">\n\t\t\t\t\t\t<img\n\t\t\t\t\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"images", env.opts.autoescape)),0, env.opts.autoescape)),"placehold", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t\tdata-lazy-scroll=\"true\"\n\t\t\t\t\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"images", env.opts.autoescape)),0, env.opts.autoescape)),"src", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"grid pad-2-bot pad-3-top\">\n\t\t\t\t\t<div class=\"col-2-3\">\n\t\t\t\t\t\t<h2 class=\"txt-med-2\">0";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index", env.opts.autoescape), env.opts.autoescape);
output += ". ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"attrs", env.opts.autoescape)),"content", env.opts.autoescape)),"title", env.opts.autoescape), env.opts.autoescape);
output += "</h2>\n\t\t\t\t\t\t<h3 class=\"bold\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"attrs", env.opts.autoescape)),"content", env.opts.autoescape)),"subtitle", env.opts.autoescape), env.opts.autoescape);
output += "</h3>\n\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"attrs", env.opts.autoescape)),"content", env.opts.autoescape)),"ref", env.opts.autoescape)) {
output += "\n\t\t\t\t\t\t<span class=\"txt-small-1\">Réf : ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"attrs", env.opts.autoescape)),"content", env.opts.autoescape)),"ref", env.opts.autoescape), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t<p class=\"txt-small-3 pad-4\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"attrs", env.opts.autoescape)),"content", env.opts.autoescape)),"text", env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t\t\t\t\t<span class=\"black\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"attrs", env.opts.autoescape)),"content", env.opts.autoescape)),"limit", env.opts.autoescape), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"col-1-3\">\n\t\t\t\t\t\t<form class=\"pad-3-bot\">\n\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_7 = runtime.memberLookup((t_4),"variants", env.opts.autoescape);
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("variant", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t\t\t\t\t<li class=\"txt-small-3\">\n\t\t\t\t\t\t\t\t\t\t<input type=\"radio\" name=\"same\" value=\"";
output += runtime.suppressValue((lineno = 32, colno = 69, runtime.callWrap(runtime.memberLookup((t_8),"checkoutUrl", env.opts.autoescape), "variant[\"checkoutUr\"]", [1])), env.opts.autoescape);
output += "\" data-product=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"id", env.opts.autoescape), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t<label class=\"black\">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"title", env.opts.autoescape), env.opts.autoescape);
output += "</label>\n\t\t\t\t\t\t\t\t\t\t<span class=\"bold\">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"price", env.opts.autoescape), env.opts.autoescape);
output += " €</span>\n\t\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t</form>\n\t\t\t\t\t\t<a href=\"\" target=\"_blank\" class=\"btn-black buy\" data-product=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"id", env.opts.autoescape), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t<i class=\"ico\">\n\t\t\t\t\t\t\t\t<svg class=\"svg\">\n\t\t\t\t\t\t\t\t\t<use xlink:href=\"#ico-cart\">\n\t\t\t\t\t\t\t\t</svg>\n\t\t\t\t\t\t\t</i>\n\t\t\t\t\t\t\t<span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"heritage", env.opts.autoescape)),"buy", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t</a>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</li>\n\n\t\t";
;
}
output += "\n\n\n\t";
;
}
}
frame = frame.pop();
output += "\n\t</ul>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
