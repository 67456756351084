var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"project-content white-background\">\n\n";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"missions", env.opts.autoescape);
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("mission", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\n\t<div class=\"grid pad-1\">\n\t\t<div class=\"col-1-3\">\n\t\t\t<span class=\"txt-small-1\">0";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index", env.opts.autoescape), env.opts.autoescape);
output += "</span>\n\t\t</div>\n\t\t<div class=\"col-2-3\">\n\t\t\t<h2 class=\"txt-med-3 pad-3-bot\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.memberLookup((t_4),"title", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape)), env.opts.autoescape);
output += "</h2>\n\t\t\t<p class=\"txt-small-3\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"description", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t</div>\n\t</div>\n\n\t";
if(runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"type", env.opts.autoescape) == "mosaik") {
output += "\n\n\t<div class=\"img\">\n\t\t<img\n\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\tdata-lazy-scroll=\"true\"\n\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"src", env.opts.autoescape)),0, env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t>\n\t</div>\n\n\t<div class=\"img col-1-2\">\n\t\t<img\n\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\tdata-lazy-scroll=\"true\"\n\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"src", env.opts.autoescape)),1, env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t>\n\t</div>\n\n\t<div class=\"img col-1-2\">\n\t\t<img\n\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\tdata-lazy-scroll=\"true\"\n\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"src", env.opts.autoescape)),2, env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t>\n\t</div>\n\n\t";
;
}
else {
if(runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"type", env.opts.autoescape) == "viewer") {
output += "\n\n\t<div class=\"img\">\n\t\t<img\n\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\">\n\t\t<div class=\"sportagraph-embed\" data-src=\"viewer/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"src", env.opts.autoescape), env.opts.autoescape);
output += "?prefetch=10\"></div>\n\t</div>\n\n\t";
;
}
else {
if(runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"type", env.opts.autoescape) == "video") {
output += "\n\n\t<div class=\"img video\">\n\t\t<span class=\"play round-hover\" data-roundhover>\n\t\t\t<i class=\"ico-play round-hover-ico\">\n\t\t\t\t<svg class=\"svg\">\n\t\t\t\t\t<use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#ico-play\"></use>\n\t\t\t\t</svg>\n\t\t\t</i>\n\t\t\t<i class=\"ico-play round-hover-ico\">\n\t\t\t\t<svg class=\"svg\">\n\t\t\t\t\t<use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#ico-play\"></use>\n\t\t\t\t</svg>\n\t\t\t</i>\n\t\t\t<i class=\"ico-play round-hover-ico\">\n\t\t\t\t<svg class=\"svg\">\n\t\t\t\t\t<use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"#ico-play\"></use>\n\t\t\t\t</svg>\n\t\t\t</i>\n\t\t</span>\n\t\t<video src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"src", env.opts.autoescape)),"video", env.opts.autoescape), env.opts.autoescape);
output += "\" preload=\"none\" type=\"video/mp4\" muted=\"true\" loop=\"true\"></video>\n\t\t<div class=\"sportagraph-embed\" data-src=\"player/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"src", env.opts.autoescape)),"widget", env.opts.autoescape), env.opts.autoescape);
output += "\"></div>\n\t\t<img\n\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\tdata-lazy-scroll=\"true\"\n\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"src", env.opts.autoescape)),"image", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t>\n\t</div>\n\n\t";
;
}
else {
if(runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"type", env.opts.autoescape) == "image") {
output += "\n\n\t<div class=\"img\">\n\t\t<img\n\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\tdata-lazy-scroll=\"true\"\n\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"src", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t>\n\t</div>\n\n\t";
;
}
else {
if(runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"type", env.opts.autoescape) == "compare") {
output += "\n\n\t<div class=\"compare-slider\">\n\t\t<ul>\n\t\t\t<li class=\"before\">\n\t\t\t\t<div class=\"img img-container\">\n\t\t\t\t\t<img\n\t\t\t\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\tdata-lazy-scroll=\"true\"\n\t\t\t\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"before", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t>\n\t\t\t\t</div>\n\t\t\t</li>\n\t\t\t<li class=\"after\">\n\t\t\t\t<div class=\"img img-container\">\n\t\t\t\t\t<img\n\t\t\t\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\tdata-lazy-scroll=\"true\"\n\t\t\t\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"media", env.opts.autoescape)),"after", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t>\n\t\t\t\t</div>\n\t\t\t</li>\n\t\t</ul>\n\t\t<div class=\"nav-drag\">\n\t\t\t<div class=\"nav-cursor-line\">\n\t\t\t\t<div class=\"line black\"></div>\n\t\t\t\t<div class=\"line white\"></div>\n\t\t\t\t<div class=\"nav-cursor\">\n\t\t\t\t\t<div class=\"icons\">\n\t\t\t\t\t\t<svg class=\"svg\">\n\t\t\t\t\t\t\t<use xlink:href=\"#ico-arrow-left\">\n\t\t\t\t\t\t</svg>\n\t\t\t\t\t\t<svg class=\"svg\">\n\t\t\t\t\t\t\t<use xlink:href=\"#ico-arrow-right\">\n\t\t\t\t\t\t</svg>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\n\t";
;
}
;
}
;
}
;
}
;
}
output += "\n\n";
;
}
}
frame = frame.pop();
output += "\n\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
