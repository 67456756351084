import Component from 'framework/core/component';
import Template from 'templates/components/talent/slider.html';
import Carousell from 'app/components/common/carousel';
import Parallax from 'framework/tools/parallax';
import Utils from 'framework/utils';

import {
	$
} from 'framework/tools/selector';
import Animator from 'framework/tools/animator';

class Slider extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.isDesktop = Utils.isDesktop();

		this.$.carousels = this.$.container.find( '.talent-carousel' );

		this.$.sectionIntro = this.$.container.find( '.talent-intro' );
		this.$.sectionMosaic = this.$.container.find( '.talent-mosaic' );
		this.$.sectionBiography = this.$.container.find( '.talent-biography' );

		this.carousels = [];

		this.parallax = [];

		this.createCarousels();

		// if( this.isDesktop ){
		// 	this.createParallax();
		// }

		this.show();

	}

	createCarousels() {

		let i = this.$.carousels.length;

		while ( i-- ) {

			this.carousels[ i ] = new Carousell( {
				container: this.$.carousels.eq( i ),
				autorun: false
			} );

		}
	}

	createParallax() {

		let i = this.$.carousels.length;

		while ( i-- ) {

			this.parallax[ i ] = new Parallax( {
				containerFixed: this.$.carousels.eq( i ).find( '.viewport' ),
				elements: this.$.carousels.eq( i ).find( '.parallax' ),
				atVisible: true,
				visibleElmt: this.$.carousels.eq( i ).find( '.overview' )
			} );

		}

	}

	prepareAnimations() {

		this.showIn = new Animator();

		this.showIn
			.from( this.$.sectionIntro, {
				opacity: 0
			} )
			.from( this.$.sectionMosaic, {
				opacity: 0
			} )
			.from( this.$.sectionBiography, {
				opacity: 0
			} )
			.from( this.$.carousels, {
				opacity: 0
			} );

	}

	show() {

		if ( !window.isOverlay ) {

			this.prepareAnimations();

			let duration = 800;

			let _this = this;
			setTimeout( function() {

				_this.showIn
					.to( _this.$.sectionIntro, {
						opacity: 1,
						duration: duration
					}, -duration )
					.to( _this.$.sectionMosaic, {
						opacity: 1,
						duration: duration
					}, -duration )
					.to( _this.$.sectionBiography, {
						opacity: 1,
						duration: duration
					}, -duration )
					.to( _this.$.carousels, {
						opacity: 1,
						duration: duration
					}, 0 );

			}, 1000 );

		} else {

			if ( this.isDesktop ) {

				this.showIn = new Animator();

				let _this = this;
				setTimeout( function() {

					$( document.body ).find( '.spinner' ).removeClass( 'visible' );

					_this.showIn
						.to( $( document.body ).find( '.layer-transition-black' ), {
							opacity: 0,
							duration: 2000
						}, 0 );

				}, 1200 );

			}

			window.isOverlay = false;

		}

	}

	hide( callback ) {

		if ( !window.isOverlay ) {

			if ( this.isDesktop ) {

				let transitionLayer = $( Utils.createElement( {
					type: 'div',
					class: 'layer-transition',
					style: {
						'position': 'fixed',
						'top': 0,
						'left': 0,
						'width': '100%',
						'height': '100%',
						'z-index': '9',
						'background-color': '#fff',
						'opacity': 0,
						'pointer-events': 'none'
					}
				} ) );

				this.$.container.append( transitionLayer );

				this.showOut = new Animator();

				this.showOut
					.to( transitionLayer, {
						opacity: 1,
						duration: 800,
						clean: false
					}, 0 );


				this.showOut.once( 'finish', function() {
					callback();
				} );

			} else {

				callback();

			}

		} else {

			callback();

		}

	}

	destroy() {

		// if( this.isDesktop ){

		// 	let i = this.$.carousels.length;

		// 	while ( i-- ) {

		// 		this.parallax[ i ].destroy();

		// 	}

		// }

	}

}

export default Slider;
