import Model from 'framework/core/model';
import ShopifyManager from 'app/managers/shopifyManager';

class HeritagesModel extends Model {

	static getAllCollections( callback ) {

		ShopifyManager.getAllCollections( function( collections ) {
			callback( collections );
		} );

	}

	static getCollectionBySlug( slug, callback ) {

		this.getAllCollections( function( collections ) {

			let one = {};

			for ( let index in collections ) {

				index = parseInt( index );
				let collection = collections[ index ];

				if ( collection.attrs.handle === slug ) {

					one = collection;
					one.navIndex = 1 + index;

					// get prev post
					one.prev = collections[ index - 1 ] || collections[ collections.length - 1 ];

					// get next post
					one.next = collections[ index + 1 ] || collections[ 0 ];

				}

			}

			callback( one );

		} );

	}

	static getProductsByCollectionsId( id, callback ) {

		ShopifyManager.getProductsByCollectionsId( id, function( collection ) {
			callback( collection );
		} );

	}

}

export default HeritagesModel;
