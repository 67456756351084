import EventEmitter from 'framework/core/eventEmitter';
import Raf from 'framework/tools/raf';

class Easer extends EventEmitter {

	constructor( options ) {

		super( options );

		this.target = this.options.target || {};
		this.pos = this.options.pos || {};
		this.autorun = this.options.autorun || false;
		this.waste = {};

		this.isActive = this.autorun ? true : false;

		this.ease = this.options.ease || 0.1;
		this.easeLimit = this.options.easeLimit || 0.1;

		this.raf = new Raf( {
			autorun: this.autorun
		} );

		this.bound();
		this.observe();

	}

	bound() {

		this.bound = this.tick.bind( this );

	}

	observe() {

		this.raf.on( 'tick', this.bound );

	}

	force( pos ) {

		for ( let name in pos ) {

			this.target[ name ] = pos[ name ];
			this.pos[ name ] = pos[ name ];

		}

	}

	set( targets ) {

		for ( let name in targets ) {

			let val = targets[ name ];

			if ( !this.target[ name ] ) {
				this.target[ name ] = 0;
				this.pos[ name ] = 0;
				this.waste[ name ] = 0;
			}

			if ( val !== this.pos[ name ] ) {

				this.target[ name ] = val;

				if ( !this.isActive ) {
					this.isActive = true;
					this.raf.start();
				}

			}

		}

	}

	stop() {

		this.force( this.target );

	}

	tick() {

		let framed = false;

		for ( let name in this.target ) {

			if ( this.target[ name ] !== this.pos[ name ] ) {

				// ease

				this.pos[ name ] += ( this.target[ name ] - this.pos[ name ] ) * this.ease;

				// limit easing

				this.waste[ name ] = this.pos[ name ] - this.target[ name ];

				if ( Math.abs( this.waste[ name ] ) < this.easeLimit ) {
					this.pos[ name ] = this.target[ name ];
					this.waste[ name ] = 0;
				}

				framed = true;

			}

		}

		if ( framed ) {
			this.trigger( 'frame', {
				target: this.target,
				pos: this.pos,
				waste: this.waste
			} );
		} else {
			if ( this.isActive ) {
				this.isActive = false;
				this.raf.stop();
			}
		}

	}

	destroy() {

		if ( this.isActive ) {
			this.isActive = false;
			this.raf.stop();
		}

		this.raf.off( 'tick', this.bound );

	}

}

export default Easer;
