import Abstract from 'framework/core';

class Storer {

	constructor() {

		this.instance = [];

	}

	set( key, val ) {
		this.instance[ key ] = val;
	}

	get( key ) {
		return this.instance[ key ];
	}

	erase( key ) {
		this.instance[ key ] = null;
	}

}

export default new Storer();
