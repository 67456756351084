import EventEmitter from 'framework/core/eventEmitter';

class Resizer extends EventEmitter {

	constructor( options ) {

		super( options );

		this.observe();

	}

	observe() {

		this.resizeHandler = this.resizeHandler.bind( this );
		window.addEventListener( 'resize', this.resizeHandler );

	}

	resizeHandler() {

		let _this = this;

		window.setTimeout( function() {

			let infos = {
				width: window.innerWidth,
				height: window.innerHeight
			};

			_this.trigger( 'resize', infos );

		}, 500 );

	}

	destroy() {

		window.removeEventListener( 'resize', this.resizeHandler );

	}

}

export default Resizer;
