// --------------------------------------------------------------o
// Polyfills & Tools
// --------------------------------------------------------------o

import 'babel-polyfill';
import 'fetch-polyfill';
import 'custom-event-polyfill';
import Fastclick from 'fastclick';
import Utils from 'framework/utils';

document.addEventListener( 'DOMContentLoaded', function() {
	Fastclick( document.body );
	document.documentElement.classList.add( Utils.getNavigator() );
	if ( Utils.isMac() ) {
		document.documentElement.classList.add( 'mac' );
	} else {
		document.documentElement.classList.add( 'win' );
	}
	if ( Utils.isIOS() ) {
		document.documentElement.classList.add( 'iOS' );
	}
}, false );


// --------------------------------------------------------------o
// Import Core and Config
// --------------------------------------------------------------o

import Framework from 'framework';
import Config from '../../config.yml';


// --------------------------------------------------------------o
// Import Controllers
// --------------------------------------------------------------o

import LocaleRedirect from 'app/helpers/localeRedirect';
import HomeController from 'app/controllers/homeController';
import ProjectController from 'app/controllers/projectController';
import ExpertiseController from 'app/controllers/expertiseController';
import HeritageController from 'app/controllers/heritageController';
import TalentController from 'app/controllers/talentController';
import AboutController from 'app/controllers/aboutController';
import LegalsController from 'app/controllers/legalsController';
import ErrorController from 'app/controllers/errorController';


// --------------------------------------------------------------o
// Create App
// --------------------------------------------------------------o

export default new Framework( {

	config: Config,

	routes: {

		redirect: {
			path: '/',
			controller: LocaleRedirect
		},
		error: {
			path: '/:locale/404',
			controller: ErrorController
		},

		home: {
			path: '/:locale/',
			controller: HomeController
		},

		project: {
			path: '/:locale/project/:slug/',
			controller: ProjectController
		},

		expertise: {
			path: '/:locale/expertise/:slug/',
			controller: ExpertiseController
		},

		heritage: {
			path: '/:locale/heritage/:slug/',
			controller: HeritageController
		},

		talent: {
			path: '/:locale/talent/:slug/',
			controller: TalentController
		},

		about: {
			path: '/:locale/about/',
			controller: AboutController
		},

		legals: {
			path: '/:locale/legals/',
			controller: LegalsController
		}

	}

} );
