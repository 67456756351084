import EventEmitter from 'framework/core/eventEmitter';

import Resizer from 'framework/tools/resizer';
import Scroller from 'framework/tools/scroller';

class Visibler extends EventEmitter {

	constructor( options ) {

		super( options );

		this.$ = {};
		this.$.element = options.element;
		this.delta = options.delta || 0;

		this.sizes = {};
		this.isVisible = false;

		this.resizer = new Resizer();
		this.scroller = new Scroller();

		this.setSize();
		this.observe();

	}

	observe() {

		this.resizeHandler = this.resizeHandler.bind( this );
		this.resizer.on( 'resize', this.resizeHandler );

		this.scrollHandler = this.scrollHandler.bind( this );
		this.scroller.on( 'scroll', this.scrollHandler );

	}

	scrollHandler( infos ) {

		if ( infos.bottom > this.sizes.top + this.delta ) {
			if ( infos.top < this.sizes.bottom + this.delta ) {
				if ( !this.isVisible ) {
					this.isVisible = true;
					this.$.element.addClass( 'visible' );
					this.trigger( 'visible', this.options );
				}
			} else {
				if ( this.isVisible ) {
					this.isVisible = false;
					this.$.element.removeClass( 'visible' );
					this.trigger( 'hidden', this.options );
				}
			}
		} else {
			if ( this.isVisible ) {
				this.isVisible = false;
				this.$.element.removeClass( 'visible' );
				this.trigger( 'hidden', this.options );
			}
		}

	}

	resizeHandler() {

		this.setSize();

	}

	setSize() {

		let sizes = this.$.element.sizes();

		let realSizes = {
			top: sizes.top + ( window.scrollY || window.pageYOffset ),
			bottom: sizes.bottom + ( window.scrollY || window.pageYOffset ),
			width: sizes.width,
			height: sizes.height
		};

		this.sizes = realSizes;

	}

	destroy() {

		this.resizer.off( 'resize', this.resizeHandler );
		this.scroller.off( 'scroll', this.scrollHandler );

		this.resizer.destroy();
		this.scroller.destroy();

	}

}

export default Visibler;
