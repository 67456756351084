import EventEmitter from 'framework/core/eventEmitter';

// import Utils from 'framework/utils';

import Resizer from 'framework/tools/resizer';
import Scroller from 'framework/tools/scroller';

class Sticker extends EventEmitter {

	constructor( options ) {

		super( options );

		// this.isDesktop = Utils.isDesktop();

		this.classname = options.classname || 'sticky';
		this.start = options.start;
		this.startSmartShown = options.startSmartShown;
		this.smartLimit = options.smartLimit || 100;

		this.$ = {};
		this.$.element = options.element;

		this.sizes = {};
		this.smartShown = true;

		this.resizer = new Resizer();
		this.scroller = new Scroller();

		this.setSize();
		this.observe();

	}

	observe() {

		this.resizeHandler = this.resizeHandler.bind( this );
		this.resizer.on( 'resize', this.resizeHandler );

		this.scrollHandler = this.scrollHandler.bind( this );
		this.scroller.on( 'scroll', this.scrollHandler );

		this.mousewheelHandler = this.mousewheelHandler.bind( this );
		this.scroller.on( 'mousewheel', this.mousewheelHandler );

		this.touchmoveHandler = this.touchmoveHandler.bind( this );
		this.scroller.on( 'touchmove', this.touchmoveHandler );

	}

	scrollHandler( infos ) {

		if ( infos.top > this.start ) {

			this.$.element.addClass( this.classname );
			this.trigger( 'sticked', this.options );

			if ( infos.top > this.startSmartShown ) {
				this.smartShown = true;
			} else {
				this.smartShown = false;
			}

		} else {

			this.smartShown = false;
			this.$.element.removeClass( this.classname );
			this.trigger( 'unsticked', this.options );

		}

	}

	mousewheelHandler( infos ) {

		if ( this.smartShown ) {

			if ( infos.direction === 'up' ) {

				if ( this.scroller.scrollY > this.scroller.saved.scroll.lastDown ) {
					this.scroller.saved.scroll.lastDown = this.scroller.scrollY;
				}

				let distance = this.scroller.saved.scroll.lastDown - this.scroller.scrollY;

				if ( distance > this.smartLimit ) {
					this.$.element.addClass( 'shown' );
				} else {
					this.$.element.removeClass( 'shown' );
				}

			} else if ( infos.direction === 'down' ) {

				this.$.element.removeClass( 'shown' );

			}

		} else {
			this.$.element.removeClass( 'shown' );
		}

	}

	touchmoveHandler( infos ){

		if ( this.smartShown ) {

			if ( infos.direction === 'up' ) {

				if ( this.scroller.scrollY > this.scroller.saved.scroll.lastDown ) {
					this.scroller.saved.scroll.lastDown = this.scroller.scrollY;
				}

				let distance = this.scroller.saved.scroll.lastDown - this.scroller.scrollY;

				if ( distance > this.smartLimit ) {
					this.$.element.addClass( 'shown' );
				} else {
					this.$.element.removeClass( 'shown' );
				}

			} else if ( infos.direction === 'down' ) {

				this.$.element.removeClass( 'shown' );

			}

		} else {

			this.$.element.removeClass( 'shown' );

		}

	}

	resizeHandler( infos ) {

		this.setSize();

	}

	setSize() {

		let sizes = this.$.element.sizes();

		let realSizes = {
			top: sizes.top + ( window.scrollY || window.pageYOffset ),
			bottom: sizes.bottom + ( window.scrollY || window.pageYOffset ),
			width: sizes.width,
			height: sizes.height
		};

		this.sizes = realSizes;

	}

	destroy() {

		this.resizer.off( 'resize', this.resizeHandler );
		this.scroller.off( 'scroll', this.scrollHandler );

		this.resizer.destroy();
		this.scroller.destroy();

	}

}

export default Sticker;
