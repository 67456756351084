var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<footer class=\"bg-dark pad-2-bot\">\n\n\t<div class=\"contact pad-2\">\n\t\t<object data=\"/contact.html?title=";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contactform", env.opts.autoescape)),"title", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "&placeholderFirstName=";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contactform", env.opts.autoescape)),"firstname", env.opts.autoescape)),"placeholder", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "&placeholderLastName=";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contactform", env.opts.autoescape)),"lastname", env.opts.autoescape)),"placeholder", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "&placeholderEmail=";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contactform", env.opts.autoescape)),"email", env.opts.autoescape)),"placeholder", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "&placeholderMessage=";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contactform", env.opts.autoescape)),"message", env.opts.autoescape)),"placeholder", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "&next=";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contactform", env.opts.autoescape)),"next", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "&submit=";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contactform", env.opts.autoescape)),"submit", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "&acontact=";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contactform", env.opts.autoescape)),"acontact", env.opts.autoescape)),"placeholder", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "&afirstname=";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contactform", env.opts.autoescape)),"afirstname", env.opts.autoescape)),"placeholder", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "&alastname=";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contactform", env.opts.autoescape)),"alastname", env.opts.autoescape)),"placeholder", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "&amessage=";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contactform", env.opts.autoescape)),"amessage", env.opts.autoescape)),"placeholder", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "&errorempty=";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contactform", env.opts.autoescape)),"errorempty", env.opts.autoescape)),"placeholder", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "&errormail=";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contactform", env.opts.autoescape)),"errormail", env.opts.autoescape)),"placeholder", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "\" type=\"text/html\" width=\"100%\">\n\t\t</object>\n\t</div>\n\n\t<div class=\"grid\">\n\t\t<ul class=\"social pad-3-bot\">\n\t\t";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "informations")),"social", env.opts.autoescape);
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("social", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t<li>\n\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"link", env.opts.autoescape), env.opts.autoescape);
output += "\" target=\"_blank\">\n\t\t\t\t\t<i class=\"ico\">\n\t\t\t\t\t\t<svg>\n\t\t\t\t\t\t\t<use xlink:href=\"#ico-";
output += runtime.suppressValue(runtime.memberLookup((t_4),"ico", env.opts.autoescape), env.opts.autoescape);
output += "\"></use>\n\t\t\t\t\t\t</svg>\n\t\t\t\t\t</i>\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_4),"label", env.opts.autoescape), env.opts.autoescape);
output += "\n\t\t\t\t</a>\n\t\t\t</li>\n\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t</ul>\n\t\t<ul class=\"others\">\n\t\t\t<li class=\"col-1-3 txt-small-2\">\n\t\t\t\t";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "informations")),"contact", env.opts.autoescape)),"adress", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape)), env.opts.autoescape);
output += "\n\t\t\t</li>\n\t\t\t<li class=\"col-1-3 txt-small-2\">\n\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contact", env.opts.autoescape)),"email", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += " : <a href=\"mailto:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "informations")),"contact", env.opts.autoescape)),"email", env.opts.autoescape), env.opts.autoescape);
output += "\" target=\"_blank\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "informations")),"contact", env.opts.autoescape)),"email", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</a><br/>\n\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contact", env.opts.autoescape)),"phone", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += " : ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "informations")),"contact", env.opts.autoescape)),"phone", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "\n\t\t\t</li>\n\t\t\t<li class=\"col-1-3 txt-small-2\">\n\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contact", env.opts.autoescape)),"copyright", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "<br/>\n\t\t\t\t<a href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/legals/\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contact", env.opts.autoescape)),"legals", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</a><br/>\n\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contact", env.opts.autoescape)),"credits", env.opts.autoescape)),"link", env.opts.autoescape), env.opts.autoescape);
output += "\" target=\"_blank\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"common", env.opts.autoescape)),"contact", env.opts.autoescape)),"credits", env.opts.autoescape)),"label", env.opts.autoescape), env.opts.autoescape);
output += "</a>\n\t\t\t\t<span class=\"locale\">\n\t\t\t\t\t<a class=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "locale") == "fr"?"is-active":runtime.contextOrFrameLookup(context, frame, "null")), env.opts.autoescape);
output += "\" href=\"/fr/\">FR</a>\n\t\t\t\t\t<a class=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "locale") == "en"?"is-active":runtime.contextOrFrameLookup(context, frame, "null")), env.opts.autoescape);
output += "\" href=\"/en/\">EN</a>\n\t\t\t\t</span>\n\t\t\t</li>\n\t\t</ul>\n\t</div>\n</footer>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
