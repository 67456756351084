import EventEmitter from 'framework/core/eventEmitter';
import ShopifyBuy from 'shopify-buy';
import Storer from 'framework/tools/storer';

class ShopifyManager extends EventEmitter {

	constructor( options ) {

		super( options );

	}

	getProductsByCollectionsId( id, callback ) {

		if ( !Storer.get( 'fetchQueryProducts' + id ) ) {

			let client = ShopifyBuy.buildClient( window.Config.shopify );

			client
				.fetchQueryProducts( {
					collection_id: id
				} )
				.then( function( datas ) {
					Storer.set( 'fetchQueryProducts' + id, datas );
					callback( datas );
				} );

		} else {

			callback( Storer.get( 'fetchQueryProducts' + id ) );

		}

	}

	getAllCollections( callback ) {

		if ( !Storer.get( 'fetchQueryCollections' ) ) {

			let client = ShopifyBuy.buildClient( window.Config.shopify );

			client
				.fetchQueryCollections()
				.then( function( datas ) {
					Storer.set( 'fetchQueryCollections', datas );
					callback( datas );
				} );

		} else {

			callback( Storer.get( 'fetchQueryCollections' ) );

		}

	}

	destroy() {

	}

}

export default new ShopifyManager();
