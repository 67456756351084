class Software {

	static init() {

		this.agent = navigator.userAgent.toLowerCase();

		this.lang = navigator.language || navigator.userLanguage;
		this.isIE = this.getIE();
		this.isChrome = !this.isIE && this.detect( 'chrome' );
		this.isSafari = !this.isChrome && !this.isIE && this.detect( 'safari' );
		this.isFirefox = this.detect( 'firefox' );
		this.version = this.getVersion();
		this.prefix = this.getPrefix();

		// todo : tick dat shit

		// if ( window.performance ) {
		// 	this.memory = {
		// 		used: window.performance.memory.usedJSHeapSize,
		// 		limit: window.performance.memory.jsHeapSizeLimit,
		// 		total: window.performance.memory.totalJSHeapSize,
		// 		ratio: window.performance.memory.usedJSHeapSize / window.performance.memory.totalJSHeapSize
		// 	};
		// }

		// this.fps = 60;

	}

	static getIE() {

		if ( this.detect( 'msie' ) ) {
			return true;
		}
		if ( this.detect( 'trident' ) && this.detect( 'rv:' ) ) {
			return true;
		}
		if ( this.detect( 'windows' ) && this.detect( 'edge' ) ) {
			return true;
		}

	}

	static getVersion() {

		try {
			if ( this.isChrome ) {
				return Number( this.agent.split( 'chrome/' )[ 1 ].split( '.' )[ 0 ] );
			}
			if ( this.isFirefox ) {
				return Number( this.agent.split( 'firefox/' )[ 1 ].split( '.' )[ 0 ] );
			}
			if ( this.isSafari ) {
				return Number( this.agent.split( 'version/' )[ 1 ].split( '.' )[ 0 ].charAt( 0 ) );
			}
			if ( this.isIE ) {
				if ( this.detect( 'msie' ) ) {
					return Number( this.agent.split( 'msie ' )[ 1 ].split( '.' )[ 0 ] );
				}
				if ( this.detect( 'rv:' ) ) {
					return Number( this.agent.split( 'rv:' )[ 1 ].split( '.' )[ 0 ] );
				}
				return Number( this.agent.split( 'edge/' )[ 1 ].split( '.' )[ 0 ] );
			}
		} catch ( e ) {
			return -1;
		}

	}

	static getNavigatorName() {

		try {
			if ( this.isChrome ) {
				return 'chrome';
			}
			if ( this.isFirefox ) {
				return 'firefox';
			}
			if ( this.isSafari ) {
				return 'safari';
			}
			if ( this.isIE ) {
				return 'ie';
			}
		} catch ( e ) {
			return -1;
		}

	}

	static getPrefix() {

		var pre = '';
		var styles = window.getComputedStyle( document.documentElement, '' );
		pre = ( Array.prototype.slice.call( styles ).join( '' ).match( /-(moz|webkit|ms)-/ ) || ( styles.OLink === '' && [ '', 'o' ] ) )[ 1 ];
		var dom = ( 'WebKit|Moz|MS|O' ).match( new RegExp( '(' + pre + ')', 'i' ) )[ 1 ];
		var IE = this.detect( 'trident' );
		return {
			dom: dom,
			lowercase: pre,
			css: '-' + pre + '-',
			js: pre[ 0 ].toUpperCase() + pre.substr( 1 )
		};

	}


	static detect( array ) {

		if ( typeof array === 'string' ) {
			array = [ array ];
		}

		for ( var i = 0; i < array.length; i++ ) {
			if ( this.agent.indexOf( array[ i ] ) > -1 ) {
				return true;
			}
		}

		return false;

	}

}

Software.init();

export default Software;
