import EventEmitter from 'framework/core/eventEmitter';
import Loader from 'framework/tools/loader';
import Visibler from 'framework/tools/visibler';
import MathUtils from 'framework/utils/math';
import {
	$
} from 'framework/tools/selector';

class LazyLoader extends EventEmitter {

	constructor( options ) {

		super( options );

		this.loadHandler = this.loadHandler.bind( this );

	}

	parse() {

		let lazy = $( '[data-lazy]' );
		let lazyScroll = $( '[data-lazy-scroll]' );
		let _this = this;

		lazy.each( function( el ) {
			_this.load( $( el ) );
		} );

		lazyScroll.each( function( el ) {
			_this.loadOnVisible( $( el ) );
		} );

	}

	load( img ) {

		let src = img.attr( 'data-src' );
		let cors = img.attr( 'data-cors' ) || false;
		let _this = this;

		if ( !cors ) {

			Loader.load( src, function( response ) {
				_this.loadHandler( img, response.url );
			} );

		} else {

			let i = new Image();

			i.onload = function() {
				_this.loadHandler( img, src );
			};

			i.src = src;

		}

	}

	getMostRelevantSrc( sizes ) {

		let ww = window.innerWidth;

		return MathUtils.closest( ww, sizes );

	}

	loadHandler( img, url ) {

		let parent = img.parent();

		img.attr( 'src', url );
		img.removeAttr( 'data-lazy' );
		img.removeAttr( 'data-lazy-scroll' );
		parent.addClass( 'loaded' );

	}

	loadOnVisible( img ) {

		let src = img.attr( 'data-src' );
		let cors = img.attr( 'data-cors' ) || false;
		let _this = this;

		if ( !src ) {

			let dataAttributes = img[ 0 ].dataset;
			let sizes = [];

			for ( let attr in dataAttributes ) {

				if ( attr.indexOf( 'src-' ) > -1 ) {
					sizes.push( parseInt( attr.replace( 'src-', '' ) ) );
				}

			}

			let size = this.getMostRelevantSrc( sizes );

			src = img.attr( 'data-src-' + size );

		}

		let visibler = new Visibler( {
			element: img
		} );

		visibler.once( 'visible', function() {

			if ( !cors ) {

				Loader.load( src, function( response ) {
					_this.loadHandler( img, response.url );
				} );

			} else {

				let i = new Image();

				i.onload = function() {
					_this.loadHandler( img, src );
				};

				i.src = src;

			}

		} );

	}

}

export default new LazyLoader();
