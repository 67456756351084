import Component from 'framework/core/component';
import Template from 'templates/components/project/intro.html';

class Intro extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

	}

}

export default Intro;
