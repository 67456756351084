var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"project-intro white-background\">\n\t<div class=\"grid pad-1\">\n\t\t<div class=\"col-1-3\">\n\t\t\t<ul class=\"pink-chip\">\n\t\t\t\t";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"skills", env.opts.autoescape);
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("skill", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t<li>";
output += runtime.suppressValue(runtime.memberLookup((t_4),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</li>\n\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t</ul>\n\t\t</div>\n\t\t<div class=\"col-2-3\">\n\t\t\t<p class=\"txt-med-1\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"brief", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t</div>\n\t</div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
