import Component from 'framework/core/component';
import Template from 'templates/components/common/footer.html';

import Visibler from 'framework/tools/visibler';
import Animator from 'framework/tools/animator';

class Footer extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.visibler = new Visibler( {
			element: this.$.container.find( 'footer' )
		} );

		this.$.contactForm = this.$.container.find( '.contact' );
		this.$.socialLinks = this.$.container.find( '.social li' );
		this.$.others = this.$.container.find( '.others > li' );

		this.prepareAnimations();
		this.observe();

	}

	observe() {

		let _this = this;

		this.visibler.once( 'visible', function() {
			_this.launchAnimations();
		} );

	}

	prepareAnimations() {

		this.showIn = new Animator();

		this.showIn
			.from( this.$.contactForm, {
				opacity: 0,
				x: 40
			} )
			.from( this.$.socialLinks, {
				opacity: 0,
				x: 40
			} )
			.from( this.$.others, {
				opacity: 0,
				x: 40
			} );

	}

	launchAnimations() {

		let duration = 1600;
		let ahead = 110;

		this.showIn
			.to( this.$.contactForm, {
				opacity: 1,
				x: 0,
				duration: duration
			}, -duration )
			.to( this.$.socialLinks, {
				opacity: 1,
				x: 0,
				duration: duration
			}, -duration, ahead )
			.to( this.$.others, {
				opacity: 1,
				x: 0,
				duration: duration
			}, -duration, ahead );

	}

}

export default Footer;
