import Component from 'framework/core/component';
import Template from 'templates/components/project/content.html';

import Videos from 'app/components/project/content/videos';
import CompareSliders from 'app/components/project/content/compareSlider';
import Visibler from 'framework/tools/visibler';

class Content extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.$.compareSliders = this.$.container.find( '.compare-slider' );
		this.$.underlineTexts = this.$.container.find( 'h2.txt-med-3 > i' );

		this.compareSliders = [];
		this.visiblers = [];

		this.createVideos();
		this.createCompareSliders();
		this.createUnderlineVisiblers();

		this.observe();

	}

	createVideos() {

		this.videos = new Videos( {
			container: this.$.container
		} );

	}

	createCompareSliders() {

		let i = this.$.compareSliders.length;

		while ( i-- ) {

			this.compareSliders[ i ] = new CompareSliders( {
				container: this.$.compareSliders[ i ]
			} );

		}
	}

	createUnderlineVisiblers() {

		let i = this.$.underlineTexts.length;

		while ( i-- ) {

			this.visiblers[ i ] = new Visibler( {
				element: this.$.underlineTexts.eq( i ),
				delta: window.innerHeight / 2
			} );

		}

	}

	observe() {

		let i = this.$.underlineTexts.length;
		this.visibleHandler = this.visibleHandler.bind( this );

		while ( i-- ) {

			this.visiblers[ i ].on( 'visible', this.visibleHandler );

		}

	}

	visibleHandler( infos ) {

		infos.element.addClass( 'underlined' );

	}

	destroy() {

		let i = this.$.compareSliders.length;

		while ( i-- ) {
			this.compareSliders[ i ].destroy();
		}

		this.videos.destroy();

		let j = this.$.underlineTexts.length;

		while ( j-- ) {

			this.visiblers[ j ].off( 'visible', this.visibleHandler );
			this.visiblers[ j ].destroy();

		}

		super.destroy();

	}

}

export default Content;
