import AbstractController from 'app/helpers/abstractController';
import Intro from 'app/components/about/intro';
import Expertises from 'app/components/about/expertises';
import Awards from 'app/components/about/awards';
import Clients from 'app/components/about/clients';
import MathUtils from 'framework/utils/math';
import Utils from 'framework/utils';

class AboutController extends AbstractController {

	constructor( options ) {

		super( options );

	}

	start() {

		// create components

		this.setPlaceholds();
		this.fallback();
		this.before();
		this.createComponents();
		this.after();

	}

	setPlaceholds() {

		// recent awards placeholds

		let recentAwardsImages = this.datas.about.awards.recent.entries;

		for ( let entry of recentAwardsImages ) {

			let img = entry.image;
			let ratio = MathUtils.ratio( img.width, img.height );
			entry.placeHold = Utils.placeHold( ratio.x, ratio.y );

		}


	}

	createComponents() {

		//console.log( 'About controller' );

		// create intro part

		this.components.intro = new Intro( {
			container: this.$.overview,
			datas: this.datas
		} );

		// create expertises part

		this.components.expertises = new Expertises( {
			container: this.$.overview,
			datas: this.datas
		} );

		// create awards part

		this.components.awards = new Awards( {
			container: this.$.overview,
			datas: this.datas
		} );

		// create clients part

		this.components.clients = new Clients( {
			container: this.$.overview,
			datas: this.datas
		} );

	}

	destroy() {

		super.destroy();

	}

}

export default AboutController;
