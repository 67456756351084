import EventEmitter from 'framework/core/eventEmitter';
import History from 'framework/tools/router/history';
import Utils from 'framework/utils';
import LazyLoader from 'framework/tools/lazyLoader';
import Anchor from 'framework/tools/anchor';
import {
	$
} from 'framework/tools/selector';

class Router extends EventEmitter {

	constructor( options ) {

		super( options );

		this.routes = options;
		this.current = {
			controller: null,
			route: null,
			params: null
		};

		this.observeHistory();
		this.observeFullscreen();

	}

	observeFullscreen() {

		this.fullscrenchangeHandler = this.fullscrenchangeHandler.bind( this );

		document.addEventListener( 'webkitfullscreenchange', this.fullscrenchangeHandler );
		document.addEventListener( 'mozfullscreenchange', this.fullscrenchangeHandler );
		document.addEventListener( 'fullscreenchange', this.fullscrenchangeHandler );
		document.addEventListener( 'MSFullscreenChange', this.fullscrenchangeHandler );

	}


	fullscrenchangeHandler() {

		document.body.offsetHeight;

		if ( $( document.documentElement ).hasClass( 'fullscreen' ) ) {
			$( document.documentElement ).removeClass( 'fullscreen' );
		} else {
			$( document.documentElement ).addClass( 'fullscreen' );
		}

	}

	observeHistory() {

		let _this = this;

		History.on( 'pop', function( datas ) {
			_this.matchPath( datas.path );
		} );

	}

	start() {

		// match landing page

		this.matchPath( History.path );

	}

	parse() {

		this.clickLinkHandler = this.clickLinkHandler.bind( this );

		let links = $( 'a' );
		let i = links.length;

		while ( i-- ) {

			let link = links[ i ];

			link.removeEventListener( 'click', this.clickLinkHandler );
			link.addEventListener( 'click', this.clickLinkHandler );

		}

		LazyLoader.parse();
		Anchor.parse();
		this.trigger( 'parse' );

	}

	clickLinkHandler( e ) {

		let link = e.currentTarget;
		let href = Utils.addFirstSlash( link.getAttribute( 'href' ) );
		let target = link.getAttribute( 'target' );

		// If it's an internal link

		if ( target !== '_blank' ) {

			// Ignore default link behavior

			e.preventDefault();

			// Match path

			if ( href !== window.location.pathname ) {

				//console.log( this );

				this.matchPath( href );

			}

		}

	}

	matchPath( path ) {

		let _this = this;

		path = Utils.removeTrailingSlash( path );

		for ( let routeName in this.routes ) {

			let route = this.routes[ routeName ];

			route.name = routeName;

			let routePath = Utils.removeTrailingSlash( route.path );
			let routePathFrags = routePath.split( '/' );

			let pathFrags = path.split( '/' );
			let params = {};

			// extract params from route frags

			for ( let index in routePathFrags ) {

				let routePathFrag = routePathFrags[ index ];

				if ( routePathFrags.length === pathFrags.length ) {

					if ( routePathFrag.indexOf( ':' ) === 0 ) {

						let key = routePathFrag.substr( 1 );
						let val = pathFrags[ index ];

						params[ key ] = val;
						routePath = routePath.replace( routePathFrag, val );

					}

				}

			}

			// match path

			if ( path === routePath ) {

				History.push( {}, path );
				this.destroyController( function() {
					_this.createController( route, params );
				} );
				return;
			}

		}

		this.createError();

	}

	destroyController( callback ) {

		let _this = this;

		if ( this.current.controller ) {

			this.current.controller.hide( function() {
				_this.current.controller.destroy();
				callback();
			} );

		} else {
			callback();
		}

	}

	createController( route, params ) {

		let controller = route.controller;

		let instance = new controller( {
			previous: this.current.controller,
			name: route.name,
			params: params
		} );

		this.current.controller = instance;
		this.current.route = route;
		this.current.params = params;
		this.trigger( route.name, params );


	}

	matchName( name, params = {} ) {

		for ( let routeName in this.routes ) {

			let route = this.routes[ routeName ];

			if ( name.toLowerCase() === routeName.toLowerCase() ) {

				this.createController( route, params );
				return;

			}

		}

		this.createError();

	}

	createError() {

		window.location = this.path( 'error', {
			locale: 'fr'
		} );

	}

	// URL Generator (inspired by : http://silex.sensiolabs.org/doc/providers/url_generator.html)

	url( name, params = {} ) {

		return History.origin + this.path( name, params );

	}

	path( name, params = {} ) {

		for ( let routeName in this.routes ) {

			if ( name.toLowerCase() === routeName.toLowerCase() ) {

				let route = this.routes[ routeName ];
				let path = Utils.replaceVars( route.path, params, '/' );

				return path;

			}

		}

	}

}

export default Router;
