import Component from 'framework/core/component';
import Template from 'templates/components/heritage/nav.html';

import Utils from 'framework/utils';
import {
	$
} from 'framework/tools/selector';
import Animator from 'framework/tools/animator';

class Nav extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.isDesktop = Utils.isDesktop();

		this.$.section = this.$.container.find( '.heritage-intro' );

		this.show();

	}

	show() {

		if ( window.isOverlay ) {

			if ( this.isDesktop ) {

				this.showIn = new Animator();

				let _this = this;
				setTimeout( function() {

					$( document.body ).find( '.spinner' ).removeClass( 'visible' );

					_this.showIn
						.to( $( document.body ).find( '.layer-transition-black' ), {
							opacity: 0,
							duration: 2000
						}, 0 );

				}, 1500 );

			}

			window.isOverlay = false;

		}

	}

	hide( callback ) {

		// page transition

		if ( !window.isOverlay ) {

			if ( this.isDesktop ) {

				let transitionLayer = $( Utils.createElement( {
					type: 'div',
					class: 'layer-transition',
					style: {
						'position': 'fixed',
						'top': 0,
						'left': 0,
						'width': '100%',
						'height': '100%',
						'z-index': '9',
						'background-color': '#fff',
						'opacity': 0,
						'pointer-events': 'none'
					}
				} ) );

				this.$.container.append( transitionLayer );

				this.showOut = new Animator();

				this.showOut
					.to( transitionLayer, {
						opacity: 1,
						duration: 800,
						clean: false
					}, 0 );


				this.showOut.once( 'finish', function() {
					callback();
				} );

			} else {

				callback();

			}

		} else {

			callback();

		}

	}

}

export default Nav;
