import Is from 'framework/utils/is';
import Abstract from 'framework/core';

class EventEmitter extends Abstract {

	constructor( options ) {

		super( options );

		this.listeners = new Map();

	}

	on( label, callback ) {

		this.listeners.has( label ) || this.listeners.set( label, [] );
		this.listeners.get( label ).push( callback );

	}

	off( label, callback ) {

		let listeners = this.listeners.get( label );
		let index;

		if ( listeners && listeners.length ) {

			index = listeners.reduce( ( i, listener, index ) => {

				return ( Is.isFunction( listener ) && listener === callback ) ? i = index : i;

			}, -1 );

			if ( index > -1 ) {

				listeners.splice( index, 1 );
				this.listeners.set( label, listeners );
				return true;

			}

		}

		return false;

	}

	trigger( label, ...args ) {

		let listeners = this.listeners.get( label );

		if ( listeners && listeners.length ) {

			listeners.forEach( ( listener ) => {
				listener.apply( listener, [ ...args ] );
			} );

			return true;

		}

		return false;

	}

	once( label, callback ) {

		let _this = this;

		let disposable = function() {
			callback();
			_this.off( label, disposable );
		};

		this.on( label, disposable );

	}

	enable() {}

	disable() {}

	kill() {}

}

export default EventEmitter;
