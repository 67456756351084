import EventEmitter from 'framework/core/eventEmitter';
import Raf from 'framework/tools/raf';

class Scroller extends EventEmitter {

	constructor( options ) {

		super( options );

		this.saved = {
			touchmove: {
				x: 0,
				y: 0
			},
			scroll: {
				x: 0,
				y: 0,
				lastDown: 0,
				lastUp: 0
			}
		};

		this.raf = new Raf( {
			autorun: this.autorun
		} );

		this.scrollY = window.scrollY || window.pageYOffset;

		this.observe();

	}

	observe() {

		this.tickHandler = this.tickHandler.bind( this );
		this.raf.on( 'tick', this.tickHandler );

		this.scrollHandler = this.scrollHandler.bind( this );
		this.mousewheelHandler = this.mousewheelHandler.bind( this );
		window.addEventListener( 'scroll', this.scrollHandler );
		window.addEventListener( 'mousewheel', this.mousewheelHandler );
		window.addEventListener( 'DOMMouseScroll', this.mousewheelHandler );
		this.touchstartHandler = this.touchstartHandler.bind( this );
		window.addEventListener( 'touchstart', this.touchstartHandler );
		this.touchmoveHandler = this.touchmoveHandler.bind( this );
		window.addEventListener( 'touchmove', this.touchmoveHandler );

	}

	tickHandler() {

		let windowHeight = window.innerHeight;

		let infos = {
			top: this.scrollY + 0.1,
			bottom: this.scrollY + windowHeight
		};

		this.trigger( 'scroll', infos );

	}

	scrollHandler() {

		this.scrollY = window.scrollY || window.pageYOffset;

	}

	touchstartHandler( event ) {

		this.saved.touchmove.y = event.touches[ 0 ].clientY;

	}

	touchmoveHandler( event ) {

		let infos = event;
		var currentY = event.touches[ 0 ].clientY;

		if ( currentY > this.saved.touchmove.y ) {
			infos.direction = 'up';
			this.saved.scroll.lastUp = this.scrollY;
		} else if ( currentY < this.saved.touchmove.y ) {
			infos.direction = 'down';
			this.saved.scroll.lastDown = this.scrollY;
		} else {
			infos.direction = 'zero';
		}

		this.trigger( 'touchmove', infos );

	}

	mousewheelHandler( event ) {

		let infos = event;
		let delta = event.detail ? event.detail * ( -120 ) : event.wheelDelta;

		if ( delta > 1 ) {
			infos.direction = 'up';
			this.saved.scroll.lastUp = this.scrollY;
		} else if ( delta < -1 ) {
			infos.direction = 'down';
			this.saved.scroll.lastDown = this.scrollY;
		} else {
			infos.direction = 'zero';
		}

		this.trigger( 'mousewheel', infos );

	}

	destroy() {

		window.removeEventListener( 'scroll', this.scrollHandler );
		window.removeEventListener( 'mousewheel', this.mousewheelHandler );
		window.removeEventListener( 'DOMMouseScroll', this.mousewheelHandler );
		window.removeEventListener( 'touchmove', this.touchmoveHandler );
		window.removeEventListener( 'touchstart', this.touchstartHandler );

	}

}

export default Scroller;
