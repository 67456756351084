import Abstract from 'framework/core';
import Router from 'framework/tools/router';

class Framework extends Abstract {

	constructor( options ) {

		super( options );

		// Routes
		window.Config = options.config;
		window.Router = new Router( this.options.routes );

		// Start

		window.Router.start();

	}

}

export default Framework;
