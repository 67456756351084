import Component from 'framework/core/component';
import Template from 'templates/components/home/carousel.html';
import Utils from 'framework/utils';
import {
	$
} from 'framework/tools/selector';

import Carousell from 'app/components/common/carousel';
import Parallax from 'framework/tools/parallax';

class Carousel extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.isDesktop = Utils.isDesktop();

		this.$.carousel = this.$.container.find( '.home-carousel' );

		this.carousel = new Carousell( {
			container: this.$.carousel,
			autorun: true
		} );

		if ( !this.isDesktop ) {
			setTimeout( function() {
				$( document.body ).find( '.spinner' ).css( {
					opacity: 0
				} );
			}, 100 );
		}

		if( this.isDesktop ){
			this.parallax = new Parallax( {
				elements: this.$.carousel.find( '.parallax' )
			} );
		}

	}

	destroy() {

		this.carousel.destroy();
		if( this.isDesktop ){
			this.parallax.destroy();
		}

	}

}

export default Carousel;
