import EventEmitter from 'framework/core/eventEmitter';
import Scheduler from 'framework/tools/timer/scheduler';
import Utils from 'framework/utils';

class Timer extends EventEmitter {

	constructor( options ) {

		super( options );

		this.options.speed = this.options.speed || 1;
		this.options.repeat = this.options.repeat || 0;
		this.options.timer = this;

		this.delay = 0;
		this.scheduler = new Scheduler( this.options );

	}

	/**
	 * set() call function
	 *
	 * @param {function} callback
	 */

	set( callback ) {

		this.scheduler.add( callback, this.delay );
		return this;

	}

	/**
	 * wait() add delay
	 *
	 * @param {integer} delay
	 */

	wait( delay ) {

		this.delay += ( delay * this.options.speed );
		return this;

	}

	/**
	 * destroy() cancel remove all scheduled tasks
	 */

	destroy() {

		this.scheduler.destroy();

		Utils.nullObject( this );

	}

}

export default Timer;
