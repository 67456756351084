import AbstractController from 'app/helpers/abstractController';

import Content from 'app/components/error/content';

class ErrorController extends AbstractController {

	constructor( options ) {

		super( options );

	}

	start() {

		// create components

		this.fallback();
		this.createComponents();

	}

	createComponents() {

		//console.log( 'Error controller' );

		// create content part

		this.components.content = new Content( {
			container: this.$.overview,
			datas: this.datas
		} );

		super.parse();

	}

	destroy() {

		super.destroy();

	}

}

export default ErrorController;
