import EventEmitter from 'framework/core/eventEmitter';
import {
	$
} from 'framework/tools/selector';

class roundHover extends EventEmitter {

	constructor( options ) {

		super( options );

		this.btns = [];

		this.$ = {};
		this.$.container = this.options.container;

		this.observe();

	}

	parse() {

		this.reset();

		let btns = $( '[data-roundhover]' );
		let _this = this;

		this.mouseEnterHandler = this.mouseEnterHandler.bind( this );
		this.mouseLeaveHandler = this.mouseLeaveHandler.bind( this );

		btns.each( function( el ) {

			let btn = $( el );

			btn.on( 'mouseenter', _this.mouseEnterHandler );
			btn.on( 'mouseleave', _this.mouseLeaveHandler );

			_this.btns.push( btn );

		} );

	}

	observe() {

		this.routerParseHandler = this.routerParseHandler.bind( this );
		window.Router.on( 'parse', this.routerParseHandler );

	}

	routerParseHandler() {
		this.parse();
	}

	mouseEnterHandler( e ) {

		let btn = $( e.target );
		btn.addClass( 'hover' );
		this.changeFirstChild( btn );

	}

	mouseLeaveHandler( e ) {

		let btn = $( e.target );
		btn.removeClass( 'hover' );
		this.changeFirstChild( btn );

	}

	changeFirstChild( btn ) {

		let firstIcon = btn.find( '.round-hover-ico:first-child' );
		firstIcon.remove();
		btn.append( firstIcon );

	}

	reset() {

		let i = this.btns.length;

		while ( i-- ) {

			let btn = this.btns[ i ];

			btn.off( 'mouseenter', this.mouseEnterHandler );
			btn.off( 'mouseleave', this.mouseLeaveHandler );

			delete this.btns[ i ];

		}

		this.btns = [];

	}

	hide() {

	}

	destroy() {

		this.reset();
		window.Router.off( 'parse', this.routerParseHandler );

	}

}

export default roundHover;
