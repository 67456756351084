import EventEmitter from 'framework/core/eventEmitter';

import Resizer from 'framework/tools/resizer';
import Scroller from 'framework/tools/scroller';
import Easer from 'framework/tools/easer';
import Visibler from 'framework/tools/visibler';
import {
	$
} from 'framework/tools/selector';

class Parallax extends EventEmitter {

	constructor( options ) {

		super( options );

		this.$ = {};
		this.$.elements = options.elements;

		this.sizes = {};
		this.pos = 0;

		this.easer = new Easer( {
			ease: 0.2
		} );

		this.resizer = new Resizer();

		this.scroller = new Scroller();

		this.items = [];

		this.setSizes();
		this.observe();

	}

	observe() {

		this.resizeHandler = this.resizeHandler.bind( this );
		this.resizer.on( 'resize', this.resizeHandler );

		this.scrollHandler = this.scrollHandler.bind( this );
		this.scroller.on( 'scroll', this.scrollHandler );

		this.frameHandler = this.frameHandler.bind( this );
		this.easer.on( 'frame', this.frameHandler );

	}

	scrollHandler( infos ) {

		this.easer.set( {
			y: infos.top
		} );

	}

	frameHandler( infos ) {

		let i = this.items.length;

		while ( i-- ) {

			let item = this.items[ i ];
			let distance = item.sizes.height - item.parentSizes.height;
			let speed = distance / this.hw;

			if ( speed === 0 ) {
				speed = 0.5;
			}

			this.pos = ( ( infos.pos.y - item.sizes.top ) * speed );

			if ( item.visibler.isVisible ) {
				item.el.css( {
					transform: 'translate3d(0, ' + this.pos + 'px, 0)'
				} );
			}

		}

	}

	setSizes() {

		this.hw = window.innerHeight;

		let i = this.$.elements.length;

		while ( i-- ) {

			let el = $( this.$.elements[ i ] );
			let parent = el.parent();

			let sizes = el.sizes();
			let parentSizes = parent.sizes();

			let visibler = new Visibler( {
				element: parent
			} );

			this.items[ i ] = {
				el: el,
				parent: parent,
				parentSizes: {
					top: parentSizes.top + ( window.scrollY || window.pageYOffset ),
					bottom: parentSizes.bottom + ( window.scrollY || window.pageYOffset ),
					width: parentSizes.width,
					height: parentSizes.height
				},
				sizes: {
					top: sizes.top + ( window.scrollY || window.pageYOffset ),
					bottom: sizes.bottom + ( window.scrollY || window.pageYOffset ),
					width: sizes.width,
					height: sizes.height
				},
				visibler: visibler
			};

		}

	}

	resizeHandler() {

	}

	destroy() {

		this.resizer.off( 'resize', this.resizeHandler );
		this.scroller.off( 'scroll', this.scrollHandler );

		this.resizer.destroy();
		this.scroller.destroy();

	}

}

export default Parallax;
