import AbstractController from 'app/helpers/abstractController';
import HeritagesModel from 'app/models/heritagesModel';

import Intro from 'app/components/heritage/intro';
import Products from 'app/components/heritage/products';
import Nav from 'app/components/heritage/nav';
import Utils from 'framework/utils';
import MathUtils from 'framework/utils/math';
import {
	$
} from 'framework/tools/selector';


class HeritageController extends AbstractController {

	constructor( options ) {

		super( options );

	}

	start() {

		let _this = this;

		// get datas

		HeritagesModel.getCollectionBySlug( this.params.slug, function( collection ) {
			_this.datas.currentCollection = collection;
			_this.getProducts( collection.attrs.collection_id );
		} );

	}

	validate() {

		// heritage validation

		HeritagesModel.getCollectionBySlug( this.params.slug, function( collection ) {

			if ( !Object.keys( collection ).length ) {
				window.Router.createError();
			}

		} );

		// locale validation

		super.validate();

	}

	getProducts( collectionId ) {

		this.getProductsCallback = this.getProductsCallback.bind( this );
		HeritagesModel.getProductsByCollectionsId( collectionId, this.getProductsCallback );

	}

	createComponents() {

		// console.log( 'Heritage controller, slug : ' + this.params.slug );

		this.components.intro = new Intro( {
			container: this.$.overview,
			datas: this.datas
		} );

		this.components.products = new Products( {
			container: this.$.overview,
			datas: this.datas
		} );

		this.components.nav = new Nav( {
			container: this.$.overview,
			datas: this.datas
		} );

	}

	getProductsCallback( products ) {

		let len = products.length;
		let count = 0;
		let _this = this;

		for ( let product of products ) {
			let html = Utils.parseHtml( product.attrs.body_html );
			let lang = this.datas.locale;
			product.attrs.content = {};
			if ( $( html ).find( 'h3' ).length ) {
				product.attrs.content.title = $( html ).find( 'h3[data-lang="' + lang + '"]' ).text();
			}
			if ( $( html ).find( 'h4' ).length ) {
				product.attrs.content.subtitle = $( html ).find( 'h4[data-lang="' + lang + '"]' ).text();
			}
			if ( $( html ).find( 'p' ).length ) {
				product.attrs.content.text = $( html ).find( 'p[data-lang="' + lang + '"]' ).text();
			}
			if ( $( html ).find( 'span' ).length ) {
				product.attrs.content.limit = $( html ).find( 'span[data-lang="' + lang + '"]' ).text();
			}
			if ( $( html ).find( 'i' ).length ) {
				product.attrs.content.ref = $( html ).find( 'i[data-lang="' + lang + '"]' ).text();
			}

			var img = new Image();

			img.addEventListener( 'load', function() {

				let ratio = MathUtils.ratio( this.naturalWidth, this.naturalHeight );
				product.images[ 0 ].placehold = Utils.placeHold( ratio.x, ratio.y );

				count++;

				if ( count === len ) {
					_this.datas.currentHeritage = {};
					_this.datas.currentHeritage.products = products;

					// create components

					_this.fallback();
					_this.before();
					_this.createComponents();
					_this.after();
				}

			} );

			img.src = product.images[ 0 ].src;

		}


	}

	destroy() {

		super.destroy();

	}

}

export default HeritageController;
