import AbstractController from 'app/helpers/abstractController';

import Content from 'app/components/legals/content';

class LegalsController extends AbstractController {

	constructor( options ) {

		super( options );

	}

	start() {

		// create components

		this.fallback();
		this.before();
		this.createComponents();
		this.after();

	}

	createComponents() {

		//console.log( 'Legals controller' );

		// create content part

		this.components.content = new Content( {
			container: this.$.overview,
			datas: this.datas
		} );

	}

	destroy() {

		super.destroy();

	}

}

export default LegalsController;
