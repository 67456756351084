import EventEmitter from 'framework/core/eventEmitter';
import Resizer from 'framework/tools/resizer';
import Scroller from 'framework/tools/scroller';
import Easer from 'framework/tools/easer';
import {
	$
} from 'framework/tools/selector';

class CustomScroll extends EventEmitter {

	constructor( options ) {

		super( options );

		this.$ = {};

		this.resizer = new Resizer();
		this.scroller = new Scroller();
		this.easer = new Easer( {
			ease: 0.2,
			easeLimit: 0.1
		} );

		this.savedPos = {
			pos: {
				y: 0
			}
		};

		this.isDisable = false;
		this.memo = {};

	}

	parse( infos ) {

		this.unobserve();

		if ( this.isDisable ) {
			this.enable();
		}

		this.easer.force( {
			y: 0.1
		} );

		this.easerHandler( {
			pos: {
				y: 0.1
			}
		} );

		window.scrollTo( 0, 0 );

		this.$.body = $( document.body );
		this.$.overviews = [];
		this.$.exceptions = [];

		// extract overviews

		for ( let overview of infos.overviews ) {

			for ( let el of overview ) {
				this.$.overviews.push( $( el ) );
			}
		}


		// extract exceptions

		for ( let exception of infos.exceptions ) {

			for ( let el of exception ) {
				this.$.exceptions.push( $( el ) );
			}
		}

		this.setSize();

		this.observe();

	}

	unobserve() {

		this.resizer.off( 'resize', this.resizeHandler );
		this.scroller.off( 'scroll', this.scrollHandler );
		this.easer.off( 'frame', this.easerHandler );

	}

	observe() {

		this.resizeHandler = this.resizeHandler.bind( this );
		this.resizer.on( 'resize', this.resizeHandler );

		this.scrollHandler = this.scrollHandler.bind( this );
		this.scroller.on( 'scroll', this.scrollHandler );

		this.easerHandler = this.easerHandler.bind( this );
		this.easer.on( 'frame', this.easerHandler );

	}

	disable() {

		this.memo.pos = this.savedPos.pos;
		this.memo.realpos = this.scroller.scrollY;
		this.isDisable = true;

	}

	enable() {

		let _this = this;

		window.scrollTo( 0, this.memo.realpos );
		this.savedPos.pos = this.memo.pos;
		this.easer.force( {
			y: this.memo.realpos
		} );
		this.isDisable = false;

	}

	setSize() {

		// window.scrollTo( 0, 0 );

		// this.easer.force( {
		// 	y: 0.1
		// } );

		// this.easerHandler( {
		// 	pos: {
		// 		y: 0.1
		// 	}
		// } );

		let _this = this;

		_this.sizes = {};
		_this.$.body[ 0 ].setAttribute( 'style', '' );
		_this.$.overviews[ 0 ][ 0 ].setAttribute( 'style', '' );

		_this.sizes.height = _this.$.body.sizes().height;

		_this.$.body.css( {
			height: _this.sizes.height + 'px'
		} );

		_this.$.overviews[ 0 ].css( {
			position: 'fixed',
			width: '100%'
		} );

		this.easerHandler();

	}


	easerHandler( infos = this.savedPos ) {

		if ( !this.isDisable ) {

			if ( this.$.overviews ) {

				let i = this.$.overviews.length;

				while ( i-- ) {
					let overview = this.$.overviews[ i ];
					overview[ 0 ].style.transform = 'translate3d(0, -' + infos.pos.y + 'px, 0)';
				}

			}


			if ( this.$.exceptions ) {

				let i = this.$.exceptions.length;

				while ( i-- ) {
					let exception = this.$.exceptions[ i ];
					exception[ 0 ].style.transform = 'translate3d(0, ' + infos.pos.y + 'px, 0)';
				}

			}

			this.savedPos = {
				pos: {
					y: infos.pos.y
				}
			};

		}

		// this.$.overview.css( {
		// 	willChange: 'transform',
		// 	transform: 'translate3d(0, -' + infos.pos.y + 'px, 0)'
		// } );

	}

	scrollHandler( infos ) {

		this.easer.set( {
			y: infos.top
		} );

	}

	resizeHandler() {

		this.setSize();

	}

	reset() {


	}

	destroy() {

	}

}

export default new CustomScroll();
