import Is from 'framework/utils/is';
import Storer from 'framework/tools/storer';
import Hardware from 'framework/tools/stage/hardware';
import Software from 'framework/tools/stage/software';
//import Timer from 'framework/tools/timer';

class Utils {

	static isInferiorIE( version ) {

		return Software.isIE && Software.version < version;

	}

	static getPrefix() {

		return Software.prefix;

	}

	static isIOS() {

		return Hardware.isIOS;

	}

	static isMac() {

		return Hardware.isMac;

	}

	static getNavigator() {

		return Software.getNavigatorName();

	}

	static isMobile() {

		return Hardware.isMobile;

	}

	static isTablet() {

		return Hardware.isTablet;

	}

	static isDesktop() {

		return Hardware.isDesktop;

	}

	static uniqueId() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, function( c ) {
			let r = Math.random() * 16 | 0,
				v = c === 'x' ? r : ( r & 0x3 | 0x8 );
			return v.toString( 16 );
		} );
	}

	static nullObject( object ) {
		if ( object.destroy || object.div ) {
			for ( let key in object ) {
				if ( typeof object[ key ] !== 'undefined' ) {
					object[ key ] = null;
				}
			}
		}
		return null;
	}

	static slugify( str ) {

		if ( typeof str !== 'string' ) {
			return str;
		}

		return str
			.toLowerCase()
			.replace( /[^\w ]+/g, '' )
			.replace( / +/g, '-' );

	}

	static removeTrailingSlash( path ) {

		if ( path.length !== 1 ) {
			return path.replace( /\/$/, '' );
		} else {
			return path;
		}

	}

	static addFirstSlash( path ) {

		if ( path.substring( 0, 1 ) !== '/' ) {
			return '/' + path;
		} else {
			return path;
		}

	}

	static replaceVars( string, vars, splitter = ' ' ) {

		let result = string;
		let frags = string.split( splitter );

		let i = frags.length;

		while ( i-- ) {

			let frag = frags[ i ];

			if ( frag.indexOf( ':' ) === 0 ) {

				let key = frag.substr( 1 );

				if ( vars[ key ] ) {
					result = result.replace( frag, vars[ key ] );
				}

			}

		}

		return result;

	}

	static shorten( str, len, sep ) {
		sep = ( sep || '...' );
		let half = len / 2;
		let firsthalf = half - sep.length;
		let lasthalf = half * -1;

		return ( str.length > len ) ? str.substr( 0, firsthalf ) + '...' + str.substr( lasthalf, str.length ) : str;
	}

	static deleteFromArray( value, array ) {

		let index = array.indexOf( value );

		if ( index !== -1 ) {
			array.splice( index, 1 );
		}

	}

	static waitRedraw( callback ) {

		document.body.offsetHeight;
		callback();

		// let timer = new Timer();
		// timer
		// 	.wait( 300 )
		// 	.set( callback );

	}

	static escapeHTML( html ) {

		let entityMap = {
			'&': '&amp;',
			'<': '&lt;',
			'>': '&gt;',
			'"': '&quot;',
			'\'': '&#39;',
			'/': '&#x2F;'
		};

		return html.replace( /[&<>"'\/]/g, function( s ) {
			return entityMap[ s ];
		} );

	}

	static mergeDeep( target = {}, src = {} ) {

		let array = Array.isArray( src );
		let dst = array && [] || {};
		let _this = this;

		if ( array ) {

			target = target || [];
			dst = dst.concat( target );

			src.forEach( function( e, i ) {
				if ( typeof dst[ i ] === 'undefined' ) {
					dst[ i ] = e;
				} else if ( typeof e === 'object' ) {
					dst[ i ] = _this.mergeDeep( target[ i ], e );
				} else {
					if ( target.indexOf( e ) === -1 ) {
						dst.push( e );
					}
				}
			} );

		} else {

			if ( target && typeof target === 'object' ) {
				Object.keys( target ).forEach( function( key ) {
					dst[ key ] = target[ key ];
				} );
			}

			Object.keys( src ).forEach( function( key ) {
				if ( typeof src[ key ] !== 'object' || !src[ key ] ) {
					dst[ key ] = src[ key ];
				} else {
					if ( !target[ key ] ) {
						dst[ key ] = src[ key ];
					} else {
						dst[ key ] = _this.mergeDeep( target[ key ], src[ key ] );
					}
				}
			} );

		}

		return dst;

	}

	static placeHold( width, height ) {

		if ( !Storer.get( 'placehold_' + width + '/' + height ) ) {

			let canvas = document.createElement( 'canvas' );
			canvas.width = width;
			canvas.height = height;

			let img = canvas.toDataURL();

			Storer.set( 'placehold_' + width + '/' + height, img );

			return img;

		} else {

			return Storer.get( 'placehold_' + width + '/' + height );

		}

	}

	static getposition( el ) {

		var xPos = 0;
		var yPos = 0;

		while ( el ) {
			if ( el.tagName == 'BODY' ) {

				// deal with browser quirks with body/window/document and page scroll
				var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
				var yScroll = el.scrollTop || document.documentElement.scrollTop;

				xPos += ( el.offsetLeft - xScroll + el.clientLeft );
				yPos += ( el.offsetTop - yScroll + el.clientTop );

			} else {

				// for all other non-BODY elements
				xPos += ( el.offsetLeft - el.scrollLeft + el.clientLeft );
				yPos += ( el.offsetTop - el.scrollTop + el.clientTop );

			}

			el = el.offsetParent;
		}

		return {
			x: xPos,
			y: yPos
		};

	}

	static cap( string ) {

		return string.charAt( 0 ).toUpperCase() + string.slice( 1 );

	}

	static sortByKey( array, key ) {

		return array.sort( function( a, b ) {

			var x = a[ key ];
			var y = b[ key ];

			return ( ( x < y ) ? -1 : ( ( x > y ) ? 1 : 0 ) );

		} );

	}

	static parseHtml( markup ) {
		if ( markup.toLowerCase().trim().indexOf( '<!doctype' ) === 0 ) {
			var doc = document.implementation.createHTMLDocument( "" );
			doc.documentElement.innerHTML = markup;
			return doc;
		} else if ( 'content' in document.createElement( 'template' ) ) {
			// Template tag exists!
			var el = document.createElement( 'template' );
			el.innerHTML = markup;
			return el.content;
		} else {
			// Template tag doesn't exist!
			var docfrag = document.createDocumentFragment();
			var el = document.createElement( 'body' );
			el.innerHTML = markup;
			for ( var i = 0; i < el.childNodes.length; i++ ) {
				docfrag.appendChild( el.childNodes[ i ] );
			}
			return docfrag;
		}
	}

	static createElement( settings ){

		if( settings.type ){

			let el = document.createElement( settings.type );

			if( settings.type ){

				el.classList.add( settings.class );

			}

			if( settings.style ){

				let style = '';

				for( var property in settings.style ){

					if( settings.style.hasOwnProperty( property ) ){

						style += property + ':' + settings.style[ property ] + ';';

					}

				}

				el.setAttribute( 'style', style );

			}

			return el;

		}else{

			return document.createElement( 'div' );

		}

	}

}

export default Utils;
