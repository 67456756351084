import AbstractController from 'app/helpers/abstractController';
import PostsModel from 'app/models/postsModel';
import Content from 'app/components/expertise/content';
import Talents from 'app/components/expertise/talents';

class ExpertiseController extends AbstractController {

	constructor( options ) {

		super( options );

	}

	start() {

		// get datas

		this.datas.expertise = PostsModel.getOneBySlug( 'expertise', this.params.slug );

		this.getSlides();

		if ( this.datas.expertise.showTalents ) {
			this.getTalentsCategories();
		}

		// create components

		this.fallback();
		this.before();
		this.createComponents();
		this.after();

	}

	getTalentsCategories() {

		this.datas.talentsCategories = [];
		let history = [];

		for ( let talent of this.datas.posts.talents ) {

			for ( let cat of talent.categories ) {

				if ( !history[ cat.slug ] ) {
					history[ cat.slug ] = true;
					this.datas.talentsCategories.push( cat );
				}

			}

		}

	}

	getSlides() {

		let contents = this.datas.expertise.content;

		for ( let content of contents ) {

			let projects = content.projects;
			let finalSlides = [];

			for ( let slug of projects ) {
				let datas = PostsModel.getOneBySlug( 'project', slug );
				finalSlides.push( datas );
			}

			content.projects = finalSlides;

		}

	}

	validate() {

		// expertise validation

		if ( !Object.keys( PostsModel.getOneBySlug( 'expertise', this.params.slug ) ).length ) {
			window.Router.createError();
		}

		// locale validation

		super.validate();

	}

	createComponents() {

		//console.log( 'Expertise controller, slug : ' + this.params.slug );

		// create content part

		this.components.content = new Content( {
			container: this.$.overview,
			datas: this.datas
		} );

		// create talents part

		if ( this.datas.expertise.showTalents ) {

			this.components.talents = new Talents( {
				container: this.$.overview,
				datas: this.datas
			} );

		}

	}

	destroy() {

		super.destroy();

	}

}

export default ExpertiseController;
