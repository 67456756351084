import EventEmitter from 'framework/core/eventEmitter';
import Utils from 'framework/utils';
import Ajax from 'framework/tools/loader/ajax';

class SportagraphManager extends EventEmitter {

	constructor( options ) {

		super( options );

		this.url = 'https://api.sportagraph.com/search';

	}

	req( url, datas, callback ) {

		Ajax.get( {
			url: url + '?unique=' + Utils.uniqueId(),
			datas: datas,
			method: 'POST'
		}, function( response ) {

			var data = JSON.parse( response.file );
			callback( data );

		} );

	}

	getImageUrl( infos ) {

		let url = 'https://download.sportagraph.com/';

		url += infos.id + '_';
		url += infos.width + '-';
		url += infos.height + '-';
		url += infos.colorModel + '-';
		url += infos.scalingStrategy + '-';
		url += infos.quality;
		url += '?streamid=' + infos.streamId;

		return url;

	}

	searchByStream( id, callback ) {

		let datas = {
			id: Utils.uniqueId(),
			jsonrpc: '2.0',
			method: 'searchByStream',
			params: {
				// request: {
				// 	scope: [ 'All' ],
				// 	query: '*',
				// 	sort: [],
				// 	index: 0,
				// 	length: 0
				// },
				streamId: id,
				bypassCache: true,
				identified: false
			}
		};

		this.req( this.url, datas, function( response ) {

			callback( response.result.docs );

		} );


	}

}

export default new SportagraphManager();
