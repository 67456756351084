import EventEmitter from 'framework/core/eventEmitter';
import {
	$
} from 'framework/tools/selector';

class Filters extends EventEmitter {

	constructor( options ) {

		super( options );

		this.$ = {};

		this.$.container = options.container;
		this.$.filters = this.$.container.find( '.filters' );
		this.$.filter = this.$.filters.find( 'li' );
		this.$.items = this.$.container.find( '.thumbs' );
		this.$.item = this.$.items.find( 'li' );

		this.createFilters();

	}

	createFilters() {

		this.clickHandler = this.clickHandler.bind( this );
		this.$.filter.on( 'click', this.clickHandler );

	}

	clickHandler( e ) {

		let btn = $( e.target );

		let cat = btn.attr( 'data-filter' );

		let items = cat === '*' ? this.$.item : this.$.items.find( '.' + cat );

		this.activeFilter( btn );
		this.showItems( items );

	}

	activeFilter( btn ) {

		// reset all actived btns

		this.$.filter.removeClass( 'is-active' );

		// add active button

		btn.addClass( 'is-active' );

	}

	showItems( items ) {

		// hide all posts

		this.$.item.addClass( 'hidden' );

		// show targets posts

		items.removeClass( 'hidden' );

		this.trigger( 'filter' );

	}

	destroy() {

		// remove filter events

		this.$.filter.off( 'click' );

	}

}

export default Filters;
