import Component from 'framework/core/component';
import Template from 'templates/components/project/nav.html';

import Utils from 'framework/utils';
import {
	$
} from 'framework/tools/selector';
import Animator from 'framework/tools/animator';

class Nav extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.isDesktop = Utils.isDesktop();
		this.isMobile = Utils.isMobile();

		this.$.sectionFeatured = this.$.container.find( '.project-featured' );

		this.$.navBtns = this.$.container.find( '.project-nav a' );

		this.isClick = false;

		this.configAnimation = {
			show: {
				img: {
					duration: 2000,
					finalShift: -200,
					fromOpacity: 0,
					toOpacity: 0.6,
					toScale: 1,
					ease: 'quartInOut'
				},
				scrollTo: {
					duration: 800,
					finalShift: 0,
					fromScale: 0,
					fromRotate: -90,
					toScale: 1,
					toRotate: -90,
					ease: ''
				},
				infos: {
					startShift: 800
				}
			},
			hide: {
				nav: {
					duration: 800,
					finalShift: 0,
					shift: 150,
					toOpacity: 0,
					toX: -100,
					ease: 'quartInOut'
				}
			}
		};

		this.observe();

		this.show();

	}

	observe() {

		let _this = this;

		if ( this.isDesktop ) {

			this.navEnterHandler = this.navEnterHandler.bind( this );
			this.navLeaveHandler = this.navLeaveHandler.bind( this );

			let i = this.$.navBtns.length;
			while ( i-- ) {
				this.$.navBtns.eq( i ).on( 'mouseenter', this.navEnterHandler );
				this.$.navBtns.eq( i ).on( 'mouseleave', this.navLeaveHandler );

				this.$.navBtns.eq( i ).once( 'click', function( el ) {

					_this.isClick = true;

					// active btn
					let btn = $( el );
					btn.addClass( 'active' );

					// disable click on other btns
					let j = _this.$.navBtns.length;
					while ( j-- ) {
						_this.$.navBtns.eq( j ).parent().css( {
							pointerEvents: 'none'
						} );
					}

				} );
			}

		}

	}

	prepareScrollToAnimations() {

		this.showIn = new Animator();

		this.showIn
			.from( this.$.sectionFeatured.find( '.scroll-to' ), {
				scale: this.configAnimation.show.scrollTo.fromScale,
				rotate: this.configAnimation.show.scrollTo.fromRotate
			} );

	}

	prepareImageAnimations() {

		this.showIn
			.from( this.$.sectionFeatured.find( '.img' ), {
				opacity: this.configAnimation.show.img.fromOpacity
			} );

	}

	show() {

		if ( !this.isMobile ) {

			this.prepareScrollToAnimations();

			if ( ( window.isOverlay && window.isOverlayProject ) || window.isOverlayNav ) {

				window.isOverlayProject = false;

				window.isOverlay = false;

				window.isOverlayNav = false;

			} else {

				this.prepareImageAnimations();

			}

			let _this = this;
			setTimeout( function() {

				_this.showIn
					.to( _this.$.sectionFeatured.find( '.img' ), {
						scale: _this.configAnimation.show.img.toScale,
						opacity: _this.configAnimation.show.img.toOpacity,
						duration: _this.configAnimation.show.img.duration,
						ease: _this.configAnimation.show.img.ease,
						clean: false
					}, _this.configAnimation.show.img.finalShift )
					.to( _this.$.sectionFeatured.find( '.scroll-to' ), {
						scale: _this.configAnimation.show.scrollTo.toScale,
						rotate: _this.configAnimation.show.scrollTo.toRotate,
						duration: _this.configAnimation.show.scrollTo.duration,
						ease: _this.configAnimation.show.scrollTo.ease
					}, _this.configAnimation.show.scrollTo.finalShift );

				let that = _this;
				setTimeout( function() {
					that.$.sectionFeatured.find( '.infos > *' ).addClass( 'show' );
				}, _this.configAnimation.show.infos.startShift );

			}, 400 );

		}

	}

	navEnterHandler( e ) {

		window.isOverlayNav = true;

		if ( window.customScroll ) {
			window.customScroll.disable();
		}

		let btn = $( e.currentTarget );
		let sizes = btn.sizes();
		let clone = btn.clone();

		clone.addClass( 'out-nav' );

		let p = clone.find( 'p' );
		let span = clone.find( 'span' );

		p.addClass( 'toanim' );
		span.addClass( 'toanim' );

		clone.css( {
			zIndex: 1000,
			left: sizes.left + 'px',
			top: sizes.top + 'px',
			width: sizes.width + 'px',
			height: sizes.height + 'px'
		} );

		$( document.body ).append( clone );

		window.setTimeout( function() {
			clone.addClass( 'shown' );
		}, 200 );

	}

	navLeaveHandler() {

		if ( window.customScroll ) {
			window.customScroll.enable();
		}

		let existing = $( document.body ).find( '.out-nav' );

		if ( existing && !this.isClick ) {
			existing.css( {
				zIndex: 500
			} );
			existing.removeClass( 'shown' );
			window.setTimeout( function() {
				existing.remove();
			}, 800 );
		}

		if ( !this.isClick ) {
			window.isOverlayNav = false;
		}

	}

	hide( callback ) {

		// page transition

		if ( !window.isOverlay ) {

			if ( window.isOverlayNav ) {

				this.showOut = new Animator();

				this.showOut
					.to( $( document.body ).find( '.out-nav.shown .toanim' ), {
						opacity: this.configAnimation.hide.nav.toOpacity,
						x: this.configAnimation.hide.nav.toX,
						duration: this.configAnimation.hide.nav.duration,
						ease: this.configAnimation.hide.nav.ease,
						clean: false
					}, this.configAnimation.hide.nav.finalShift, this.configAnimation.hide.nav.shift );

				this.showOut.once( 'finish', function() {
					callback();
					$( document.body ).find( '.out-nav' ).remove();
				} );

			} else {

				if ( this.isDesktop ) {

					let transitionLayer = $( Utils.createElement( {
						type: 'div',
						class: 'layer-transition',
						style: {
							'position': 'fixed',
							'top': 0,
							'left': 0,
							'width': '100%',
							'height': '100%',
							'z-index': '9',
							'background-color': '#fff',
							'opacity': 0,
							'pointer-events': 'none'
						}
					} ) );

					this.$.container.append( transitionLayer );

					this.showOut = new Animator();

					this.showOut
						.to( transitionLayer, {
							opacity: 1,
							duration: 800,
							clean: false
						}, 0 );

					this.showOut.once( 'finish', function() {
						callback();
					} );

				} else {

					callback();

				}

			}

		} else {

			callback();

		}

	}

}

export default Nav;
