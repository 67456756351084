import Controller from 'framework/core/controller';
import Utils from 'framework/utils/index';
import CustomScroll from 'framework/tools/customScroll';

import DatasModel from 'app/models/datasModel';
import PostsModel from 'app/models/postsModel';
import HeritagesModel from 'app/models/heritagesModel';

import Header from 'app/components/common/header';
import Newsletter from 'app/components/common/newsletter';
import Footer from 'app/components/common/footer';
import Turnscreen from 'app/components/common/turnscreen';
import Oldbrowser from 'app/components/common/oldbrowser';
import RoundHover from 'app/components/common/roundHover';
import Cookies from 'app/components/common/cookies';

import {
	$
} from 'framework/tools/selector';

class AbstractController extends Controller {

	constructor( options ) {

		super( options );

		this.isDesktop = Utils.isDesktop();

		// get datas`

		this.datas = DatasModel.getAll();

		this.datas.posts = {};
		this.datas.posts.projects = PostsModel.getAllSortedByCategorySlug( 'project' );
		this.datas.posts.expertises = Utils.sortByKey( PostsModel.getAll( 'expertise' ), 'slug' );
		this.datas.posts.talents = Utils.sortByKey( PostsModel.getAll( 'talent' ), 'name' );

		this.datas.locale = this.params.locale;
		this.datas.page = this.name;

		this.datas.helpers = {};
		this.datas.helpers.placeHold_160_63 = Utils.placeHold( 160, 63 );
		this.datas.helpers.placeHold_16_9 = Utils.placeHold( 16, 9 );
		this.datas.helpers.placeHold_3_4 = Utils.placeHold( 3, 4 );

		window.customScroll = CustomScroll;

		this.getHeritageCollection();
		this.enableScoll();
		this.createSpinner();


	}

	getHeritageCollection() {

		let _this = this;

		HeritagesModel.getAllCollections( function( collections ) {

			// tri par ordre alphabétique

			let obj = [];

			for ( let collection in collections ) {
				obj.push( {
					title: collections[ collection ].attrs.title,
					collection: collections[ collection ]
				} );
			}

			let ordonate = Utils.sortByKey( obj, 'title' );

			let final = [];

			for ( let item in ordonate ) {
				final.push(
					ordonate[ item ].collection
				);
			}

			_this.datas.posts.heritages = final;
			_this.validate();

		} );

	}

	enableScoll() {

		$( document.body ).removeClass( 'no-scroll' );

	}

	createSpinner() {

		if ( $( document.body ).find( '.spinner' ).length === 0 ) {

			let spinner = $( Utils.createElement( {
				type: 'div',
				class: 'spinner',
				style: {
					'position': 'fixed',
					'top': '50%',
					'left': '50%',
					'transform': 'translate3d(-50%,-50%,0)',
					'width': '50px',
					'height': '50px',
					'z-index': '2000',
					'opacity': 0,
					'pointer-events': 'none'
				}
			} ) );

			if ( this.isDesktop ) {

				$( document.body ).append( spinner );

			} else {

				spinner.css( {
					opacity: 1
				} );

				this.$.overview.append( spinner );

			}

		}

	}

	validate() {

		// page validation

		if ( this.params.locale !== 'en' && this.params.locale !== 'fr' ) {
			window.Router.createError();
		} else {
			this.start();
		}

	}

	start() {}

	parse() {

		super.parse();

		if ( window.sportagraph && window.sportagraph.embeds ) {
			window.sportagraph.embeds.parse();
		}

		if ( this.isDesktop ) {

			window.customScroll.parse( {
				overviews: [ document.querySelectorAll( '#overview' ), document.querySelectorAll( 'header' ) ],
				exceptions: []
			} );

		}

	}

	fallback() {

		window.scrollTo( 0, 1 );
		window.scrollTo( 0, 0 );

		// turnscreen

		this.components.oldbrowser = new Oldbrowser( {
			container: this.$.fixed,
			datas: this.datas
		} );

		// turnscreen

		this.components.turnscreen = new Turnscreen( {
			container: this.$.fixed,
			datas: this.datas
		} );

		// add btns round hovers

		this.components.roundH = new RoundHover( {
			container: this.$.overview,
			datas: this.datas
		} );

		// cookies

		this.components.cookies = new Cookies( {
			container: this.$.fixed,
			datas: this.datas
		} );

	}

	before() {

		// create header

		this.components.header = new Header( {
			container: this.$.fixed,
			datas: this.datas
		} );

	}

	createComponents() {}

	after() {

		// create newsletter part

		this.components.newsletter = new Newsletter( {
			container: this.$.overview,
			datas: this.datas
		} );

		// create footer part

		this.components.footer = new Footer( {
			container: this.$.overview,
			datas: this.datas
		} );

		// Post rendering

		this.parse();

	}

	destroy() {

		super.destroy();

	}

}

export default AbstractController;
