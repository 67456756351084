import EventEmitter from 'framework/core/eventEmitter';
import {
	$
} from 'framework/tools/selector';
import Utils from 'framework/utils';

class Component extends EventEmitter {

	constructor( options ) {

		super( options );

		this.datas = this.options.datas;
		this.datas.isMobile = Utils.isMobile();
		this.datas.isTablet = Utils.isTablet();
		this.datas.isDesktop = Utils.isDesktop();

		this.template = this.options.template.render( this.datas );

		this.$ = {};
		this.$.container = this.options.container;

		let range = document.createRange();

		range.setStart( document.body, 0 );

		this.$.el = $( range.createContextualFragment( this.template ) );

		// render

		this.$.container.append( this.$.el );

	}

	hide( callback ) {

		callback();

	}

	destroy() {

		Utils.nullObject( this );

	}

}

export default Component;
