import Abstract from 'framework/core';

class Model extends Abstract {

	constructor( options ) {

		super( options );

	}

}

export default Model;
