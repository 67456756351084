var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<nav class=\"heritage-nav\">\n\t<ul class=\"grid\">\n\t\t<li class=\"col-1-2 pad-1\">\n\t\t\t<a class=\"txt-med-4\" href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/heritage/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "currentCollection")),"prev", env.opts.autoescape)),"attrs", env.opts.autoescape)),"handle", env.opts.autoescape), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "currentCollection")),"prev", env.opts.autoescape)),"attrs", env.opts.autoescape)),"title", env.opts.autoescape), env.opts.autoescape);
output += "</a>\n\t\t</li>\n\t\t<li class=\"col-1-2 pad-1\">\n\t\t\t<a class=\"txt-med-4\" href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/heritage/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "currentCollection")),"next", env.opts.autoescape)),"attrs", env.opts.autoescape)),"handle", env.opts.autoescape), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "currentCollection")),"next", env.opts.autoescape)),"attrs", env.opts.autoescape)),"title", env.opts.autoescape), env.opts.autoescape);
output += "</a>\n\t\t</li>\n\t</ul>\n</nav>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
