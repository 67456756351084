import EventEmitter from 'framework/core/eventEmitter';
import Easer from 'framework/tools/easer';
import Resizer from 'framework/tools/resizer';
import Utils from 'framework/utils';
import {
	$
} from 'framework/tools/selector';

class CompareSlider extends EventEmitter {

	constructor( options ) {

		super( options );

		this.$ = {};


		this.$.body = $( document.body );
		this.$.container = $( options.container );
		this.$.slider = this.$.container.find( 'ul' );
		this.$.itemBefore = this.$.slider.find( 'li.before' );
		this.$.imgBefore = this.$.itemBefore.find( '.img-container' );
		this.$.itemAfter = this.$.slider.find( 'li.after' );
		this.$.imgAfter = this.$.itemAfter.find( '.img-container' );

		this.$.navDrag = this.$.container.find( '.nav-drag' );
		this.$.cursor = this.$.navDrag.find( '.nav-cursor-line' );
		this.$.btn = this.$.cursor.find( '.nav-cursor' );

		this.isDesktop = Utils.isDesktop();

		this.isDragged = false;
		this.dragStartX = 0;
		this.dragMoveX = 0;
		this.dragSaved = 0;
		this.slideWidth = this.$.slider[ 0 ].offsetWidth;

		this.oldWindowWidth = window.innerWidth;

		this.easer = new Easer( {
			target: {
				x: -( this.slideWidth ) / 2
			},
			pos: {
				x: 0
			},
			ease: 1,
			autorun: true
		} );

		this.resizer = new Resizer();

		this.observe();

	}

	observe() {

		if ( this.isDesktop ) {

			this.mouseDownHandler = this.mouseDownHandler.bind( this );
			this.$.btn.on( 'mousedown', this.mouseDownHandler );

			this.mouseMoveHandler = this.mouseMoveHandler.bind( this );
			this.$.body.on( 'mousemove', this.mouseMoveHandler );

			this.mouseUpHandler = this.mouseUpHandler.bind( this );
			this.$.body.on( 'mouseup', this.mouseUpHandler );
			this.$.body.on( 'mouseleave', this.mouseUpHandler );

		} else {

			this.touchStartHandler = this.touchStartHandler.bind( this );
			this.$.btn.on( 'touchstart', this.touchStartHandler );

			this.touchMoveHandler = this.touchMoveHandler.bind( this );
			this.$.body.on( 'touchmove', this.touchMoveHandler );

			this.touchEndHandler = this.touchEndHandler.bind( this );
			this.$.body.on( 'touchend', this.touchEndHandler );

		}

		this.frameHandler = this.frameHandler.bind( this );
		this.easer.on( 'frame', this.frameHandler );

		this.resizeHandler = this.resizeHandler.bind( this );
		this.resizer.on( 'resize', this.resizeHandler );

	}

	resizeHandler( infos ) {

		this.slideWidth = this.$.slider[ 0 ].offsetWidth;

		this.easer.set( {
			x: ( this.easer.pos.x * infos.width ) / this.oldWindowWidth
		} );

		this.oldWindowWidth = infos.width;

	}

	mouseDownHandler( e ) {

		this.dragStart( e.pageX );

	}

	mouseMoveHandler( e ) {

		this.dragMove( e.pageX );

	}

	mouseUpHandler() {

		this.dragEnd();

	}

	touchStartHandler( e ) {

		this.dragStart( e.changedTouches[ 0 ].pageX );

	}

	touchMoveHandler( e ) {

		this.dragMove( e.changedTouches[ 0 ].pageX );

	}

	touchEndHandler() {

		this.dragEnd();

	}

	dragStart( x ) {

		this.isDragged = true;

		this.dragStartX = x;
		this.dragSaved = this.easer.pos.x;

	}

	dragMove( x ) {

		if ( this.isDragged ) {

			this.dragMoveX = this.dragSaved + this.dragStartX - x;

			if ( this.dragMoveX >= 0 ) {
				this.dragMoveX = 0;
			}

			if ( this.dragMoveX <= -( this.slideWidth ) ) {
				this.dragMoveX = -( this.slideWidth );
			}

			this.setTarget( this.dragMoveX );

		}

	}

	dragEnd() {

		this.isDragged = false;

		this.dragStartX = 0;
		this.dragMoveX = 0;

	}

	setTarget( pos ) {

		this.easer.set( {
			x: pos
		} );

	}

	frameHandler( datas ) {

		this.$.cursor.css( {
			transform: 'translate3d(' + ( -Math.floor( datas.pos.x ) ) + 'px, 0, 0)'
		} );

		this.$.itemBefore.css( {
			transform: 'translate3d(' + ( -Math.floor( datas.pos.x ) - ( this.slideWidth ) ) + 'px, 0, 0)'
		} );

		this.$.imgBefore.css( {
			transform: 'translate3d(' + ( Math.floor( datas.pos.x ) + ( this.slideWidth ) ) + 'px, 0, 0)'
		} );

		this.$.itemAfter.css( {
			transform: 'translate3d(' + ( -Math.floor( datas.pos.x ) - ( this.slideWidth ) ) + 'px, 0, 0)'
		} );

		this.$.imgAfter.css( {
			transform: 'translate3d(' + ( Math.floor( datas.pos.x ) ) + 'px, 0, 0)'
		} );

	}

	destroy() {

		if ( this.isDesktop ) {

			this.$.btn.off( 'mousedown', this.mouseDownHandler );
			this.$.body.off( 'mousemove', this.mouseMoveHandler );
			this.$.body.off( 'mouseup', this.mouseUpHandler );
			this.$.body.off( 'mouseleave', this.mouseUpHandler );

		} else {

			this.$.btn.off( 'touchstart', this.touchStartHandler );
			this.$.body.off( 'touchmove', this.touchMoveHandler );
			this.$.body.off( 'touchend', this.touchEndHandler );

		}

		this.easer.off( 'frame', this.frameHandler );
		this.resizer.off( 'resize', this.resizeHandler );

		this.easer.destroy();
		this.resizer.destroy();

		super.destroy();

	}

}

export default CompareSlider;
