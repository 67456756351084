import Component from 'framework/core/component';
import Template from 'templates/components/legals/content.html';

import Utils from 'framework/utils';
import {
	$
} from 'framework/tools/selector';
import Animator from 'framework/tools/animator';

class Content extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.isDesktop = Utils.isDesktop();

		this.$.section = this.$.container.find( '.legals-content' );

		if ( !this.isDesktop ) {
			setTimeout( function() {
				$( document.body ).find( '.spinner' ).css( {
					opacity: 0
				} );
			}, 100 );
		}

		this.show();

	}

	prepareAnimations() {

		this.showIn = new Animator();

		this.showIn
			.from( this.$.section, {
				opacity: 0
			} );

	}

	show() {

		this.prepareAnimations();

		let _this = this;
		setTimeout( function() {

			_this.showIn
				.to( _this.$.section, {
					opacity: 1,
					duration: 800
				}, 0 );

		}, 800 );

	}

	hide( callback ) {

		// page transition

		if ( !window.isOverlay ) {

			if ( this.isDesktop ) {

				let transitionLayer = $( Utils.createElement( {
					type: 'div',
					class: 'layer-transition',
					style: {
						'position': 'fixed',
						'top': 0,
						'left': 0,
						'width': '100%',
						'height': '100%',
						'z-index': '9',
						'background-color': '#fff',
						'opacity': 0,
						'pointer-events': 'none'
					}
				} ) );

				this.$.container.append( transitionLayer );

				this.showOut = new Animator();

				this.showOut
					.to( transitionLayer, {
						opacity: 1,
						duration: 800,
						clean: false
					}, 0 );


				this.showOut.once( 'finish', function() {
					callback();
				} );

			} else {

				callback();

			}

		} else {

			callback();

		}

	}

}

export default Content;
