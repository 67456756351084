import Component from 'framework/core/component';
import Template from 'templates/components/about/clients.html';

import Utils from 'framework/utils';
import {
	$
} from 'framework/tools/selector';
import Animator from 'framework/tools/animator';

class Clients extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.isDesktop = Utils.isDesktop();

		this.$.sectionIntro = this.$.container.find( '.about-intro' );
		this.$.sectionExpertises = this.$.container.find( '.about-expertises' );
		this.$.sectionClients = this.$.container.find( '.about-clients' );
		this.$.sectionAwards = this.$.container.find( '.about-awards' );

		this.show();

	}

	prepareAnimations() {

		this.showIn = new Animator();

		this.showIn
			.from( this.$.sectionIntro, {
				opacity: 0
			} )
			.from( this.$.sectionExpertises, {
				opacity: 0
			} )
			.from( this.$.sectionClients, {
				opacity: 0
			} )
			.from( this.$.sectionAwards, {
				opacity: 0
			} );

	}

	show() {

		this.prepareAnimations();

		let duration = 800;

		let _this = this;
		setTimeout( function() {

			_this.showIn
				.to( _this.$.sectionIntro, {
					opacity: 1,
					duration: duration
				}, -duration )
				.to( _this.$.sectionExpertises, {
					opacity: 1,
					duration: duration
				}, -duration )
				.to( _this.$.sectionClients, {
					opacity: 1,
					duration: duration
				}, -duration )
				.to( _this.$.sectionAwards, {
					opacity: 1,
					duration: duration
				}, 0 );

		}, 800 );

	}

	hide( callback ) {

		// page transition

		if ( !window.isOverlay ) {

			if ( this.isDesktop ) {

				let transitionLayer = $( Utils.createElement( {
					type: 'div',
					class: 'layer-transition',
					style: {
						'position': 'fixed',
						'top': 0,
						'left': 0,
						'width': '100%',
						'height': '100%',
						'z-index': '9',
						'background-color': '#fff',
						'opacity': 0,
						'pointer-events': 'none'
					}
				} ) );

				this.$.container.append( transitionLayer );

				this.showOut = new Animator();

				this.showOut
					.to( transitionLayer, {
						opacity: 1,
						duration: 800,
						clean: false
					}, 0 );


				this.showOut.once( 'finish', function() {
					callback();
				} );

			} else {

				callback();

			}

		} else {

			callback();

		}

	}

}

export default Clients;
