import EventEmitter from 'framework/core/eventEmitter';

class History extends EventEmitter {

	constructor( options ) {

		super( options );

		this.instance = window.history;
		this.origin = window.location.origin;
		this.path = window.location.pathname || '/';

		this.isLanding = true;

		this.observePopState();

	}

	prev( step = -1 ) {
		this.instance.go( step );
	}

	next( step = 1 ) {
		this.instance.go( step );
	}

	observePopState() {

		let _this = this;

		// On History pop state

		window.onpopstate = function() {

			this.path = window.location.pathname || '/';

			// Trigger pop event

			_this.trigger( 'pop', {
				path: this.path
			} );

		};


	}

	push( state, url ) {

		if ( !this.isLanding ) {

			// Push state into history

			this.instance.pushState( state, null, url );

		} else {

			this.isLanding = false;

		}

	}

}

export default new History();
