import AbstractController from 'app/helpers/abstractController';
import PostsModel from 'app/models/postsModel';

import Carousel from 'app/components/home/carousel';
import Scrolling from 'app/components/home/scrolling';
import Content from 'app/components/home/content';

class HomeController extends AbstractController {

	constructor( options ) {

		super( options );

	}

	start() {

		this.getSlides();

		// create components

		this.fallback();
		this.before();
		this.createComponents();
		this.after();

	}

	getSlides() {

		let projects = this.datas.home.carousel;
		let finalSlides = [];

		for ( let slug of projects ) {
			let datas = PostsModel.getOneBySlug( 'project', slug );
			finalSlides.push( datas );
		}

		this.datas.home.carousel = finalSlides;

	}

	createComponents() {

		//console.log( 'Home controller' );

		// create carousel part

		this.components.carousel = new Carousel( {
			container: this.$.overview,
			datas: this.datas
		} );

		// create scrolling part

		this.components.scrolling = new Scrolling( {
			container: this.$.overview,
			datas: this.datas
		} );

		// create content part

		this.components.content = new Content( {
			container: this.$.overview,
			datas: this.datas
		} );

	}

	destroy() {

		super.destroy();

	}

}

export default HomeController;
