import Controller from 'framework/core/controller';
import Software from 'framework/tools/stage/software';

class RedirectController extends Controller {

	constructor( options ) {

		super( options );

		// force fr (waiting trads)

		// window.location = '/fr/';

		if ( Software.lang.indexOf( 'fr' ) > -1 ) {
			window.location = '/fr/';
		} else {
			window.location = '/en/';
		}

	}

}

export default RedirectController;
