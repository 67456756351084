import EventEmitter from 'framework/core/eventEmitter';
import Resizer from 'framework/tools/resizer';
import Easer from 'framework/tools/easer';
import Timer from 'framework/tools/timer';
import {
	$
} from 'framework/tools/selector';

class Carousel extends EventEmitter {

	constructor( options ) {

		super( options );

		this.$ = {};
		this.$.container = this.options.container;
		this.$.images = this.$.container.find( '.images' );
		this.$.imagesViewport = this.$.images.find( '.viewport' );
		this.$.imagesOverview = this.$.images.find( '.overview' );
		this.$.imagesSlides = this.$.imagesOverview.find( 'li' );
		this.$.imagesSlidesImgs = this.$.imagesOverview.find( '.img' );
		this.$.imagesSlidesMasks = this.$.imagesOverview.find( '.mask' );
		this.$.infos = this.$.container.find( '.infos' );
		this.$.infosSlide = this.$.infos.find( 'li' );
		this.$.nav = this.$.container.find( 'nav' );
		this.$.navInfos = this.$.container.find( '.nav-infos' );
		this.$.btnPrev = this.$.container.find( '.previous' );
		this.$.btnNext = this.$.container.find( '.next' );
		this.$.statusBar = this.$.imagesViewport.find( '.statusbar' );

		this.onlyOneSlide = this.$.imagesSlides.length === 1;

		this.resizer = new Resizer();

		this.easer = new Easer( {
			target: {
				x: 0.1
			},
			pos: {
				x: 0
			},
			ease: 0.08,
			autorun: true
		} );

		this.viewportWidth = 0;
		this.size = this.$.imagesSlides.length;
		this.currentSlide = 0;
		this.isManipulated = false;
		this.autorun = this.options.autorun || false;
		this.isTouched = false;
		this.touchStart = 0;
		this.touchStartPos = 0;
		this.touchDist = 0;

		this.appendBlackLayers();
		this.observe();
		if ( this.autorun ) {
			this.startTimer();
		}

		if ( this.onlyOneSlide ) {
			this.$.nav.addClass( 'hide' );
			this.$.navInfos.addClass( 'hide' );
		}

	}

	startTimer( switchingTiming = 0 ) {

		this.timer = new Timer();
		let _this = this;

		this.timer
			.wait( 100 + switchingTiming )
			.set( function() {
				_this.$.statusBar.addClass( 'progress' );
			} )
			.wait( 4900 + switchingTiming )
			.set( function() {
				_this.$.statusBar.removeClass( 'progress' );
				_this.goNext();
			} )
			.wait( 900 )
			.set( function() {
				if ( _this.currentSlide !== ( _this.size - 1 ) ) {
					_this.startTimer( 100 );
				} else {
					_this.isManipulated = true;
				}
			} );

	}

	observe() {

		this.frameHandler = this.frameHandler.bind( this );
		this.easer.on( 'frame', this.frameHandler );

		this.resizeHandler = this.resizeHandler.bind( this );
		this.resizer.on( 'resize', this.resizeHandler );

		this.prevBtnHandler = this.prevBtnHandler.bind( this );
		this.$.btnPrev.on( 'click', this.prevBtnHandler );

		this.nextBtnHandler = this.nextBtnHandler.bind( this );
		this.$.btnNext.on( 'click', this.nextBtnHandler );

		this.touchstartHandler = this.touchstartHandler.bind( this );
		this.$.imagesViewport.on( 'touchstart', this.touchstartHandler );

		this.touchmoveHandler = this.touchmoveHandler.bind( this );
		this.$.imagesViewport.on( 'touchmove', this.touchmoveHandler );

		this.touchendHandler = this.touchendHandler.bind( this );
		this.$.imagesViewport.on( 'touchend', this.touchendHandler );

	}

	touchstartHandler( e ) {

		if ( !this.isTouched ) {
			this.manageTimer();
			this.isTouched = true;
			this.touchStart = e.touches[ 0 ].clientX;
			this.touchStartPos = this.easer.pos.x;
		}

	}

	touchmoveHandler( e ) {

		if ( this.isTouched ) {

			let x = e.touches[ 0 ].clientX;
			this.touchDist = this.touchStart - x;

			let target = this.touchStartPos + this.touchDist;

			let min = 0.1;
			let max = ( this.size - 1 ) * this.viewportWidth;
			let final = 0;

			if ( target < min ) {
				final = min;
			} else if ( target > max ) {
				final = max;
			} else {
				final = target;
			}

			this.easer.set( {
				x: final
			} );

		}

	}

	touchendHandler() {

		if ( this.isTouched ) {

			let force = this.touchDist / this.viewportWidth;
			let step = 0.3;

			if ( force > step ) {
				this.currentSlide++;
			} else if ( force < -step ) {
				this.currentSlide--;
			}

			this.currentSlide = this.currentSlide < 0 ? 0 : this.currentSlide;
			this.currentSlide = this.currentSlide > ( this.size - 1 ) ? ( this.size - 1 ) : this.currentSlide;

			this.isTouched = false;
			this.touchStart = 0;
			this.touchStartPos = 0;
			this.touchDist = 0;

			this.goToSlide( this.currentSlide );
		}

	}

	resizeHandler() {

		this.viewportWidth = this.$.imagesViewport.width();

		this.$.imagesOverview.css( {
			width: this.viewportWidth * this.size + 'px'
		} );

		this.$.imagesSlides.css( {
			width: this.viewportWidth + 'px'
		} );

		this.goToSlide( this.currentSlide );


	}

	appendBlackLayers() {

		let i = this.size;

		while ( i-- ) {

			let layer = document.createElement( 'div' );
			$( layer ).addClass( 'black-layer' );
			this.$.imagesSlides.eq( i ).append( $( layer ) );

		}

		this.$.imagesBlackLayers = this.$.imagesOverview.find( '.black-layer' );

	}

	goToSlide( index ) {

		this.checkNav( index );

		this.easer.set( {
			x: index * this.viewportWidth
		} );

		this.$.infosSlide.removeClass( 'shown' );
		$( this.$.infosSlide[ index ] ).addClass( 'shown' );

	}

	goPrev() {

		if ( this.currentSlide > 0 && this.currentSlide < this.size ) {
			this.currentSlide--;
			this.goToSlide( this.currentSlide );
		}

	}

	goNext() {

		if ( this.currentSlide >= 0 && this.currentSlide < ( this.size - 1 ) ) {
			this.currentSlide++;
			this.goToSlide( this.currentSlide );
		}

	}

	frameHandler( infos ) {

		let i = this.size;
		let overviewPos = -infos.pos.x;

		let forceRykiel = 1;
		let forceOpacity = 0.8;
		let forceScale = 0.2;
		var deltaScale = 0.2;

		// rykiel slide move

		while ( i-- ) {

			var pos = ( ( overviewPos - ( i * ( this.viewportWidth ) ) ) / forceRykiel ) + ( 2 * ( i * ( this.viewportWidth ) ) );
			var percent = pos / ( this.viewportWidth / forceRykiel );
			var scale = 1 + ( percent * forceScale ) + deltaScale;
			var opacity = -( percent * forceOpacity );

			if ( opacity > forceOpacity ) {
				opacity = forceOpacity;
			}

			if ( scale > forceScale + 1 + deltaScale ) {
				scale = forceScale + 1 + deltaScale;
			}

			this.$.imagesSlidesImgs.eq( i ).css( {
				transform: 'translate3d(' + ( -pos ) + 'px, 0, 0) scale(' + scale + ' )'
			} );

			this.$.imagesBlackLayers.eq( i ).css( {
				opacity: opacity
			} );

		}

		// overview move

		this.$.imagesOverview.css( {
			transform: 'translate3d(' + overviewPos + 'px, 0, 0)'
		} );

	}

	prevBtnHandler() {

		this.goPrev();
		this.manageTimer();
		// this.startTimer( 100 );

	}

	nextBtnHandler() {

		this.goNext();
		this.manageTimer();
		// this.startTimer( 100 );

	}

	checkNav( index ) {

		if ( index === 0 ) {

			this.$.btnPrev.addClass( 'disable' );
			this.$.btnNext.removeClass( 'disable' );

		} else if ( index === this.size - 1 ) {

			this.$.btnNext.addClass( 'disable' );
			this.$.btnPrev.removeClass( 'disable' );

		} else {

			this.$.btnPrev.removeClass( 'disable' );
			this.$.btnNext.removeClass( 'disable' );

		}
	}

	manageTimer() {

		if ( !this.isManipulated ) {
			this.isManipulated = true;
			if ( this.timer ) {
				this.$.statusBar.removeClass( 'progress' );
				this.timer.destroy();
			}
		}

	}

	destroy() {

		this.resizer.off( 'resize', this.resizeHandler );
		this.resizer.destroy();
		this.easer.off( 'frame', this.frameHandler );
		this.easer.destroy();

		this.$.btnPrev.off( 'click', this.prevBtnHandler );
		this.$.btnNext.off( 'click', this.nextBtnHandler );
		this.$.imagesViewport.off( 'touchstart', this.touchstartHandler );
		this.$.imagesViewport.off( 'touchmove', this.touchmoveHandler );
		this.$.imagesViewport.off( 'touchend', this.touchendHandler );

	}

}

export default Carousel;
