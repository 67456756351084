import EventEmitter from 'framework/core/eventEmitter';
import Ajax from 'framework/tools/loader/ajax';
import Is from 'framework/utils/is';

class Loader extends EventEmitter {

	load( urls, completeCallback, progressCallback ) {

		let sources = typeof urls === 'string' ? [ urls ] : urls;
		let dist = null;
		let loaded = [];
		let len = Is.isObject( sources ) ? Object.keys( sources ).length : sources.length;
		let count = 0;

		for ( let key in sources ) {

			let url = sources[ key ];

			Ajax._get( {
				url: url
			}, function( response ) {

				let ressource = {
					url: response.url,
					file: response.file
				};

				loaded.push( ressource );

				count++;

				if ( progressCallback ) {
					progressCallback( count / len );
				}

				if ( count === len ) {

					if ( len > 1 ) {

						dist = loaded;

					} else {

						dist = ressource;

					}

					completeCallback( dist );

				}

			} );

		}

	}

}

export default new Loader();
