import EventEmitter from 'framework/core/eventEmitter';

class RafHandler extends EventEmitter {

	constructor() {

		super();

		this.infos = {
			last: 0
		};

		this.count = 0;
		this.request = null;

		this.bound();

	}

	bound() {

		this.render = this.render.bind( this );

	}

	on( label, callback ) {

		super.on( label, callback );

		if ( !this.count ) {
			this.count++;
			this.render( 0 );
		} else {
			this.count++;
		}

	}

	off( label, callback ) {

		super.off( label, callback );

		this.count--;

		if ( !this.count ) {
			window.cancelAnimationFrame( this.request );
		}

	}

	render( currentTime ) {

		if ( this.count ) {

			//let currentTime = window.performance.now();

			// console.log(this.count);

			this.request = window.requestAnimationFrame( this.render );

			this.infos.delta = currentTime - this.infos.last;
			this.infos.last = currentTime;
			this.infos.fps = 1 / ( this.infos.delta / 1000 );
			this.infos.now = Date.now();

			this.trigger( 'tick', this.infos );

		}

	}

}

export default new RafHandler();
