var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"about-awards\">\n\t<div class=\"grid\">\n\t\t<h2 class=\"txt-fat-2 pad-1\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "about")),"awards", env.opts.autoescape)),"title", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</h2>\n\t\t<p class=\"txt-med-1\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "about")),"awards", env.opts.autoescape)),"intro", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t<ul class=\"pad-2-top\">\n\t\t\t";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "about")),"awards", env.opts.autoescape)),"icons", env.opts.autoescape);
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("icon", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t<li class=\"v-align\"><i class=\"logo-";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += "\"></i></li>\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t</ul>\n\t</div>\n\n\t";
frame = frame.push();
var t_7 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "about")),"awards", env.opts.autoescape)),"recent", env.opts.autoescape)),"entries", env.opts.autoescape);
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("entry", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\n\t\t<div class=\"grid pad-2-top pad-1-bot\">\n\t\t\t<div class=\"col-1-3\">\n\t\t\t\t<h2 class=\"txt-small-1\">\n\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"first", env.opts.autoescape)) {
output += "\n\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "about")),"awards", env.opts.autoescape)),"recent", env.opts.autoescape)),"title", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "\n\t\t\t\t";
;
}
output += "\n\t\t\t\t</h2>\n\t\t\t</div>\n\t\t\t<div class=\"col-2-3\">\n\t\t\t\t<h2 class=\"txt-med-3 pad-3-bot\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_8),"title", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</h2>\n\t\t\t\t<p class=\"cat pad-5-bot\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_8),"infos", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t\t\t<span class=\"author\"><i class=\"bold\">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"author", env.opts.autoescape), env.opts.autoescape);
output += "</i> « ";
output += runtime.suppressValue(runtime.memberLookup((t_8),"name", env.opts.autoescape), env.opts.autoescape);
output += " »</span>\n\t\t\t\t<p class=\"txt-small-3 pad-5-top\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_8),"description", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class=\"img\">\n\t\t\t<img\n\t\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"placeHold", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\tdata-lazy-scroll=\"true\"\n\t\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_8),"image", env.opts.autoescape)),"src", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t>\n\t\t</div>\n\n\t";
;
}
}
frame = frame.pop();
output += "\n\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
