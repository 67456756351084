import AbstractController from 'app/helpers/abstractController';
import PostsModel from 'app/models/postsModel';

import SportagraphManager from 'app/managers/sportagraphManager';

import Intro from 'app/components/talent/intro';
import Mosaic from 'app/components/talent/mosaic';
import Biography from 'app/components/talent/biography';
import Slider from 'app/components/talent/slider';
import MathUtils from 'framework/utils/math';
import Utils from 'framework/utils';

class TalentController extends AbstractController {

	constructor( options ) {

		super( options );

	}

	start() {

		// get datas

		this.datas.talent = PostsModel.getOneBySlug( 'talent', this.params.slug, 'name' );

		this.getSlides();
		this.getGallery();

	}

	getSlides() {

		let projects = this.datas.talent.projects;
		let finalSlides = [];


		for ( let slug of projects ) {
			let datas = PostsModel.getOneBySlug( 'project', slug );
			finalSlides.push( datas );
		}

		this.datas.talent.projects = finalSlides;


	}

	getGallery() {

		let _this = this;

		this.datas.talent.gallery = [];

		SportagraphManager.searchByStream( this.datas.talent.stream, function( response ) {

			let limit = response.length;
			let count = 0;

			for ( let image of response ) {

				if ( image.model.type === 'Photo' ) {

					let infos = {
						streamId: _this.datas.talent.stream,
						id: image.id,
						width: image.model.resourceFormat.width,
						height: image.model.resourceFormat.height,
						scalingStrategy: 'width',
						colorModel: 'rgb',
						quality: 'high'
					};

					let ratio = MathUtils.ratio( infos.width, infos.height );

					let title = image.model.iptcCoreContentHeadline ? image.model.iptcCoreContentHeadline : '';

					_this.datas.talent.gallery.push( {
						placehold: Utils.placeHold( ratio.x, ratio.y ),
						url: SportagraphManager.getImageUrl( infos ),
						title: title ? title : ''
					} );

				}

				count++;

				if ( count === limit ) {
					// create components

					_this.fallback();
					_this.before();
					_this.createComponents();
					_this.after();
				}

			}

		} );


	}

	validate() {

		// talent validation

		if ( !Object.keys( PostsModel.getOneBySlug( 'talent', this.params.slug ) ).length ) {
			window.Router.createError();
		}

		// locale validation

		super.validate();

	}

	createComponents() {

		//console.log( 'Talent controller, slug : ' + this.params.slug );

		this.components.intro = new Intro( {
			container: this.$.overview,
			datas: this.datas
		} );

		this.components.mosaic = new Mosaic( {
			container: this.$.overview,
			datas: this.datas
		} );

		this.components.biography = new Biography( {
			container: this.$.overview,
			datas: this.datas
		} );

		this.components.slider = new Slider( {
			container: this.$.overview,
			datas: this.datas
		} );

	}

	destroy() {

		super.destroy();

	}

}

export default TalentController;
