var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<nav class=\"project-nav white-background\">\n\t<ul class=\"grid\">\n\t\t<li class=\"col-1-2 pad-1\">\n\t\t\t<a href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/project/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"prev", env.opts.autoescape)),"slug", env.opts.autoescape), env.opts.autoescape);
output += "\">\n\t\t\t\t<p class=\"txt-small-1 pad-4-bot\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"prev", env.opts.autoescape)),"category", env.opts.autoescape)),"name", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t\t\t<span class=\"txt-med-4\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"prev", env.opts.autoescape)),"client", env.opts.autoescape), env.opts.autoescape);
output += "</span>\n\t\t\t\t<div class=\"img-container\">\n\t\t\t\t\t<div class=\"img\">\n\t\t\t\t\t\t<img\n\t\t\t\t\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t\tdata-lazy=\"true\"\n\t\t\t\t\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"prev", env.opts.autoescape)),"image", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</a>\n\t\t</li>\n\t\t<li class=\"col-1-2 pad-1\">\n\t\t\t<a href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/project/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"next", env.opts.autoescape)),"slug", env.opts.autoescape), env.opts.autoescape);
output += "\">\n\t\t\t\t<p class=\"txt-small-1 pad-4-bot\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"next", env.opts.autoescape)),"category", env.opts.autoescape)),"name", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t\t\t<span class=\"txt-med-4\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"next", env.opts.autoescape)),"client", env.opts.autoescape), env.opts.autoescape);
output += "</span>\n\t\t\t\t<div class=\"img-container\">\n\t\t\t\t\t<div class=\"img\">\n\t\t\t\t\t\t<img\n\t\t\t\t\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t\tdata-lazy=\"true\"\n\t\t\t\t\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"next", env.opts.autoescape)),"image", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</a>\n\t\t</li>\n\t</ul>\n</nav>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
