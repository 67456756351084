import EventEmitter from 'framework/core/eventEmitter';
import Resizer from 'framework/tools/resizer';
import Scroller from 'framework/tools/scroller';
import Easer from 'framework/tools/easer';
import { $ } from 'framework/tools/selector';

class Anchor extends EventEmitter {

	constructor( options ) {

		super( options );

		this.anchors = [];

		this.resizer = new Resizer();
		this.scroller = new Scroller();
		this.easer = new Easer( {
			ease: 0.1,
			easeLimit: 0.2
		} );

		this.observe();

	}

	parse() {

		this.reset();

		let anchors = $( '[data-anchor]' );
		let _this = this;

		this.clickHandler = this.clickHandler.bind( this );

		anchors.each( function( el ) {

			let btn = $( el );
			let name = btn.attr( 'data-anchor' );
			let section = $( name );

			btn.on( 'click', _this.clickHandler );

			_this.anchors.push( {
				btn: btn,
				name: name,
				section: section,
				top: 0
			} );

			//btn.removeAttr( 'data-anchor' );

		} );

		this.setSize();

	}

	setSize() {

		let i = this.anchors.length;
		let maxTop = document.body.offsetHeight - window.innerHeight;

		while ( i-- ) {

			let anchor = this.anchors[ i ];
			let sizes = anchor.section.sizes();
			let top = sizes.top + ( window.scrollY || window.pageYOffset );

			anchor.top = top < maxTop ? top : maxTop;

		}

	}

	observe() {

		this.resizeHandler = this.resizeHandler.bind( this );
		this.resizer.on( 'resize', this.resizeHandler );

		this.mousewheelHandler = this.mousewheelHandler.bind( this );
		this.scroller.on( 'mousewheel', this.mousewheelHandler );

		this.easerHandler = this.easerHandler.bind( this );
		this.easer.on( 'frame', this.easerHandler );

	}

	easerHandler( infos ) {

		window.scrollTo( 0, infos.pos.y );

	}

	clickHandler( e ) {

		let btn = $( e.target );
		let name = btn.attr( 'data-anchor' );

		let i = this.anchors.length;

		while ( i-- ) {

			let anchor = this.anchors[ i ];

			if ( anchor.name === name ) {
				this.goToPosition( anchor.top );
			}

		}

	}

	goToPosition( top ) {

		this.easer.force( {
			y: ( window.scrollY || window.pageYOffset )
		} );

		this.easer.set( {
			y: top
		} );

	}

	mousewheelHandler() {

		if ( this.easer.isActive ) {
			this.easer.stop();
		}

	}

	resizeHandler() {

		this.setSize();

	}

	reset() {

		let i = this.anchors.length;

		while ( i-- ) {

			let anchor = this.anchors[ i ];

			anchor.btn.off( 'click', this.clickHandler );

			delete this.anchors[ i ];

		}

		this.anchors = [];

	}

	destroy() {

		this.resizer.off( 'resize', this.resizeHandler );
		this.scroller.off( 'mousewheel', this.mousewheelHandler );
		this.easer.off( 'frame', this.easerHandler );

	}

}

export default new Anchor();
