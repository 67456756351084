var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"error-content\">\n\t<div>\n\t\t<p class=\"txt-med-5\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"error", env.opts.autoescape)),"title", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t<p class=\"txt-med-2 white\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"error", env.opts.autoescape)),"subtitle", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t<div class=\"pad-3-top\">\n\t\t\t<a href=\"/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape);
output += "/\" class=\"back round-hover\" data-roundhover>\n\t\t\t\t<svg class=\"svg round-hover-ico\">\n\t\t\t\t\t<use xlink:href=\"#ico-arrow-left\">\n\t\t\t\t</svg>\n\t\t\t\t<svg class=\"svg round-hover-ico\">\n\t\t\t\t\t<use xlink:href=\"#ico-arrow-left\">\n\t\t\t\t</svg>\n\t\t\t\t<svg class=\"svg round-hover-ico\">\n\t\t\t\t\t<use xlink:href=\"#ico-arrow-left\">\n\t\t\t\t</svg>\n\t\t\t</a>\n\t\t\t<span class=\"txt-small-3 bold white\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "indications")),"error", env.opts.autoescape)),"back", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</span>\n\t\t</div>\n\t</div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
