import Utils from 'framework/utils';

class Selector {

	constructor( selector, parent ) {

		var elements = [];

		if ( typeof selector === 'string' ) {

			if ( parent ) {
				elements = parent[ 0 ].querySelectorAll( selector );
			} else {
				elements = document.querySelectorAll( selector );
			}

		} else {
			elements[ 0 ] = selector;
		}

		for ( let i in elements ) {
			this[ i ] = elements[ i ];
		}

		this.length = elements.length;
		this.handlers = [];

		Object.assign( this, elements );

	}

	eq( index ) {

		return new Selector( this[ index ] );

	}

	each( callback ) {

		let i = this.length;

		while ( i-- ) {

			callback( this[ i ] );

		}

	}

	find( selector ) {
		return new Selector( selector, this );
	}

	type( type ) {

		this.each( function( el ) {

			el.type = type;

		} );

	}

	css( properties ) {

		let prefix = Utils.getPrefix();

		this.each( function( el ) {
			for ( let prop in properties ) {
				el.style[ prop ] = properties[ prop ];
				if ( prop === 'transform' ) {
					el.style[ prefix.js + Utils.cap( prop ) ] = properties[ prop ];
				}
			}
		} );

	}

	append( children ) {

		this.each( function( el ) {

			el.appendChild( children[ 0 ] );

		} );

	}

	prepend( children ) {

		this.each( function( el ) {

			el.insertBefore( children[ 0 ], el.firstChild );

		} );

	}

	attr( name, value ) {

		if ( !value ) {

			if ( name instanceof Object ) {

				for ( let key in name ) {

					if ( name[ key ] ) {
						this[ 0 ].setAttribute( key, name[ key ] );
					}

				}

			} else {

				if ( name ) {

					return this[ 0 ].getAttribute( name );

				} else {

					let attr = {};
					let i = Object.keys( this[ 0 ].attributes ).length;

					while ( i-- ) {

						let a = this[ 0 ].attributes[ i ];

						attr[ a.name ] = a.value;

					}

					return attr;

				}

			}

		} else {

			this[ 0 ].setAttribute( name, value );

		}

	}

	text( string ) {

		if ( string ) {
			this[ 0 ].textContent = string;
		} else {
			return this[ 0 ].textContent;
		}

	}

	html( string ) {

		this.each( function( el ) {

			if ( string ) {
				el.innerHTML = string;
			} else {
				return el.innerHTML;
			}

		} );

	}

	val( value ) {

		if ( value ) {
			this[ 0 ].value = value;
		} else {
			return this[ 0 ].value || false;
		}

	}

	empty() {

		this.each( function( el ) {

			el.innerHTML = '';

		} );

	}

	emptyVal() {

		this.each( function( el ) {

			el.value = '';

		} );
	}

	hasClass( className ) {

		return this[ 0 ].classList.contains( className );

	}

	addClass( className ) {

		this.each( function( el ) {

			if ( !el.classList.contains( className ) ) {
				return el.classList.add( className );
			}

		} );

	}

	removeAttr( name ) {

		this.each( function( el ) {

			el.removeAttribute( name );

		} );

	}

	addId( idName ) {

		let _this = this;

		this.each( function() {

			return _this.attr( 'id', idName );

		} );

	}

	removeClass( className ) {

		this.each( function( el ) {

			if ( el.classList.contains( className ) ) {
				return el.classList.remove( className );
			}

		} );

	}

	remove() {

		this.each( function( el ) {

			el.parentNode.removeChild( el );

		} );

	}

	clone() {

		return new Selector( this[ 0 ].cloneNode( true ) );

	}

	parent() {

		return new Selector( this[ 0 ].parentNode );

	}

	sizes() {

		return this[ 0 ].getBoundingClientRect();

	}

	height() {

		return this.sizes().height;
	}

	width() {

		return this.sizes().width;
	}

	top() {

		return this.sizes().top;
	}

	bottom() {

		return this.sizes().bottom;
	}

	on( event, handler ) {

		if ( !this.handlers[ event ] ) {
			this.handlers[ event ] = [];
		}

		this.handlers[ event ].push( handler );

		this.each( function( el ) {

			return el.addEventListener( event, handler, false );

		} );

	}

	trigger( event ) {

		this.each( function( el ) {

			var evt = document.createEvent( 'MouseEvents' );
			evt.initEvent( event, true, false );

			return el.dispatchEvent( evt );

		} );

	}

	off( event, handler ) {

		if ( handler ) {

			this.each( function( el ) {

				return el.removeEventListener( event, handler );

			} );

			let i = this.handlers[ event ].length;

			while ( i-- ) {

				let foundHandler = this.handlers[ event ][ i ];

				if ( foundHandler === handler ) {
					this.handlers[ event ].splice( i, 1 );
				}

				if ( !this.handlers[ event ].length ) {
					delete this.handlers[ event ];
				}

			}

		} else {

			if ( this.handlers[ event ] ) {

				let j = this.handlers[ event ].length;

				while ( j-- ) {

					handler = this.handlers[ event ][ j ];

					this.each( function( el ) {

						return el.removeEventListener( event, handler );

					} );

					this.handlers[ event ].splice( j, 1 );

				}

			}

		}

	}

	once( event, handler ) {

		let _this = this;

		let disposable = function() {
			handler( this );
			_this.off( event, disposable );
		};

		this.on( event, disposable );

	}

}

export let $ = ( selector, parent ) => new Selector( selector, parent );
