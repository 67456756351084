import Component from 'framework/core/component';
import Template from 'templates/components/talent/intro.html';
import Utils from 'framework/utils';
import {
	$
} from 'framework/tools/selector';

class Intro extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.isDesktop = Utils.isDesktop();

		if ( !this.isDesktop ) {
			setTimeout( function() {
				$( document.body ).find( '.spinner' ).css( {
					opacity: 0
				} );
			}, 100 );
		}

	}

}

export default Intro;
