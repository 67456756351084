var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
output += "<div class=\"project-featured\">\n\t<div class=\"featured-black-layer\"></div>\n\t<div class=\"viewport\">\n\t\t<div class=\"parallax\">\n\t\t\t<div class=\"img\">\n\t\t\t\t<img\n\t\t\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "helpers")),"placeHold_16_9", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t\tdata-lazy=\"true\"\n\t\t\t\t\tdata-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"image", env.opts.autoescape), env.opts.autoescape);
output += "\"\n\t\t\t\t>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"white-background\">\n\t\t<div class=\"grid infos center bg-dark\">\n\t\t\t<span class=\"txt-small-1\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"category", env.opts.autoescape)),"name", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</span>\n\t\t\t<h1 class=\"txt-med-4 pad-5\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"title", env.opts.autoescape)),runtime.contextOrFrameLookup(context, frame, "locale"), env.opts.autoescape), env.opts.autoescape);
output += "</h1>\n\t\t\t<p class=\"subtitle bold\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "project")),"client", env.opts.autoescape), env.opts.autoescape);
output += "</p>\n\t\t</div>\n\t</div>\n\t<span data-anchor=\".project-intro\" class=\"scroll-to round-hover\" data-roundhover>\n\t\t<i class=\"ico-scroll-to round-hover-ico\"><i></i></i>\n\t\t<i class=\"ico-scroll-to round-hover-ico\"><i></i></i>\n\t\t<i class=\"ico-scroll-to round-hover-ico\"><i></i></i>\n\t</span>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
