class Is {

	static isAlphanumeric( str, wordcase ) {
		let regex = /^[a-zA-Z0-9']+$/i;
		if ( wordcase == 'lowercase' ) regex = /^[a-z0-9']+$/i;
		else if ( wordcase == 'uppercase' ) regex = /^[A-Z0-9']+$/i;

		if ( str.match( regex ) !== null ) return true;
		return false;
	}

	static isNumber( str ) {
		if ( str.match( /^[0-9]+$/i ) !== null ) return true;
		return false;
	}

	static isString( str ) {
		if ( str.match( /^[a-zA-ZàáâãäåæÀÁÃÂÄÅÆßçÇéèêëÉÊÈËîïíÎÏÍñÑóôòöðœÓÔÕÖÒŒúùûüÚÛÙÜýÿÝŸþÞ\-\s\']+$/i ) !== null ) return true;
		return false;
	}

	static isArray( item ) {
		return Object.prototype.toString.call( item ) === '[object Array]';
	}

	static isObject( item ) {
		return ( item && typeof item === 'object' && !Array.isArray( item ) && item !== null );
	}

	static isFunction( obj ) {
		return typeof obj == 'function' || false;
	}

	static isEmail( str ) {
		if ( str.match( /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i ) !== null ) return true;
		return false;
	}

	static isDate( str, format ) {
		if ( format == 'euro' ) { // french DD/MM/YYYY
			if ( str.match( /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/ ) !== null ) return true;
		} else { // US : YYYY/MM/DD
			if ( str.match( /^[0-9]{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])/ ) !== null ) return true;
		}
		return false;
	}

	static isUrl( str ) {
		if ( str.match( /\b((?:https?:\/\/|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/i ) !== null ) return true;
		return false;
	}

}

export default Is;
