class Maths {

	static lerp( ratio, start, end ) {
		return start + ( end - start ) * ratio;
	}

	static rand( min, max ) {
		return this.lerp( Math.random(), min, max );
	}

	static clamp( num, min, max ) {
		return Math.min( Math.max( num, min ), max );
	}

	static range( a ) {
		a.sort();
		return a[ a.length - 1 ] - a[ 0 ];
	}

	static gcg( a, b ) {
		return ( b == 0 ) ? a : this.gcd( b, a % b );
	}

	static ratio( w, h ) {

		var r = this.gcd( w, h );

		return {
			x: w / r,
			y: h / r
		}

	}

	static constrain( num, min, max ) {
		return Math.min( Math.max( num, Math.min( min, max ) ), Math.max( min, max ) );
	}

	static closest( num, arr ) {

		var curr = arr[ 0 ];
		var diff = Math.abs( num - curr );
		for ( var val = 0; val < arr.length; val++ ) {
			var newdiff = Math.abs( num - arr[ val ] );
			if ( newdiff < diff ) {
				diff = newdiff;
				curr = arr[ val ];
			}
		}
		return curr;

	}

	static average( array ) {
		let m = 0;
		let n = 0;

		for ( let o = 0, x = array.length; o < x; o++ ) {
			if ( typeof array[ o ] == 'number' ) {
				n += array[ o ];
				m += 1;
			}
		}

		return ( n / m );
	}

	static toDegrees( rad ) {
		return rad * ( 180 / Math.PI );
	}

	static toRadians( deg ) {
		return deg * ( Math.PI / 180 );
	}

	static toPolar( p ) {
		let r = Math.sqrt( p.x * p.x + p.y * p.y ),
			t = this.atan2D( p.y, p.x );

		return {
			r: r,
			t: t
		};
	}

	static toCartisian( p ) {
		let x = p.r * this.cosD( p.t ),
			y = p.r * this.sinD( p.t );

		return {
			x: x,
			y: y
		};
	}

	static dist( p1, p2 ) {
		let dx = p2.x - p1.x;
		let dy = p2.y - p1.y;
		return Math.sqrt( dx * dx + dy * dy );
	}

	static sinD( a ) {
		return Math.sin( a * ( Math.PI / 180 ) );
	}

	static cosD( a ) {
		return Math.cos( a * ( Math.PI / 180 ) );
	}

	static tanD( a ) {
		return Math.tan( a * ( Math.PI / 180 ) );
	}

	static atan2D( y, x ) {
		return Math.atan2( y, x ) * ( 180 / Math.PI );
	}

	static angleOfLine( x1, y1, x2, y2 ) {
		return this.atan2D( y2 - y1, x2 - x1 );
	}

	static asinD( r ) {
		return Math.asin( r ) * ( 180 / Math.PI );
	}

	static acosD( r ) {
		return Math.acos( r ) * ( 180 / Math.PI );
	}

	static fixAngle( a ) {
		a %= 360;

		return ( a < 0 ) ? a + 360 : a;
	}

	static mean( a ) {
		let m = 0,
			len = a.length,
			i;

		for ( i = a.length; --i > -1; ) {
			m = a[ i ] + m;
		}

		return m / len;
	}

	static median( a ) {
		let len = a.length;

		a.sort();
		if ( len === 0 ) return 0;

		if ( len === 1 ) return a[ 0 ];

		if ( len % 2 === 1 ) {
			return a[ Math.floor( len * .5 ) ];
		} else {
			return this.mean( [ a[ ( len * .5 ) - 1 ], a[ len * .5 ] ] );
		}
	}

	static angle( p1, p2 ) {
		return Math.atan2( p2.y - p1.y, p2.x - p1.x ) * 180 / Math.PI;
	}

	static gcd( a, b ) {

		let _ref;

		while ( b ) {
			_ref = [ b, a % b ], a = _ref[ 0 ], b = _ref[ 1 ];
		}

		return a;

	}

	static lcm( a, b ) {

		return a / Math.gcd( a, b ) * b;

	}

}

export default Maths;
