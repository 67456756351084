import Component from 'framework/core/component';
import Template from 'templates/components/common/oldbrowser.html';
import Utils from 'framework/utils';

class Oldbrowser extends Component {

	constructor( options ) {

		options.template = Template;
		super( options );

		this.$.oldbrowserSection = this.$.container.find( '#oldbrowser' );

		this.minVersionIE = 11;

		this.checkOldIE();

	}

	checkOldIE() {

		if ( Utils.isInferiorIE( this.minVersionIE ) ) {
			this.show();
		}

	}

	show() {

		this.$.oldbrowserSection.addClass( 'show' );

	}

}

export default Oldbrowser;
